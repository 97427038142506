import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import { startSubmission, stopSubmission } from '../../actions/forms';
import _ from 'lodash';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';

import { saveCategory, fetchCategoriesList } from '../../actions/categories';

import { checkPermission } from '../../helpers/permissions';

var formName = 'formCategoriesEdit';

class CategoriesEdit extends Component {

	constructor(props){
		super(props);
		
		const { id } = this.props.match.params;
						
		if(id){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'CATEGORIES_EDIT')){
				this.props.history.push('/');
			}
			
			this.props.fetchCategoriesList(id, () => {
				   
				if(this.props.categories.categories && !this.props.categories.categories[id]){
					this.props.history.push('/categories');
				}else{
					this.updateDomElements();
				}
			   });
			   
		}else{
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'CATEGORIES_ADD')){
				this.props.history.push('/');
			}
		}
							
		this.props.stopSubmission(formName);
		
		this.state = {
			id: id
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		this.updateDomElements(); 
	}
	
	updateDomElements(){

		if(this.props.categories.categories && this.state.id){
			let category = this.props.categories.categories[this.state.id];		
			this.props.initialize(category);
		}
	}

	onSubmit(values) {
		
		this.props.startSubmission(formName);
		
		var posting = _.clone(values);
		
		this.props.saveCategory(formName, this.state.id, posting, (id) => {
			this.props.history.push(this.props.parentPath);
		});
	}
	
	render() {
		
		if(this.state.id && (!this.props.categories.categories || !this.props.categories.categories[this.state.id])){
			return (
				<Loading />
			);
		}

		const { handleSubmit } = this.props;

		let title = i18n.t('categories:title_add');
		
		if(this.state.id){
			let category = this.props.categories.categories[this.state.id];
			title = category.name;			
		}
				
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>
				
				<Toolbar
					buttons={[{
						label: this.state.id ? i18n.t('categories:action_edit_submit') : i18n.t('categories:action_add_submit'),
						submit: formName
					}]}
				/>					
					
				<div className="c-card">
					<Field
						label={i18n.t('categories:label_name')} 
						name="name"
						type="text"
						component={InputField}
					/>
				</div>
			</form>				
		);
	}
}

const validate = (values) => {	
	
	const errors = {};

	if (!values.name) {
		errors.name = 'Enter a category name!';
	}
	
	return errors;
}

function mapStateToProps({ categories, account, brand }, ownProps){
	return {
		categories,
		account,
		brand
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		startSubmission, 
		stopSubmission,
		saveCategory,
		fetchCategoriesList
	} )(CategoriesEdit)
);