import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import i18n from '../../helpers/i18n'; 
import ReactTooltip from 'react-tooltip';
import SkillBar from 'react-skillbars';
import NumberFormat from 'react-number-format';
import { imageResize } from '../../helpers/s3';

import moment from 'moment-timezone';
import 'moment/min/locales';

class Table extends Component {

	constructor(props){
		super(props);
		moment.locale(i18n.language);
		
		this.state = {
			sort: {
				column: (this.props.sort && this.props.sort.column) ? this.props.sort.column : false,
				order: (this.props.sort && this.props.sort.order) ? this.props.sort.order : 'asc'
			},
			pagination: {
				page: 1, 
				rows: this.props.rows ? this.props.rows : 15
			}
		}
	}
	
	componentDidUpdate(){
		ReactTooltip.rebuild();
	}
	
	onChangeOrder(col){
		
		let dir = this.state.sort.order;
		
		if(this.state.sort.column === col){
		
			if(dir === 'asc'){
				dir = 'desc';
			}else{
				dir = 'asc'
			}		
		}
		
		this.setState({
			sort: {
				column: col,
				order: dir
			}
		});
	}
	
	renderColumn(row, col, key){
		
		let { actions } = this.props;
		let value = row[col.key];
		let url = false;
		let className = col.className ? col.className : '';
		
		if(col.hidden){
			className += ' hidden';
		}	
										
		if(value && value.url){
			url = value.url;
		}
		
		let color = false;
		let rawValue = value;
				
		if(col.key === 'actions'){												
			
			value = _.map(row.actions, (action, key3) => {
				
				let margin = '';
				
				if(key3 < row.actions.length-1){
					margin = 'u-mr-xsmall';
				}													
				
				if(actions[action].onClick){
					return (
						<button 
							key={key3} 
							onClick={() => { actions[action].onClick(row.id) }} className={`c-btn c-btn--info ${margin}`} 
							data-tip={actions[action].tooltip ? actions[action].tooltip : ''}
						>
							{actions[action].icon &&
								<i className={`${actions[action].icon} ${actions[action].label ? 'icon-padding-right' : ''}`}></i>
							}
							
							{actions[action].label &&
								<React.Fragment>
									{actions[action].label}
								</React.Fragment>
							}
						</button>
					)
				}else{
					return (
						<Link 
							key={key3} 
							to={actions[action].url.replace('[ID]', row.id)} 
							className={`c-btn c-btn--info ${margin}`}
							data-tip={actions[action].tooltip ? actions[action].tooltip : ''}
						>
							{actions[action].icon &&
								<i className={`${actions[action].icon} ${actions[action].label ? 'icon-padding-right' : ''}`}></i>
							}
							
							{actions[action].label &&
								<React.Fragment>
									{actions[action].label}
								</React.Fragment>
							}
						</Link>
					)
				}
			});
			
		}else if(col.format){
							
			switch(col.format){
				
				case 'date':
					value = moment.utc(value).tz(this.props.account.profile.timezone).format('LL');
					break;
					
				case 'datetime':
								
					if(value){
						
						if(typeof value.value !== 'undefined'){
							
							if(value.value){
								value = moment.utc(value.value).tz(this.props.account.profile.timezone).format('LL - HH:mm');
							}else{
								value = 'NA';
							}							
						}else{
							value = moment.utc(value).tz(this.props.account.profile.timezone).format('LL - HH:mm');
						}						
					}else{
						value = 'NA';
					}
					
					if(rawValue.text){
						value = (
							<React.Fragment>
								{value}
								<div className="sub">
									{rawValue.text}
								</div>
							</React.Fragment>
						)
					}
					break;
					
				case 'time':
					value = moment.utc(value).tz(this.props.account.profile.timezone).format('HH:mm');
					break;
					
				case 'percent':
					value = (
						<React.Fragment>
							{value}%
						</React.Fragment>
					);
					break;
					
				case 'percent_bar':
					value = (
						<SkillBar 
							skills={[{
								type: false, 
								level: value
							}]}
							colors={{
								"bar": "#CCC"
							}}
							height={15}
							animationDuration={500}
						/>
					);
					break;
					
				case 'price':
					rawValue = value.value;
					
					if(value.was){
						
						value = (
							<div className="price">
								<strike>
									<NumberFormat
										value={value.was}
										displayType="text"
										thousandSeparator={true}
									/>
								</strike> <NumberFormat
									value={value.value}
									displayType="text"
									thousandSeparator={true}
								/>
							</div>
						);
												
					}else{
						value = (
							<div className="price">
								<NumberFormat
									value={value.value}
									displayType="text"
									thousandSeparator={true}
								/>
							</div>
						);
					}					
					break;
					
				case 'image_circle':
					value = (
						<div className="c-avatar c-avatar--small">
							{value.src && 
	                    		<img className={`c-avatar__img ${url ? 'click' : ''}`} src={value.src} alt={value.alt} />
	                    	}
	                    	
	                    	{!value.src && value.icon && 
	                    		<i className={`${value.icon} c-avatar__img`}></i>
	                    	}
						</div>
					);
					break;
					
				case 'icon':
				
					let style = [];
					
					if(value.color){
						style = {
							color: value.color
						};
					}
																		
					value = (
						<i className={`${value.icon ? value.icon : value} ${value.className ? value.className : ''}`} style={style} data-tip={value.tooltip ? value.tooltip : ''}></i>
					);
					break;
					
				case 'image_pre':
					
					rawValue = value.value;
					let prefix = '';
					
					if(value.image){
						prefix = (
							<img src={value.image} className={`pre ${value.className ? value.className : null}`} alt={value.value} data-tip={value.tooltip ? value.tooltip : ''} />
						);
					}else if(value.icon){
						
						let style = [];
					
						if(value.color){
							style = {
								color: value.color
							};
						}
						
						prefix = (
							<i className={`pre ${value.icon ? value.icon : value} ${value.className ? value.className : ''}`} style={style} data-tip={value.tooltip ? value.tooltip : ''} ></i>
						);
					}
					
					value = (
						<React.Fragment>
							{prefix} {value.value}
						</React.Fragment>
					);
					break;
					
				case 'screenshot':				
					if(value){
						value = (
							<img src={imageResize(value, 60)} width="60" />
						);
					}else{
						value = 'Queued';
					}
					break;
					
				default: 
					break;
					
			}
		}
						
		if(col.highlight){
													
			if(col.highlight.rule){
				
				switch(col.highlight.rule){
					
					case 'greater_zero':
						if(rawValue > 0){
							color = col.highlight.color;
						}
						break;
						
					default:
					
						break;
				}
			}else{
				color = col.highlight.color;
			}
		}
		
		if(color){
			value = (
				<span style={{ color: color }}>{value}</span>
			);
		}
		
		if(Number.isInteger(value)){
			value = (
				<NumberFormat
					value={value}
					displayType="text"
					thousandSeparator={true}
				/>
			);
		}
		
		if(value.value){
			value = value.value;
		}
		
		return (
			<td key={`header_${key}_${key}`} className={`c-table__cell ${className}`}>
				
				{url && 
					<Link to={url}>
						{value}
					</Link>
				||
					<React.Fragment>
						{value}
					</React.Fragment>
				}													
			</td>
		);
		
	}
	
	render() {
		
		let { columns, data } = this.props;
		
		if(this.state.sort.column){

			data = _.orderBy(data, o => {
				
				if(typeof o[this.state.sort.column].value !== 'undefined'){	
					if(typeof o[this.state.sort.column] === 'string'){
						return o[this.state.sort.column].value.toLowerCase();
					}else{
						return o[this.state.sort.column].value;
					}
				}else if(typeof o[this.state.sort.column] === 'string'){
					return o[this.state.sort.column].toLowerCase();
				}else{
					return o[this.state.sort.column];
				}
		
			}, [this.state.sort.order]);
		}
				
		let pages = new Array(Math.ceil(data.length/this.state.pagination.rows));
		
		// PAGINATION		
		data = _.drop(data, ((this.state.pagination.page-1) * this.state.pagination.rows)).slice(0, this.state.pagination.rows);

		let rowSpan = 1;
		
		_.forEach(columns, (row, key) => {	
			if(row.cols){
				rowSpan = 2;
			}
		});
				
		return (
			<React.Fragment>
				<div className="c-table-container">
					<table cellPadding="0" cellSpacing="0" className="c-table">
						{!this.props.hideHead &&
							
							<thead className="c-table__head">
								<tr className="c-table__row">
									{_.map(columns, (col, key) => {
	
										let className = col.className ? col.className : '';
										let onClick = () => { };
										
										if(col.hidden){
											className += ' hidden';
										}	
					
										if(col.sortable){
											
											if(this.state.sort.column === col.key){
												className = `${className} sort_${this.state.sort.order}`;
											}
																															
											onClick = () => {
												this.onChangeOrder(col.key)
											}
										}
										
										let label = col.label;
										
										if(typeof label === 'object'){
											
											if(col.label.icon){
												label = (
													<i className={`${col.label.icon} ${col.label.label ? 'icon-padding-right' : ''}`}></i>
												);
											}
											
											if(col.label.label){
												label = (
													<React.Fragment>
														{label} {col.label.label}
													</React.Fragment>
												);
											}
										}
										
										let tooltip = '';
										
										if(col.label.tooltip){
											tooltip = col.label.tooltip;
										}
										
										return (
											<th 
												key={`header_${key}`} 
												className={`c-table__cell c-table__cell--head ${col.sortable ? 'sort' : ''} ${className}`}
												onClick={onClick}
												rowSpan={col.cols ? 1 : rowSpan}
												colSpan={col.cols ? col.cols.length : 1}
												data-tip={tooltip}
												width={col.width ? col.width : ''}
											>
												{label}
											</th>
										)
									})}
								</tr>
								
								{rowSpan > 1 &&
									
									<tr className="c-table__row">
										{_.map(columns, (col, key) => {
		
											if(col.cols){
												
												return _.map(col.cols, (colSub, keySub) => {
													
													let className = colSub.className ? colSub.className : '';
													let onClick = () => { };
									
													if(colSub.sortable){
														
														if(this.state.sort.column === colSub.key){
															className = `${className} sort_${this.state.sort.order}`;
														}
																																		
														onClick = () => {
															this.onChangeOrder(colSub.key)
														}
													}
													
													return (
														<th 
															key={`header_${keySub}`} 
															className={`c-table__cell c-table__cell--head ${colSub.sortable ? 'sort' : ''} ${className}`}
															onClick={onClick}
														>
															{colSub.label}
														</th>
													)
												})
												
											}else{
												return false;
											}
										})}
									</tr>
								}
			
							</thead>
						}
						
						<tbody>
							
							{_.map(data, (row, key) => {
								
								let className = '';
								let onClick = () => { };
								
								if(this.props.rowClick){
				
									className = 'click';
									onClick = (e) => {
										
										if((e.target.tagName.toLowerCase() === 'img' && e.target.classList.contains('click')) || e.target.tagName.toLowerCase() === 'button' || e.target.parentElement.tagName.toLowerCase() === 'button' || e.target.tagName.toLowerCase() === 'a'){
											return;
    									}
    																			
										this.props.rowClick(row);
									}
								}		
																
								return (
									<tr 
										key={`row_${key}`} 
										className={`c-table__row ${className}`}
										onClick={onClick}
									>
									
										{_.map(columns, (col, key2) => {
											
											if(col.cols){
												
												return _.map(col.cols, (colSub, key2Sub) => {
													return this.renderColumn(row, colSub, key2Sub);
												});
												
											}else{
												return this.renderColumn(row, col, key2);
											}
											
											
										})}
									</tr>
								)
							})}
							
						</tbody>
					</table>
					<ReactTooltip 
						effect="solid"
					/>
				</div>
				
				{pages.length > 1 &&
					
  					<ul className="c-pagination u-mt-medium">
						{this.state.pagination.page > 1 &&
							<li>
								<button 
									className="c-pagination__link" 
									onClick={() => {
											
										let pagination = this.state.pagination;
										--pagination.page;
										
										this.setState({
											pagination: pagination
										})
									}}
								>
									<i className="fal fa-chevron-left"></i>
								</button>
							</li>
						}
						
						{_.map(pages, (page, key) => {
							
							page = key+1;
								
							return (
								<li key={page}>
									<button 
										className={`c-pagination__link ${this.state.pagination.page === page ? 'is-active' : ''}`}
										onClick={() => {
											
											let pagination = this.state.pagination;
											pagination.page = page;
											
											this.setState({
												pagination: pagination
											})
										}}
									>
										{page}
									</button>
								</li>
							);
						})}
						
						{this.state.pagination.page < pages.length &&
							<li>
								<button
									className="c-pagination__link" 
									onClick={() => {
											
										let pagination = this.state.pagination;
										++pagination.page;
										
										this.setState({
											pagination: pagination
										})
									}}
								>
									<i className="fal fa-chevron-right"></i>
								</button>
							</li>
						}
					</ul>
				}
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account }, ownProps) {
	//return { post: posts[ownProps.match.params.id] };
	return { 
		account
	};
}

export default connect(mapStateToProps)(Table);