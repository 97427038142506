import { assetsConstants } from '../constants/assets';
import _ from 'lodash';

export default function(state = { }, action) {

	switch (action.type) {
		      
		case assetsConstants.COLLECTION_LIST:

			return { 
				...state, 
				collections: { ...state.collections, ..._.mapKeys(action.payload.data, 'id') }
			}
			
		case assetsConstants.ASSET_LIST:

			return { 
				...state, 
				assets: { ...state.assets, ..._.mapKeys(action.payload.data, 'id') }
			}
			
		case assetsConstants.COLLECTION_SAVE:

			return { 
				...state, 
				collections: { ...state.collections, ..._.mapKeys(action.payload.data.collections, 'id') }
			}
			
		case assetsConstants.ASSET_SAVE:

			let collections = state.collections;
			
			_.forEach(action.payload.data.assets, (asset, key) => {					
									
				if(asset.collection_id && !collections[asset.collection_id].assets.includes(asset.id)){
					collections[asset.collection_id].assets.push(asset.id);
				}
			});

			return { 
				...state, 
				assets: { ...state.assets, ..._.mapKeys(action.payload.data.assets, 'id') },
				collections: collections
			}
			
		case assetsConstants.ASSET_HEADLINE:

			return { 
				...state, 
				assets: { ...state.assets, ..._.mapKeys(action.payload.data.assets, 'id') }
			}	
			
			
		case assetsConstants.ASSET_DELETE:

			let assets = state.assets;

			delete assets[action.id]; 

			return { 
				...state, 
				assets: assets
			}

		default:
			return state;
	}
}