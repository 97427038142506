import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { trackOpen, trackPwa } from '../../actions/tracking';

import {
	isMobile,
	isTablet,
	isIOS,
	isAndroid
} from "react-device-detect";

class GAPage extends Component {	
	
	componentDidMount() {
		const page = this.props.location.pathname;
		this.trackPage(page);
	}
	
	componentDidUpdate(prevProps) {
		const currentPage = this.props.match.path;
		const nextPage = prevProps.match.path;
			
		if(currentPage !== nextPage){
			this.trackPage(prevProps.location.pathname);
		}
	}
	
	trackPage(page){
				
		if(this.props.type && this.props.type === 'modal'){
			page = page.replace(this.props.parentPath, '');
			ReactGA.modalview(page);
		}else{
			ReactGA.pageview(page);	
		}
		
		this.trackOpen(page);
	}
	
	trackOpen(page){
		
		// ONLY FIRST LOAD
		if(!this.props.tracking.opener){			
			
			// ONLY WHEN LOGGED IN!
			if(this.props.users.id){
			
				// LOG THIS VIA REDUX
				this.props.trackOpen(page);
				
				// ONLY FOR MOBILE/TABLETS
				if(isMobile || isTablet){
					
					let category = 'AppChannel';
					let action = '';
					
					const query = require('query-string');
					const queryString = query.parse(this.props.location.search);
			
					if(queryString.standalone){
						this.props.trackPwa();
						action = 'PWA';
					}
					
					if(isIOS){
						action += 'iOS';
					}else if(isAndroid){
						action += 'Android';
					}
					
					if(isTablet){
						action += 'Tab';
					}else if(isMobile){
						action += 'Mob';
					}					
					
					ReactGA.event({
						category: category,
						action: action,
						label: this.props.users.id + ' - ' . this.props.account.profile.id
					});
				}			
			}
		}
	}
	
	render(){
		return (
			<React.Fragment>
			
			</React.Fragment>
		)
	}
}

function mapStateToProps({ tracking, users }) {
	return {
		tracking,
		users
	 };
}

export default connect(mapStateToProps, { trackOpen, trackPwa })(GAPage);
