import { topicsConstants } from '../constants/topics';
import _ from 'lodash';

export default function(state = {}, action) {
	
	let topics = false;

	switch (action.type) {
		      
		case topicsConstants.TOPIC_LIST:

			return { 
				...state, 
				topics: { ...state.topics, ..._.mapKeys(action.payload.data, 'id') }
			}
			
		case topicsConstants.TOPIC_SAVE_GENERAL:
		case topicsConstants.TOPIC_SAVE_MODULES:
		case topicsConstants.TOPIC_START:
		case topicsConstants.TOPIC_READ_MODULE:

			return { 
				...state, 
				topics: { ...state.topics, ..._.mapKeys(action.payload.data.topics, 'id') }
			}

			
		case topicsConstants.TOPIC_DELETE:
								
			topics = state.topics;
			
			if(action.payload.data.deleted){
			
				if(topics[action.payload.data.deleted]){
					delete(topics[action.payload.data.deleted]);
				}
				
			}else{
				topics = { ...state.topics, ..._.mapKeys(action.payload.data.topics, 'id') };
			}

			return { 
				...state, 
				topics: topics
			}	
											
		default:
			return state;
	}
}