import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import EmptySection from '../../components/chrome/empty';
import Table from '../../components/chrome/table';
import moment from 'moment-timezone';
import 'moment/min/locales';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import TopicGridItem from '../../components/topics/grid_item';
import { imageResize } from '../../helpers/s3';
import InputField from '../../components/forms/field.js';

import { setSectionOptions } from '../../actions/chrome';
import { checkPermission } from '../../helpers/permissions';
import { fetchTopicsList } from '../../actions/topics';
import { fetchCategoriesList } from '../../actions/categories';

class Topics extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'TOPICS')){
			this.props.history.push('/');
		}
		
		this.props.setSectionOptions(
			'modules', 
			i18n.t('topics:title')
		);
		
		this.props.fetchTopicsList();
		
		if(!checkPermission(this.props.account.permissions, 'TOPICS_ADD') && !checkPermission(this.props.account.permissions, 'TOPICS_EDIT')){
			this.props.fetchCategoriesList();
		}

		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		
		this.state = {
			type: queryString.type ? queryString.type : 'approved',
			category: queryString.cat ? queryString.cat : false,
			search: false,
			filters: {
				type: false,
				status: false,
				keyword: false
			},
			archived: false
		}		
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	
	handleResize(){
		
		let width = window.innerWidth;
		let columns = 1;
		
		if(width >= 1200){
			columns = 6;
		}else if(width >= 992){
			columns = 3;
		}else if(width >= 576){
			columns = 2;
		}
		
		this.setState({
			columns: columns
		});
	}
	
	filterData(type){
		
		let now = moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
		let topics = this.props.topics.topics;

		let data = {
			approved: [],
			upcoming: [],
			draft: [],
			disabled: []
		};
				
		// APPLY FILTERS
		if(this.state.search){
			
			if(this.state.filters.keyword){
				topics = _.filter(topics, topic => (topic.title.toLowerCase().includes(this.state.filters.keyword.toLowerCase()) ));
			}
		}
				
		if(type === 'counter' || this.state.type === 'approved'){
			data.approved = _.filter(topics, topic => (topic.status === 'approved' && moment(topic.date_launch).format('YYYY-MM-DD HH:mm:ss') <= now && topic.modules.length > 0));
		}
		
		if(type === 'counter' || this.state.type === 'upcoming'){
			data.upcoming = _.filter(topics, topic => (topic.status === 'approved' && moment(topic.date_launch).format('YYYY-MM-DD HH:mm:ss') > now &&  topic.modules.length > 0));
		}
		
		if(type === 'counter' || this.state.type === 'draft'){
			data.draft = _.filter(topics, topic => (topic.status === 'draft' || (topic.status === 'approved' && topic.modules.length == 0)));
		}
	
		if(type === 'counter' || this.state.type === 'disabled'){
			data.disabled = _.filter(topics, topic => topic.status === 'disabled');
		}
		
		if(type === 'counter' || this.state.type === 'archived'){
			data.archived = _.filter(topics, topic => topic.status === 'archived');
		}
						
		if(type === 'counter'){
			
			return {
				approved: data.approved.length,
				upcoming: data.upcoming.length,
				draft: data.draft.length,
				disabled: data.disabled.length,
				archived: data.archived.length
			};
					
		}else{
			return data[this.state.type];
		}
	}
	
	renderTable(){

		let data = this.filterData();
				
		if(_.isEmpty(data)){
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t(`topics:empty_${this.state.type}_title`)}
					description={i18n.t(`topics:empty_${this.state.type}_description`)}
					cta={{
						label: i18n.t('topics:action_add'),
						url: '/modules/add'
					}}
				/>
			);			
		}
		
		let actions = [];
		let rowClick = (row) => {
			
			if(checkPermission(this.props.account.permissions, 'MODULES_VIEW')){
				this.props.history.push(`/modules/${row.id}`);
			}else{
				this.props.history.push(`/modules/${row.id}/setup`);
			}			
		};
		
		let cols = [
			{
				key: "image",
				label: "",
				format: 'image_circle',
				className: 'tight'
			},
			{
				key: "name",
				label: "Title",
				sortable: true
			},
			{
				key: "public",
				label: "Retailer Access",
				format: 'icon'
			},
			{
				key: "launch_date",
				label: "Launch Date",
				sortable: true,
				format: 'datetime'
			},
			{
				key: "topics",
				label: "Lessons",
				sortable: true
			},
			{
				key: "dependencies",
				label: "Dependencies",
				sortable: true
			}
		];
		
		switch(this.state.type){
			
			case 'approved':
			case 'archived':
			case 'disabled':
				cols.push({
					key: "completed",
					label: "Completed",
					sortable: true
				});
				break;
		}

		cols.push({
			key: "actions",
			label: "Actions",
			className: "tight right"
		});
		
		return (
			<div className="container">
				<div className="c-card">
					<Table 
						columns={cols}
						data={_.map(data, (topic, key) => {
							
							let actions = [];

							if(checkPermission(this.props.account.permissions, 'TOPICS_EDIT')){
								actions.push('edit');
							}
							
							if(checkPermission(this.props.account.permissions, 'TOPICS_ANALYTICS') && (topic.status == 'approved' || topic.status == 'disabled' || topic.status == 'archived') && moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss') >= moment(topic.date_launch).format('YYYY-MM-DD HH:mm:ss')){
								//actions.push('analytics'); // FUTURE
							}
							
							return ({
								id: topic.id,
								image: {
									src: topic.image_filename ? imageResize(topic.image_filename, 100, 100) : false,
									icon: 'fal fa-graduation-cap',
									alt: topic.title
								},
								name: topic.title,
								public: `fal ${topic.public === 1 ? 'fa-check' : 'fa-times'}`,
								launch_date: moment(topic.date_launch).format('YYYY-MM-DD HH:mm:ss'),
								topics: topic.modules ? topic.modules.length : 0,
								dependencies: topic.dependencies ? topic.dependencies.length : 0,
								completed: topic.completions ? topic.completions : 0,
								actions: actions							
							});
						})}
						actions={{
							edit: {
								tooltip: i18n.t('topics:action_edit'),
								icon: 'fal fa-edit',
								url: '/modules/[ID]/setup'
							},
							analytics: {
								tooltip: i18n.t('topics:action_analytics'),
								icon: 'fal fa-analytics',
								url: '/modules/[ID]/analytics'
							}
						}}
						sort={{
							column: 'name',
							order: 'asc'
						}}
						rowClick={rowClick}
					/>
				</div>
			</div>
		);
	}
	
	renderGrid(){
		
		let dataRefined = this.props.topics.topics;
		
		if(this.state.category){
			
			const categoryFilter = this.state.category;
			
			dataRefined = _.filter(dataRefined, function(o) { 
				return _.filter(o.categories, { id: parseFloat(categoryFilter) }).length > 0 ? true : false;
			});
		}
		
		if(this.state.filters.keyword){
			
			const keyword = this.state.filters.keyword.toLowerCase();
			let dataRefined2 = [];
			
			_.forEach(dataRefined, function(o, key) { 
				
				o.score = 0;
				
				if(o.title.toLowerCase().includes(keyword)){
					o.score = 10;
				}
													
				_.forEach(o.modules, (module) => {
					
					_.forEach(_.filter(module.content, { type: 'text' }), (topic) => {
					
						if(topic.content.toLowerCase().includes(keyword)){
							++o.score;
						}
					});
					
				});	
				
				if(o.score > 0){
					dataRefined2.push(o)
				}				
			});
			
			dataRefined = _.orderBy(dataRefined2, 'score');
		}
				
		let data = {
			pending: _.filter(dataRefined, topic => {
				
				if(!topic.completed && topic.status !== 'archived'){
					return true;
				}else{
					return false;
				}
			}),
			pending_archived: _.filter(dataRefined, topic => {
				
				if(!topic.completed && topic.status === 'archived'){
					return true;
				}else{
					return false;
				}
			}),
			completed: _.filter(dataRefined, topic => {
				
				if(topic.completed && topic.status !== 'archived'){
					return true;
				}else{
					return false;
				}
			}),
			completed_archived: _.filter(dataRefined, topic => {
				
				if(topic.completed && topic.status === 'archived'){
					return true;
				}else{
					return false;
				}
			})
			
		}
		
		let categories = [];
		
		if(this.props.categories.categories){
						
			const organiseCategories = (id) => {
				
				let found =_.sortBy( _.filter(this.props.categories.categories, { parent_id: id }), ['sortorder']);
				
				_.forEach(found, (child, key) => {
				
					found[key].count = _.filter(dataRefined, function(o) { 
						return _.filter(o.categories, { id: child.id }).length > 0 ? true : false;
					}).length;

					found[key].children = organiseCategories(child.id);
				});
				
				return found;
			}
			
			categories = organiseCategories(null);			
		}
		
		categories.unshift({
			name: 'All',
			count: dataRefined.length,
			children: []
		});
						
		return(
			<div className="container">
			
				<div className="row">
					<div className="col-xl-3 col-md-12">
						
						<div className="c-card">
							<h4 className="u-mb-small">Filters</h4>
							
							<h5 className="u-mb-small" style={{ fontSize: '16px' }}>Categories</h5>
							
							<ul className="bullets">
								{_.map(categories, (cat) => {
									
									const catRender = (data, depth) => {
										
										let selected = false;
										
										if(data.id && parseFloat(this.state.category) === parseFloat(data.id)){
											selected = true;
										}else if(!data.id && !this.state.category){
											selected = true;
										}
										
										return (
											<li>
												<Link 
													to={cat.id ? `/modules?cat=${data.id}` : `/modules`}
													onClick={() => {
														this.setState({ 
															category: data.id ? data.id : false
														});
													}}
												>
													<div 
														style={{
															//marginLeft: `${5*depth}px`,
															fontSize: '14px',
															color: selected ? 'inherit' : '#768093',
															fontWeight: selected ? '800' : '400'
														}}
													>
														{/*depth > 0 ? '-' : ''*/} {data.name}
													</div>
												</Link>
													
												{data.children && data.children.length > 0 && 													
													<ul className="bullets">
														{_.map(data.children, (child) => {
															return catRender(child, depth+1);
														})}
													</ul>
												}
											</li>
										)
									}
									
									return catRender(cat, 0);
								})}
							</ul>
							
							<h5 className="u-mt-medium u-mb-small" style={{ fontSize: '16px' }}>Keyword Search</h5>

							
							<InputField
								type="text"
								name="keyword"
								placeholder={i18n.t('topics:filter_keyword')}
								onChangeFunc={(event) => { 
									
									let filters = this.state.filters;
									filters.keyword = event.target.value;
									
									this.setState({
										filters: filters
									});
								}}
							/>
						</div>
					</div>
					<div className="col-xl-9 col-md-12">
						<div id="grid_progress">
							<div className="available c-card u-pb-zero">
						
								{_.isEmpty(data.pending) && 
									<EmptySection
										icon="fa-exclamation-circle"
										title={i18n.t(`topics:empty_topics_incomplete_title`)}
										description={i18n.t(`topics:empty_topics_incomplete_description`)}
									/>
								||
									<div className="row u-pb-zero topics">
										{_.map(data.pending, (item, key) => {	
						
											return (
												<TopicGridItem 
													key={key}
													topic={item}
													icon={'fa-eye'}
													size="large"
												/>						
											);
										})}
									</div>
								}
							</div>
							{data.completed.length > 0 && 
								<div className="completed">
									<div className="c-card grid-scroller" style={{ marginBottom: '0px' }}>
										<h4>
											{i18n.t(`topics:topic_completed_title`)}
											<span className="c-badge c-badge--small badge-light u-ml-xsmall">
												<CountUp 
													delay={0} 
													duration={1} 
													end={data.completed.length}
													separator="," 
													preserveValue={true}
												/>
											</span>
											
											{data.length > 0 && data.length > this.state.columns && 
												<div className="options">
													<i className={`fal fa-arrows-h ${this.state.expanded ? '' : 'selected'}`} onClick={() => { 
			
										 				this.setState({
											 				expanded: false
										 				});
										 				
													}}></i>
													<i className={`fal fa-th ${this.state.expanded ? 'selected' : ''}`} onClick={() => { 
						
										 				this.setState({
											 				expanded: true
										 				});
										 				
													}}></i>
												</div>
											}
										</h4>
										<div className={`row u-pt-small u-pb-zero topics ${this.state.expanded ? 'expanded' : ''}`}>
																	
											{_.map(data.completed, (item, key) => {	
						
												return (
													<TopicGridItem 
														key={key}
														topic={item}
														icon={'fa-plus'}
														size="small"
													/>						
												);
											})}
										</div>
									</div>
								</div>
							}

							{(data.pending_archived.length > 0 || data.completed_archived.length > 0) && 
								<div className="archived">
									<div className="c-card grid-scroller" style={{ marginTop: '30px', marginBottom: '0px' }}>
										<h4>											
											{i18n.t(`topics:topic_archived_title`)}
											<span className="c-badge c-badge--small badge-light u-ml-xsmall">
												<CountUp 
													delay={0} 
													duration={1} 
													end={data.pending_archived.length+data.completed_archived.length}
													separator="," 
													preserveValue={true}
												/>
											</span>
											
											{data.length > 0 && data.length > this.state.columns && 
												<div className="options">
													<i className={`fal fa-arrows-h ${this.state.expanded_archived ? '' : 'selected'}`} onClick={() => { 
								
														 this.setState({
															 expanded_archived: false
														 });
														 
													}}></i>
													<i className={`fal fa-th ${this.state.expanded_archived ? 'selected' : ''}`} onClick={() => { 
								
														 this.setState({
															 expanded_archived: true
														 });
														 
													}}></i>
												</div>
											}
										</h4>
										<div className={`row u-pt-small u-pb-zero topics ${this.state.expanded_archived ? 'expanded' : ''}`}>
																	
											{_.map(data.pending_archived.concat(data.completed_archived), (item, key) => {	
								
												return (
													<TopicGridItem 
														key={key}
														topic={item}
														icon={'fa-plus'}
														size="small"
													/>						
												);
											})}
										</div>
									</div>
								</div>
							}	
						</div>
					</div>
				</div>
			</div>
		);
	}
	
	onChangeType(type){
		this.setState({
			type: type,
		});
	}
	
	render() {
		
		let { topics } = this.props;
		
		if(!topics.topics){
			return (
				<Loading />
			);
		}
		
		if(checkPermission(this.props.account.permissions, 'TOPICS_ADD') || checkPermission(this.props.account.permissions, 'TOPICS_EDIT')){

			let counter = this.filterData('counter');
			let tabs = [];
			
			if(checkPermission(this.props.account.permissions, 'TOPICS')){
				tabs.push({
					label: i18n.t('topics:toolbar_approved'),
					counter: String(counter.approved),
					onClick: () => { this.onChangeType('approved') },
					selected: this.state.type === 'approved' ? true : false
				});
	
				tabs.push({
					label: i18n.t('topics:toolbar_upcoming'),
					counter: String(counter.upcoming),
					onClick: () => { this.onChangeType('upcoming') },
					selected: this.state.type === 'upcoming' ? true : false
				});
	
				tabs.push({
					label: i18n.t('topics:toolbar_draft'),
					counter: String(counter.draft),
					onClick: () => { this.onChangeType('draft') },
					selected: this.state.type === 'draft' ? true : false
				});
				
				tabs.push({
					label: i18n.t('topics:toolbar_archived'),
					counter: String(counter.archived),
					onClick: () => { this.onChangeType('archived') },
					selected: this.state.type === 'archived' ? true : false
				});

				tabs.push({
					label: i18n.t('topics:toolbar_disabled'),
					counter: String(counter.disabled),
					onClick: () => { this.onChangeType('disabled') },
					selected: this.state.type === 'disabled' ? true : false
				});
			}
			
			return (
	
				<React.Fragment>
					<Toolbar
						tabs={tabs}
						buttons={[{
							label: i18n.t('topics:action_add'),
							url: '/modules/add'
						}]}
						filter={{
							toggleFunc: (status) => {
								this.setState({
									search: status
								});
							},
							onChangeFunc: (event, type) => { 
								
								let filters = this.state.filters;
								
								switch(type){
									
									case 'keyword':
										filters.keyword = event.target.value;
										break;
										
									case 'type':
									case 'status':
										filters[type] = event.value;
										break;	
									
									default:
										return;
								}
						
								this.setState({
									filters: filters
								})
							},
							fields: [
								/*{
									placeholder: i18n.t('topics:filter_type'),
									name: "type",
									type: 'suggest',
									options: [
										{
											value: 1,
											label: 'Item 1'
										},
										{
											value: 2,
											label: 'Item 2'
										}
									]
								},
								{
									placeholder: i18n.t('topics:filter_status'),
									name: "status",
									type: 'suggest',
									options: [
										{
											value: 1,
											label: 'Item 1'
										},
										{
											value: 2,
											label: 'Item 2'
										}
									]
								},*/
								{
									placeholder: i18n.t('topics:filter_keyword'),
									name: "keyword",
									type: 'text'
								}
							],
							focus: 'keyword'
						}}
					/>
					
					{this.renderTable()}				
				</React.Fragment>
			);
			
		}else{
			
			return(
				<React.Fragment>
					{this.renderGrid()}				
				</React.Fragment>
			);			
		}
	}
}

function mapStateToProps({ account, topics, categories }, ownProps){
	return {
		account,
		topics,
		categories
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchTopicsList,
	fetchCategoriesList
})(Topics);