import { quizzesConstants } from '../constants/quizzes';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 

export function fetchQuizzesList(id, callback) {

    return async (dispatch) => {
		
		let url = '/quiz/list';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: quizzesConstants.QUIZ_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
			 			
		} catch(error) {
			console.log(error)
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveQuizGeneral(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
				
		try {
						
			if(id){
				request = await axiosCreate().patch(`/quiz/save/${id}`, values);
			}else{
				request = await axiosCreate().post('/quiz/save', values);
			}
			
			dispatch({
				type: quizzesConstants.QUIZ_SAVE_GENERAL,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('quizzes:notification_setup_updated_title'),
					i18n.t('quizzes:notification_setup_updated_description')
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('quizzes:notification_setup_added_title'),
					i18n.t('quizzes:notification_setup_added_description')
				));
			}
			
			if(callback)
				callback(request.data);
			
			dispatch(stopSubmission(formName));

		} catch (error) {
			
			console.log(error);
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function saveQuizQuestions(formName, id, values, callback) {

	return async (dispatch) => {
						
		try {
						
			const request = await axiosCreate().put(`/quiz/questions/${id}`, values);
			
			dispatch({
				type: quizzesConstants.QUIZ_SAVE_QUESTIONS,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('quizzes:notification_content_updated_title'),
				i18n.t('quizzes:notification_content_updated_description')
			));
			
			if(callback)
				callback(request.data);
			
			dispatch(stopSubmission(formName));

		} catch (error) {
			
			console.log(error);
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function startQuiz(quiz_id, callback) {

	return async (dispatch) => {
						
		try {
						
			const request = await axiosCreate().post('/quiz/start', { id: quiz_id });
			
			dispatch({
				type: quizzesConstants.QUIZ_START,
				payload: request
			});

			if(callback)
				callback(request.data);
			
		} catch (error) {
						
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveAnswers(formName, question, answers, callback) {

	return async (dispatch) => {
						
		try {
						
			const request = await axiosCreate().post(`/quiz/answers/${question}`, { answers: answers });
			
			dispatch({
				type: quizzesConstants.QUIZ_SAVE_ANSWERS,
				payload: request
			});
		
			if(callback)
				callback(request.data);
			
			dispatch(stopSubmission(formName));

		} catch (error) {
						
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function setProgress(percent){
		
	return {
		type: quizzesConstants.QUIZ_PROGRESS,
		payload: percent
	};
}