import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Sticky from 'react-sticky-el';
import Filters from '../../components/chrome/filters';
import Button from '../../components/chrome/button';
import CountUp from 'react-countup';

let ElementTab = ({ tab }) => {
	
	return (
		
		<React.Fragment>
			{tab.icon &&
				<i className={`fal ${tab.icon} ${tab.label ? 'icon-padding-right' : ''}`}></i>
			}
			
			{tab.label}
			
			{(tab.counter || tab.counter === 0) && 
				<span className="c-badge c-badge--small badge-light u-ml-xsmall">
					<CountUp 
						delay={0} 
						duration={1} 
						end={tab.counter} 
						separator="," 
						preserveValue={true}
					/>
				</span>
			}
		</React.Fragment>
	);	
}

class Toolbar extends Component {

	constructor(props){
		super(props);
		
		if(this.props.filter && this.props.filter.open){
			this.state = {
				search: true
			}
		}else{
			this.state = {
				search: false
			}
		}
	}
	
	componentDidMount(){
		this.forceScroll();
	}
	
	onDisplayFilters(status){
		
		let enabled = this.state.search ? false : true;
		
		this.setState({
			search: enabled
		});
		
		this.forceScroll();
		
		if(this.props.filter.toggleFunc){
			this.props.filter.toggleFunc(enabled)
		}
	}
	
	forceScroll(){
		 // Fixes dom bug with sticky header, so forces it to reload!
		 window.scrollBy(0, 1);
		 window.scrollBy(0, -1);
	}
	
	render() {
		
		const { tabs, buttons } = this.props;
	
		return (
			<React.Fragment>
				
				<Sticky 
					topOffset={this.props.offset ? this.props.offset : -30}
					className="c-toolbar-responsive u-mb-medium"
					disabled={this.props.stickyDisabled ? true : false}
				>
					{this.props.filter && 
						<Filters 
							display={this.state.search}
							{...this.props.filter}
						/>
					}
					<div className="c-toolbar">
										
						{tabs &&
							
							<nav className="c-toolbar__nav u-mr-auto">
								{_.map(tabs, (tab, key) => {
									
									let step = '';
									
									if(this.props.tabsSteps && key < tabs.length-1) {
										step = (
											<i className="fal fa-chevron-right"></i>
										)
									}
			
									if(tab.url){
										
										return (
									
											<React.Fragment key={key}>
												<Link 
													className={`c-toolbar__nav-link ${tab.selected ? 'is-active' : ''}`} 
													to={tab.url}
												>
													<ElementTab tab={tab} /> 
												</Link>
												{step}
											</React.Fragment>
										);
		
									}else if(tab.onClick){
										
										return (
									
											<React.Fragment key={key}>
												<button
													className={`c-toolbar__nav-link ${tab.selected ? 'is-active' : ''}`} 
													onClick={tab.onClick}
												>
													<ElementTab tab={tab} /> 
												</button>
												{step}
											</React.Fragment>
										);
									}
								})}
							</nav>
						}
								
						{(buttons || this.props.filter) &&
							
							<div className="c-toolbar__buttons u-ml-auto">
							
								{_.map(buttons, (button, key) => {
									return(
										<React.Fragment key={key}>
											<Button
												submit={this.props.submit ? this.props.submit : null}
												{...button}
											/>
										</React.Fragment>
									);									
								})}
								
								{this.props.filter && 
									
									<button 
										className={`c-btn filters-toggle ${this.state.search ? 'active' : ''}`}
										onClick={() => { this.onDisplayFilters() }}
									>
										<i className={`fal fa-${this.state.search ? 'times' : 'search'}`}></i>
									</button>
								}
							</div>
						}
					</div>					
				</Sticky>
			</React.Fragment>
		);
	}
}

export default Toolbar;