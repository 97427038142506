import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import { setSectionOptions } from '../../actions/chrome';
import { checkPermission } from '../../helpers/permissions';

class Export extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'EXPORT')){
			this.props.history.push('/');
		}
				
		this.props.setSectionOptions(
			'export', 
			i18n.t('export:title')
		);	
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}

	render() {

		return (
			<div className="container">
				<div className="c-card">
					Coming soon!	
				</div>
			</div>
		);
	}
}

function mapStateToProps({ account, stats }, ownProps){
	return {
		account, 
		stats
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions
})(Export);