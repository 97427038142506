import { statsConstants } from '../constants/stats';
import _ from 'lodash';

export default function(state = { }, action) {
	
	switch (action.type) {
			
		case statsConstants.USER_OVERVIEW:
	
			return { 
				...state, 
				overview: action.payload.data
			}	
			
		default:
			return state;
	}
}