import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import EmptySection from '../../components/chrome/empty';
import Table from '../../components/chrome/table';
import moment from 'moment-timezone';
import 'moment/min/locales';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import { imageResize } from '../../helpers/s3';
import InputField from '../../components/forms/field.js';

import { setSectionOptions } from '../../actions/chrome';
import { checkPermission } from '../../helpers/permissions';
import { fetchCategoriesList, saveSort } from '../../actions/categories';

import 'rc-tree/assets/index.css';
import Tree from 'rc-tree';

class Categories extends Component {

	constructor(props) {
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if (!checkPermission(this.props.account.permissions, 'CATEGORIES')) {
			this.props.history.push('/');
		}
				
		this.props.setSectionOptions(
			'categories', 
			i18n.t('categories:title')
		);
		
		this.props.fetchCategoriesList();
		
		this.state = {
			treeData: [],
			reload: true
		};	
	}
	
	componentDidMount() {
		window.scrollTo(0, 0);
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
		this.updateTree();
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	
	componentWillReceiveProps(nextProps) {
		if (nextProps.categories.categories) {
			if (this.state.reload) {
				this.updateTree(nextProps.categories.categories);
			} else {
				this.setState({ reload: true });
			}
		}
	}
	
	updateTree(data) {
		if (!data) {
			if (!this.props.categories.categories) {
				return;
			}
			data = this.props.categories.categories;
		}

		const findChildren = (parent) => {
			let ret = [];
			const children = _.sortBy(_.filter(data, { parent_id: parent }), ['sortorder']);

			if (children.length > 0) {
				children.forEach((value) => {
					ret.push({
						key: value.id,  // rc-tree requires 'key'
						title: value.name,
						children: findChildren(value.id)
					});
				});					
			}
			return ret;
		};
											
		this.setState({
			treeData: findChildren(null)
		});
	}
	
	handleResize() {
		let width = window.innerWidth;
		let columns = 1;
		
		if (width >= 1200) {
			columns = 6;
		} else if (width >= 992) {
			columns = 3;
		} else if (width >= 576) {
			columns = 2;
		}
		
		this.setState({
			columns: columns
		});
	}

	handleDrop = (info) => {
		const dropKey = info.node.key;
		const dragKey = info.dragNode.key;
		const dropPos = info.node.pos.split('-');
		const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

		const loop = (data, key, callback) => {
			for (let i = 0; i < data.length; i++) {
				if (data[i].key === key) {
					return callback(data[i], i, data);
				}
				if (data[i].children) {
					loop(data[i].children, key, callback);
				}
			}
		};

		const data = [...this.state.treeData];

		// Find drag object
		let dragObj;
		loop(data, dragKey, (item, index, arr) => {
			arr.splice(index, 1);
			dragObj = item;
		});

		if (!info.dropToGap) {
			// Drop on the content
			loop(data, dropKey, (item) => {
				item.children = item.children || [];
				// where to insert based on the drop position
				item.children.push(dragObj);
			});
		} else if (
			(info.node.props.children || []).length > 0 && // Has children
			info.node.props.expanded && // Is expanded
			dropPosition === 1 // Dropping into the bottom gap
		) {
			loop(data, dropKey, (item) => {
				item.children = item.children || [];
				item.children.unshift(dragObj);
			});
		} else {
			let ar;
			let i;
			loop(data, dropKey, (item, index, arr) => {
				ar = arr;
				i = index;
			});
			if (dropPosition === -1) {
				ar.splice(i, 0, dragObj);
			} else {
				ar.splice(i + 1, 0, dragObj);
			}
		}

		this.setState({
			treeData: data,
		});
		
		// You can also call your API to save the new tree structure here
		// this.props.saveSort(newTreeData);
	};
	
	renderTable() {
		let data = this.props.categories.categories;
				
		if (_.isEmpty(data)) {
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t(`categories:empty_title`)}
					description={i18n.t(`categories:empty_description`)}
					cta={{
						label: i18n.t('categories:action_add'),
						url: '/categories/category/add'
					}}
				/>
			);			
		}
					
		return (
			<div className="container">
				<div className="c-card">
					<div style={{ height: '90vh' }} draggable="false">
						<Tree
							draggable
							blockNode
							onDrop={this.handleDrop}
							treeData={this.state.treeData}
							titleRender={(nodeData) => {
								let buttons = [];
								if (checkPermission(this.props.account.permissions, 'CATEGORIES_EDIT')) {
									buttons.push(
										<Link
											to={`/categories/category/${nodeData.key}`}
											title="Edit"
										>
											<i className="fal fa-edit"></i>
										</Link>
									);
								}
								return (
									<div>
										{nodeData.title}
										{buttons.length > 0 && <span style={{ marginLeft: 8 }}>{buttons}</span>}
									</div>
								);
							}}
						/>
					</div>			
				</div>
			</div>
		);
	}
	
	render() {
		let { categories } = this.props;
		
		if (!categories.categories) {
			return <Loading />;
		}
		
		return (
			<React.Fragment>
				<Toolbar
					tabs={false}
					buttons={[{
						label: i18n.t('categories:action_add'),
						url: '/categories/category/add'
					}]}
				/>
				
				{this.renderTable()}				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, categories }, ownProps) {
	return {
		account,
		categories,
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchCategoriesList,
	saveSort
})(Categories);
