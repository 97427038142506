import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import EmptySection from '../../components/chrome/empty';
import Loading from '../../components/chrome/loading';
import { setSectionOptions } from '../../actions/chrome';
import Toolbar from '../../components/chrome/toolbar';
import { checkPermission } from '../../helpers/permissions';
import { fetchRegionalList } from '../../actions/regional';
import Table from '../../components/chrome/table';
import { imageResize } from '../../helpers/s3';
var countries = require("i18n-iso-countries");
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

class Regional extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'REGIONAL')){
			this.props.history.push('/');
		}
		
		this.props.setSectionOptions(
			'regional', 
			i18n.t('regional:title')
		);
		this.props.fetchRegionalList();
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	renderPage(){
		
		let { regional } = this.props;

		if(!regional.groups){
			return (
				<Loading />
			);
		}
		
		if(_.isEmpty(this.props.regional.groups)){
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t('regional:empty_title')}
					description={i18n.t('regional:empty_description')}
					cta={{
						label: i18n.t('regional:action_add'),
						url: `${this.props.location.pathname}/region/add`
					}}
				/>
			);			
		}
					
		return (
			<div className="container">				
				<div className="c-card">
					<Table 
						columns={[
							{
								key: "image",
								label: "",
								format: 'image_circle',
								className: 'tight'
							},
							{
								key: "name",
								label: "Name",
								sortable: true
							},
							{
								key: "country",
								label: "Country",
								sortable: true,
								format: "image_pre"
							},
							{
								key: "users",
								label: "Users",
								sortable: true
							},
							{
								key: "actions",
								label: "Actions",
								className: "tight right"
							}
						]}
						data={_.map(this.props.regional.groups, (group, key) => {
			
							let country = countries.getName(group.details.country, "en");
																												
							return ({
								id: group.id,
								image: {
									src: group.image ? imageResize(group.image, 100) : false,
									icon: 'fal fa-users',
									alt: group.name
								},
								name: group.name,
								users: group.users ? group.users.length : 0,
								country: {
									value: country,
									image: country ? `/images/flags/countries/${country.replace(/\s+/g, '-').toLowerCase()}.svg` : false,
									className: 'flag'
								},
								actions: ['edit']								
							});
						})}
						actions={{
							edit: {
								tooltip: i18n.t('regional:action_edit'),
								icon: 'fal fa-edit',
								url: `${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/region/[ID]`
							}
						}}
						sort={{
							column: 'name',
							order: 'asc'
						}}
						rowClick={(row) => {
							this.props.history.push(`${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/region/${row.id}`);
						}}
					/>
				</div>
			</div>
		);
	}
	
	render() {
									
		return (

			<React.Fragment>
				<Toolbar
					tabs={[
						{
							label: i18n.t('regional:toolbar_groups'),
							url: '/regional',
							selected: true
						},
					]}
					buttons={[{
						label: i18n.t('regional:action_add'),
						url: '/regional/region/add'
					}]}
				/>
				
				{this.renderPage()}				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, regional }, ownProps){
	return {
		account,
		regional
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchRegionalList
})(Regional);