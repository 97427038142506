export function imageUrl(path) {
	
	if(checkUrl(path)){
		return path;
	}
	
	return `https://elearning-tool.s3.eu-west-2.amazonaws.com/${path}`;
}

export function imageResize(path, width, height, fit, overlay) {
	
	if(checkUrl(path)){
		return path;
	}
		
	if(!width){
		width = null;
	}
	
	if(!height){
		height = null;
	}
	
	if(!fit){
		fit = 'cover';
	}

	//  OPTIONS: https://sharp.pixelplumbing.com/
	
	const imageRequest = {
	    bucket: 'elearning-tool',
	    key: path,
	    edits: {
	        //grayscale: true,
	        resize: {
	          	width: width,
			  	height: height,
			  	withoutEnlargement: false,
			  	fit: fit,
			  	position: 'attention',
			  	background: '#FFF'
	        }
	    }
	};
		
	if(overlay){
			
		switch(overlay){
			
			case 'play':
				imageRequest.edits.overlayWith = {
	                 bucket: 'elearning-tool',
	                 key: 'general/play.png',
	                 wRatio: 15
		        };			        
				break;
				
			default:
			
				break;
		}
	}		
	
	return `https://d1c9wponvcsi3c.cloudfront.net/${btoa(JSON.stringify(imageRequest))}`;
}

export function checkUrl(path) {
	let regex = new RegExp("^(https?)(:\/\/|(\%3A%2F%2F))", "i");
	return regex.test(path);
};

export { imageUrl as default };
