import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import QuizzesToolbar from './toolbar';

import Loading from '../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../actions/forms';
import AssetPicker from '../../components/assets/picker';

import _ from 'lodash';
import Swal from 'sweetalert2';
import { fetchQuizzesList, saveQuizQuestions } from '../../actions/quizzes';

import { setSectionOptions } from '../../actions/chrome';

import { checkPermission } from '../../helpers/permissions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

var formName = 'formQuizContent';

class QuizContent extends Component {

	constructor(props){
		super(props);
						
		const { id } = this.props.match.params;
		
        if(!id){
	       	this.props.history.push('/quizzes');
		}else{
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'QUIZZES_EDIT')){
				this.props.history.push('/quizzes');
			}
		}
		
		this.props.stopSubmission(formName);
		
		this.state = {
			id: id,
			questions: [{
				status: true,
				answers: [
					{ 
						status: true, 
						correct: 0 
					},
					{ 
						status: true, 
						correct: 0 
					}
				]
				//content: EditorState.createEmpty()
			}],
			question: 0
		}
		
		this.props.fetchQuizzesList(id, () => {
		       	
	       	if(this.props.quizzes.quizzes && !this.props.quizzes.quizzes[id]){
				this.props.history.push('/quizzes');
			}else{
				this.updateDomElements();
			}
       	});
       	
		this.updateDomElements();     
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	updateDomElements(){
				
		if(this.props.quizzes.quizzes && this.props.quizzes.quizzes[this.state.id]){
		
			const quiz = this.props.quizzes.quizzes[this.state.id];		
			let init = {};
			
			if(quiz.questions.length > 0){				
				this.setState({
					questions: _.map(quiz.questions, (question, key) => {					
						question.status = true;
						
						init[`question_${key}`] = question.question;
						init[`content_${key}`] = question.content;
						init[`quiz_${key}`] = question.quiz_id;
						
						let answers = [
							{ 
								correct: 0 
							},
							{ 
								correct: 0 
							}
						];
						
						if(question.answers && question.answers.length > 0){
							answers = question.answers;
						}
						
						question.answers = _.map(answers, (answer, key2) => {					
							answer.status = true;
							
							init[`answer_${key}_${key2}_answer`] = answer.answer ? answer.answer : '';
							init[`answer_${key}_${key2}_correct`] = answer.correct ? answer.correct : 0;
							
							return answer;
						});
						
						return question;
					})
				});
			}
			
			this.props.setSectionOptions(
				'quizzes', 
				quiz.title,
				[
					{
						url: '/quizzes',
						label: 'Quizzes'
					}
				]
			);
			
			this.props.initialize(init);
		}else{
			this.props.setSectionOptions(
				'quizzes', 
				'Questions',
				[
					{
						url: '/quizzes',
						label: 'Quizzes'
					}
				]

			);
		}
	}
	
	onSubmit(values) {
		
		this.props.startSubmission(formName);
	  	
	  	var posting = { 
		  	questions: _.map(this.state.questions, (question) => {
			  	//question.content = draftToHtml(convertToRaw(question.content.getCurrentContent()));
			  	question.content = question.content; //TEMP
			  	return question;
		  	})
		}
		
	  	this.props.saveQuizQuestions(formName, this.state.id, posting);
	}
	
	updateQuestion(type, value){
		
		let questions = this.state.questions;
		
		questions[this.state.question][type] = value;
		
		this.setState({
			questions: questions
		});
	}
	
	paginateQuestion(type, key, ret){
		
		let newKey = false;
		let checkKey = 0;
		
		if(key == 0){
			
			checkKey = 1;
			
			while(newKey === false) {
				
				if(this.state.questions[checkKey].status){
					newKey = checkKey;
				}else{
					++checkKey;
				}
			}
		}
		
		if(key > 0 || !newKey){
			
			checkKey = key-1;
			
			while(newKey === false && checkKey >= 0) {
				
				if(this.state.questions[checkKey].status){
					newKey = checkKey;
				}else{
					--checkKey;
				}
			}
		}
			
		if(ret){
			return newKey
		}else{	
			this.setState({
				question: newKey
			});
		}
	}
	
	renderPage(){
		
		const { quizzes } = this.props;
		
		if(!quizzes.quizzes || !quizzes.quizzes[this.state.id]){
			return (
				<Loading />
			);
		}
		
		const quiz = quizzes.quizzes[this.state.id];
		const question = this.state.questions[this.state.question];
		const active = _.filter(this.state.questions, { 'status': true });
						
		return (
			<div className="container">				
				<div className="row">
					<div className="col-xl-4 col-md-12">
					
						<div className="c-card">
														
							<h4 className="u-mb-medium">{i18n.t('quizzes:content_title_questions')} </h4>				
							
							<DragDropContext
								onDragEnd={(result) => {
									if (!result.destination) return;
									
									const questions = Array.from(this.state.questions);
									const [reorderedItem] = questions.splice(result.source.index, 1);
									questions.splice(result.destination.index, 0, reorderedItem);
									
									let new_id = this.state.question;
									
									if(this.state.question == result.source.index){
										new_id = result.destination.index;
									}else if(this.state.question > result.destination.index){
										--new_id;
									}else{
										new_id = this.paginateQuestion(new_id == 0 ? 'next' : 'previous', new_id, true);
									}
									
									let init = {}
									
									this.setState({
										questions: questions,
										question: new_id
									});
									
									_.forEach(questions, (question, key) => {					
											
										init[`question_${key}`] = question.question;
										init[`content_${key}`] = question.content;
										init[`quiz_${key}`] = question.quiz_id;
										
										_.map(question.answers, (answer, key2) => {																	
											init[`answer_${key}_${key2}_answer`] = answer.answer ? answer.answer : '';
											init[`answer_${key}_${key2}_correct`] = answer.correct ? answer.correct : 0;
										});
									});
									
									this.props.initialize(init);
								}}
							>
								<Droppable droppableId="questions">
								    {(provided) => (
									    
									    <ul className="questions" {...provided.droppableProps} ref={provided.innerRef}>
									    
											{_.map(this.state.questions, (question, key) => {
												
												if(!question.status){
													return null;
												}
												
												return (
													<Draggable key={key} draggableId={key.toString()} index={key}>
														{(provided) => (
															<li ref={provided.innerRef} {...provided.draggableProps}>
													
																<div 
																	className={`c-card clickable reduced u-mb-small ${this.state.question == key ? 'selected' : ''}`} 
																	key={key}
																	onClick={() => {
																		this.setState({
																			question: key
																		});
																	}}
																>
																	<h6 className="split">
																		{question.question ? question.question : i18n.t('quizzes:content_question_unnamed')}
																		
																		{active.length > 1 && 
																			<div className="options">
																				<i 
																					className="far fa-arrows"
																					{...provided.dragHandleProps}
																				></i>
																				<i 
																					className="far fa-trash"
																					onClick={() => {
																						
																						Swal.fire({
																							title: i18n.t('quizzes:delete_question_dialog_title'),
																							text: i18n.t('quizzes:delete_question_dialog_description'),
																							icon: 'warning',
																							showCancelButton: true,
																							confirmButtonText: i18n.t('dialog_confirm'),
																							cancelButtonText: i18n.t('dialog_cancel'),
																							focusConfirm: false
																						}).then((result) => {
																				
																							if (result.value) {
																								
																								let existing = this.state.questions;
																																
																								existing[key].status = false;
																								
																								let newKey = false;
																								let checkKey = 0;
																								
																								this.paginateQuestion(key == 0 ? 'next' : 'previous', key);
																															
																								this.setState({
																									questions: existing
																								});
																							}
																						});														
																					}}
																				></i>
																			</div>												
																		}
																	</h6>									
																</div>
															</li>
															
														)}
													</Draggable>														
													
												);
											})}
											{provided.placeholder}
										</ul>
									)}
									
								</Droppable>
							</DragDropContext>
							
							<button
								type="button"
								className="c-btn c-btn--info c-btn--outline"
								onClick={() => {
									
									let existing = this.state.questions;
									const newKey = existing.length;
																			
									existing[newKey] = {
										status: true,
										answers: [
											{ status: true },
											{ status: true }
										]
										//content: EditorState.createEmpty()
									}
									
									this.setState({
										questions: existing,
										question: newKey
									});
								}}
							>
								{i18n.t('quizzes:content_question_add')}
							</button>
						</div>
					</div>				
					<div className="col-xl-8 col-md-12">
						
						<div className="c-card" key={this.state.question}>
														
							<h4 className="u-mb-medium">{i18n.t('quizzes:content_title_question')} </h4>				
																							
							<Field
								label={i18n.t('quizzes:settings_content_question_question')} 
								name={`question_${this.state.question}`}
								type="text"
								value={question.question ? question.question : ''}
								onChangeFunc={(event) => {
									this.updateQuestion('question', event.target.value)
									
								}}
								component={InputField}
							/>
							
							<div className="c-field">
								<label className="c-field__label">{i18n.t('quizzes:settings_content_question_content')}</label>
								<div className="field">
							
									<Field
										name={`content_${this.state.question}`}
										type="textarea-auto"
										value={question.content ? question.content : ''}
										onChangeFunc={(event) => {
											this.updateQuestion('content', event.target.value);
										}}
										component={InputField}
									/>
								</div>
							</div>
							
							<div className="c-field">
								<label className="c-field__label">{i18n.t('quizzes:settings_content_question_asset')}</label>
								<AssetPicker
									types={['image','video','audio']}
									max={1}
									selected={question.asset_id ? [{ type: 'asset', id: question.asset_id }] : false}
									placeholder={i18n.t('quizzes:settings_asset_empty')}
									onSelect={(selected) => {
										
										let asset_id = false;
																			
										if(selected && selected.length > 0){
											asset_id = selected[0].id;
										}
																			
										this.updateQuestion('asset_id', asset_id);
									}}
								/>
							</div>
						
							<h4 className="u-mb-medium u-mt-large">{i18n.t('quizzes:content_title_answers')} </h4>	
							
							<DragDropContext
								onDragEnd={(result) => {
									if (!result.destination) return;
									
									const answers = Array.from(question.answers);
									const [reorderedItem] = answers.splice(result.source.index, 1);
									answers.splice(result.destination.index, 0, reorderedItem);
						
									this.updateQuestion('answers', answers);	
									
									_.forEach(answers, (answer, key) => {					
										this.props.change(`answer_${this.state.question}_${key}_answer`, answer.answer);
										this.props.change(`answer_${this.state.question}_${key}_correct`, answer.correct ? answer.correct : 0);
									});
								}}
							>
								<Droppable droppableId="answers">
								    {(provided) => (
									    
									    <ul className="answers" {...provided.droppableProps} ref={provided.innerRef}>
									    
										    {_.map(question.answers, (answer, key) => {
																						
												if(!answer.status){
													return null;
												}
																				
												return (
													<Draggable key={key} draggableId={key.toString()} index={key}>
														{(provided) => (
															<li ref={provided.innerRef} {...provided.draggableProps}>
																<div className="row u-mb-0" >
																	<div className="col-xl-8">
																		<Field
																			name={`answer_${this.state.question}_${key}_answer`}
																			type="text"
																			component={InputField}
																			onChangeFunc={(event) => {
																				question.answers[key].answer = event.target.value;
																				this.updateQuestion('answers', question.answers);
																			}}
																		/>	
																	</div>
																	<div className="col-xl-3">
																		<Field
																			name={`answer_${this.state.question}_${key}_correct`}
																			type="suggest"
																			selected={answer.correct}
																			onChangeFunc={(selected) => {
																				question.answers[key].correct = selected.value;
																				this.updateQuestion('answers', question.answers);						
																			}}
																			options={[
																				{ 
																					label: 'Correct',
																					value: 1
																				},
																				{
																					label: 'Incorrect',
																					value: 0
																				}
																			]}
																			component={InputField}
																		/>	
																	</div>
																
																	<div className="col-xl-1">
																		<i 
																			className="far fa-arrows"
																			{...provided.dragHandleProps}
																		></i>
																		{question.answers.length > 2 && 
																			<i 
																				className="far fa-trash"
																				onClick={() => {
																					
																					Swal.fire({
																						title: i18n.t('quizzes:delete_answer_dialog_title'),
																						text: i18n.t('quizzes:delete_answer_dialog_description'),
																						icon: 'warning',
																						showCancelButton: true,
																						confirmButtonText: i18n.t('dialog_confirm'),
																						cancelButtonText: i18n.t('dialog_cancel'),
																						focusConfirm: false
																					}).then((result) => {
																			
																						if (result.value) {
																							question.answers[key].status = false;
																							this.updateQuestion('answers', question.answers);
																						}
																					});														
																				}}
																			></i>
																		}
																	</div>												
																</div>
															</li>
														)}
													</Draggable>														
													
												);
											})}
											{provided.placeholder}
										</ul>
									)}
									
								</Droppable>
							</DragDropContext>
							
							<button
								type="button"
								className="c-btn c-btn--info c-btn--outline"
								onClick={() => {
									
									const newKey = question.answers.length;
																			
									question.answers[newKey] = {
										status: true, 
										correct: 0
									}
									
									this.updateQuestion('answers', question.answers);
								}}
							>
								{i18n.t('quizzes:content_answer_add')}
							</button>
							
						</div>				
					</div>
				</div>
			</div>
		)
	}
	
	render() {
	
		const { handleSubmit, quizzes } = this.props;
						
		/* TODO:
			
			Mobile version of topic list
			Sticky topic list which scrolls in itsetf
			Drag and drop
			Add pagination buttons to bottom
			Actual posting to server!
			Editor needs decorating to support assets and links to other modules 
			Editor toolbar options
		*/
		
		/*
			
			
										
		<Editor
			wrapperClassName="c-input"
			editorState={module.content ? module.content : false}
			onEditorStateChange={(editorState) => {
				this.updateQuestion('content', editorState)
			}}
			toolbar={{
				options: ['inline'],
			}}
		/>
		
		*/
				
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
			
				<QuizzesToolbar 
					formName={formName}
					id={this.state.id}
					section="content"
					history={this.props.history}
					quiz={(quizzes.quizzes && quizzes.quizzes[this.state.id]) ? quizzes.quizzes[this.state.id] : false}
				/>
				
				{this.renderPage()}
			</form>				
		);
	}
}

const validate = (values, props) => {	
			
	const errors = {};
	
	let i = 0;
	
	console.log('dean', values)
	
	while (i < 200) {
		
		if (!values[`question_${i}`]) {
			errors[`question_${i}`] = 'Please enter a question!';
		}
		
		let x = 0;
		let correct = 0;
		
		while (x < 100) {
			
			if (!values[`answer_${i}_${x}_answer`]) {
				errors[`answer_${i}_${x}_answer`] = 'Please enter an answer!';
			}
						
			if(values[`answer_${i}_${x}_correct`] && (values[`answer_${i}_${x}_correct`].value && values[`answer_${i}_${x}_correct`].value == 1) || values[`answer_${i}_${x}_correct`] == 1){
				++correct;
			}
			
			x++;
		}
				
		if(correct != 1){
			
			x = 0;
			
			while (x < 100) {
				errors[`answer_${i}_${x}_correct`] = 'Please select a correct answer!';
				x++;
			}
		}
		
		i++;
	}
	
	
	return errors;
}

function mapStateToProps({ quizzes, account }, ownProps){
	return {
		quizzes,
		account
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		setSectionOptions,
		fetchQuizzesList,
		saveQuizQuestions
	})(
	reduxForm({
		validate: validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(QuizContent)
);
