import { libraryConstants } from '../constants/library';
import _ from 'lodash';

export default function(state = { }, action) {

	switch (action.type) {
				
		case libraryConstants.LIBRARY_SETUP:
			
			return { 
				...state, 
				selected: action.payload.selected,
				types: action.payload.types,
				max: action.payload.max,
				onSelect: action.payload.onSelect
			}
			
		case libraryConstants.LIBRARY_SELECTION:

			let items = state.selected;
						
			_.forEach(action.payload.assets, (asset, key) => {					
												
				// DOES THIS EXIST?
				let existing = _.findIndex(items, { 'type': action.payload.type, 'id': asset.id });
								
				switch(action.payload.action){
					
					case 'add':
						if(existing === -1){
							items.push({
								type: action.payload.type, 
								id: asset.id
							});
						}
						break;
						
					case 'delete':
						if(existing >= 0){
							items.splice(existing, 1);
						}
						break;
						
					default:
					
						break;
				}
			});
			
			if(state.max && state.max < items.length){
				items = _.takeRight(items, state.max);
			}
					
			return { 
				...state, 
				selected: items
			}

		default:
			return state;
	}
}