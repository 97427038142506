import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import Loading from '../../components/chrome/loading';

class AchievementsView extends Component {

	constructor(props){
		super(props);
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push(this.props.parentPath);
		}		
				
		this.state = {
			id: id
		}
		
		
	}
		
	render() {

		return (
			<React.Fragment>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						Achievement
					</h2>
				</header>
								
				Coming soon - pending certificate and badge designs
				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ overlay }, ownProps){
	return {
		overlay
	};
}

export default connect(mapStateToProps, { 
	
	
})(AchievementsView);