import { achievementsConstants } from '../constants/achievements';
import _ from 'lodash';

export default function(state = {}, action) {
	
	switch (action.type) {
		      
		case achievementsConstants.ACHIEVEMENTS_LIST:

			return { 
				...state, 
				achievements: action.payload.data
			}
			
		default:
			return state;
	}
}