import { categoriesConstants } from '../constants/categories';
import _ from 'lodash';

export default function(state = { }, action) {

	switch (action.type) {
			  
		case categoriesConstants.CATEGORIES_LIST:

			return { 
				...state, 
				categories: { ...state.categories, ..._.mapKeys(action.payload.data, 'id') }
			}
						
		case categoriesConstants.CATEGORY_SAVE:

			return { 
				...state, 
				categories: { ...state.categories, ..._.mapKeys(action.payload.data.categories, 'id') }
			}
			
		default:
			return state;
	}
}