import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import UsersReducer from './users';
import ErrorsReducer from './errors';
import OverlayReducer from './overlay';
import AccountReducer from './account';
import AssetsReducer from './assets';
import SystemReducer from './system';
import MessagesReducer from './messages';
import LibraryReducer from './library';
import FormsReducer from './forms';
import ChromeReducer from './chrome';
import OptionsReducer from './options';
import BrandReducer from './brand';
import RetailersReducer from './retailers';
import TrackingReducer from './tracking';
import TopicsReducer from './topics';
import QuizzesReducer from './quizzes';
import RegionalReducer from './regional';
import AchievementsReducer from './achievements';
import CategoriesReducer from './categories';
import StatsReducer from './stats';

import { reducer as notificationsReducer } from 'reapop';

import { userConstants } from '../constants/users';

const appReducer = combineReducers({
	form: formReducer, 
	overlay: OverlayReducer,
	account: AccountReducer,
	users: UsersReducer,
	assets: AssetsReducer,
	errors: ErrorsReducer,
	messages: MessagesReducer,
	library: LibraryReducer,
	forms: FormsReducer,
	chrome: ChromeReducer,
	options: OptionsReducer,
	brand: BrandReducer,
	system: SystemReducer,
	retailers: RetailersReducer,
	regional: RegionalReducer,
	tracking: TrackingReducer,
	topics: TopicsReducer,
	quizzes: QuizzesReducer,
	achievements: AchievementsReducer,
	categories: CategoriesReducer,
	stats: StatsReducer,
	notifications: notificationsReducer()
});

const rootReducer = (state, action) => {
	
	if(action.type === userConstants.LOGOUT){
		state = undefined;
	}
	
	return appReducer(state, action)
}

export default rootReducer;
