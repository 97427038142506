import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import i18n from '../../helpers/i18n'; 
import { checkPermission } from '../../helpers/permissions';
import { stack as Menu } from 'react-burger-menu';

class Nav extends Component {
	
	constructor(props){
		super(props);	
		
		this.state = {
			width: window.innerWidth,
			open: false
		}	
		
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}
	
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	
	updateWindowDimensions() {
		this.setState({ width: window.innerWidth });
	}
	
	closeNav(){
		
		if(this.props.closeNav){
			this.props.closeNav();
		}
		
		this.setState({
			open: false
		});
	}

	getNav() {
		
		let permissions = this.props.account.permissions;
		let options = [];
			
		if(checkPermission(permissions, 'DASHBOARD')){
			options.push('dashboard');
		}
		
		if(checkPermission(permissions, 'TOPICS')){
			options.push('modules');
		}
		
		if(checkPermission(permissions, 'QUIZZES')){
			options.push('quizzes');
		}
				
		if(checkPermission(permissions, 'ASSETS')){
			options.push('assets');
		}
		
		if(checkPermission(permissions, 'RETAILERS_USERS') || checkPermission(permissions, 'INTERNAL_USERS')){
			options.push('users');
		}
		
		if(checkPermission(permissions, 'REGIONAL')){
			options.push('regional');
		}
		
		if(checkPermission(permissions, 'RETAILERS')){
			options.push('retailers');
		}	
		
		if(checkPermission(permissions, 'CATEGORIES')){
			options.push('categories');
		}
		
		if(checkPermission(permissions, 'ACHIEVEMENTS')){
			options.push('achievements');
		}	
		
		if(checkPermission(permissions, 'EXPORT')){
			options.push('export');
		}	
		
		return options;
	}
	
	createNavLink(key, title, url, icon, blank){

		let className = '';
		
		if(!this.props.type && this.props.type !== 'template'){
			className = 'c-sidebar__link';
		}
		
		if(key === this.props.section){
			className += ' is-active';
		}
		
		if(blank){
			return (
				<li key={key}>
					<a href={url} className={className} target="_blank" rel="noopener noreferrer" onClick={this.closeNav.bind(this)}>
						<i className={`c-sidebar__icon fal fa-${icon}`}></i>
						{title}
					</a>
				</li>
			)
		}else{
			return (
				<li key={key}>
					<Link to={url} className={className} onClick={this.closeNav.bind(this)}>
						<i className={`c-sidebar__icon fal fa-${icon}`}></i>
						{title}
					</Link>
				</li>
			)
		}		
	}

	renderNav() {

		let options = this.getNav();

		if(options){

			let list = options.map(option => {

				switch(option){

					case 'dashboard':
						return this.createNavLink(option, i18n.t('nav_dashboard'), '/', 'home');
						
					case 'modules':
						return this.createNavLink(option, i18n.t('nav_modules'), '/modules', 'graduation-cap');

					case 'assets':
						return this.createNavLink(option, i18n.t('nav_assets'), '/assets', 'images');
						
					case 'quizzes':
						return this.createNavLink(option, i18n.t('nav_quizzes'), '/quizzes', 'badge-check');
						
					case 'regional':
						return this.createNavLink(option, i18n.t('nav_regional'), '/regional', 'users');
						
					case 'retailers':
						return this.createNavLink(option, i18n.t('nav_retailers'), '/retailers', 'store-alt');
						
					case 'users':
						return this.createNavLink(option, i18n.t('nav_users'), '/users', 'users');
						
					case 'achievements':
						return this.createNavLink(option, i18n.t('nav_achievements'), '/achievements', 'trophy');
						
					case 'categories':
						return this.createNavLink(option, i18n.t('nav_categories'), '/categories', 'folder');	
					
					case 'export':
						return this.createNavLink(option, i18n.t('nav_export'), '/export', 'cloud-download');						
						
					default: 
						return {};
				}

			});

			return list;
		}

		return;
	}

	render() {
		
		let permissions = this.props.account.permissions;
			
		if(this.props.type && this.props.type == 'template'){
			
			if(this.state.width < 800){
			
				return (
					<Menu 
						right 
						itemListElement="ul"
						disableAutoFocus
						isOpen={this.state.open}
						onOpen={() => {
							this.setState({
								open: true
							})
						}}
					>
						{this.renderNav()}
					</Menu>
				);
			}else{
				return (
					<ul>
						{this.renderNav()}
					</ul>
				);
			}
			
		}else{
			
			return (
				<React.Fragment>
					<ul className="c-sidebar__list u-border-bottom">
						{this.renderNav()}
					</ul>
					
					<ul className="c-sidebar__list">
						{/*this.createNavLink('documentation', i18n.t('nav_documentation'), this.props.account.docs, 'book', true)*/}
						{this.createNavLink('account', i18n.t('nav_account'), '/account', 'user')}
					</ul>
				</React.Fragment>
			);
		}		
	}
}

function mapStateToProps({ account, chrome, brand }, ownProps){
	return { 
		account,
		section: chrome.section,
		brand
	};
}

export default withRouter(connect(mapStateToProps)(Nav));
