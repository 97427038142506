import { chromeConstants } from '../constants/chrome';

export function setSectionOptions(section, title, breadcrumbs, back){
		
	return {
		type: chromeConstants.SECTIONTITLE,
		payload: {
			section: section,
			title: title,
			breadcrumbs: breadcrumbs ? breadcrumbs : false 
		}
	};
}