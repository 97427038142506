import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UserAvatar from 'react-user-avatar';
import LogRocket from 'logrocket';
import Nav from '../../components/chrome/nav';
import i18n from '../../helpers/i18n'; 
import Languages from '../../components/chrome/languages';
import { fetchNotifications } from '../../actions/messages';
import { userDelegateLogout } from '../../actions/users';
import { imageResize } from '../../helpers/s3';
import ErrorAlert from '../../components/chrome/error';
import Swal from 'sweetalert2';
import AppendHead from 'react-append-head';
import _ from 'lodash';
import ProgressBar from "@ramonak/react-progress-bar";

class Layout extends Component {

	constructor(props){
		super(props);
		
		this.state = {
			toggleNav: false,
			enableNotifications: false
		}
	}
	
	componentDidMount() {
		
		if(this.props.users.id){
			
			LogRocket.identify(this.props.users.id, {
			  	Key: localStorage.getItem('user_token'),
			  	Type: this.props.account.type,
			  	ProfileId: this.props.account.profile.id
			});
		}
		
		// CHECK NOTIFICATIONS - MOVE TO PUSH LATER
		if(this.state.enableNotifications){
			
			let _react = this;
		
			setInterval(function(){
				_react.props.fetchNotifications();
			}, (60000));
			
			this.props.fetchNotifications();
		}
	}
	
	toggleNav(force){
		
		let status = false;
		
		if(force){
			status = force;
		}else if(this.state.toggleNav){
			status = false;
		}else{
			status = 'is-sidebar-open'
		}
		
		this.setState({
			toggleNav: status
		});
	}
	
	toggleIcon(type, e){
			
		e.stopPropagation();
		
		if(!type || this.state.toggleIcon === type){
			this.setState({
				toggleIcon: false
			});
		}else{
			this.setState({
				toggleIcon: type
			});
		}
	}
	
	logout(){
		
		let logoutUrl = '/logout';
		
		if(this.props.users.delegate_id && this.props.users.delegate_id !== 'null'){
			
			let user = this.props.account.profile;
			
			Swal.fire({
				title: i18n.t('nav_logout_delegate_dialog_title'),
				text: i18n.t('nav_logout_delegate_dialog_description', { name: `${user.forename} ${user.surname}` }),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: i18n.t('nav_logout_delegate_all'),
				cancelButtonText: i18n.t('nav_logout_delegate_only', { name: `${user.forename} ${user.surname}` }),
				focusConfirm: false
			}).then((result) => {
	
				if(result.value) {
					window.location = logoutUrl;
				}else{
					this.props.userDelegateLogout();
				}
			});
			
			
		}else{
			window.location = logoutUrl;
		}		
	}
	
	delegate(){
		
		const user = this.props.account.profile;
		
		if(this.props.users.delegate_id && this.props.users.delegate_id !== 'null'){
			
			return (
				<div id="delegate-bar" className="c-navbar">
					<i className="far fa-exclamation-circle"></i>
					
					You are logged in as {`${user.forename} ${user.surname}`}. 
					<a href="#" onClick={() => {
						this.props.userDelegateLogout();
					}}>
						Back to my account
					</a>
				</div>
			);
		}
		
		return null;
	}
	
	render() {
		
		const user = this.props.account.profile;
		const group = this.props.account.group;
				
		switch(user?.role?.toLowerCase()){
			
			case 'internal':
			case 'retailer':
			
				const background = imageResize(`brands/${this.props.brand.details.directory}/heroes/assets.jpg`, 1500);
			
				return (
					<div className={`templated o-page ${this.state.toggleNav ? this.state.toggleNav : ''}`} onClick={(e) => { this.toggleIcon(false, e) }} ref={node => this.node = node }>
						<AppendHead>
				          	<link rel="stylesheet" type="text/css" href={`/css/template.css`} />
				        </AppendHead>
				        
				        {this.delegate()}
				        
				        <header
							style={{
								backgroundImage: `url(${background})`
							}}
						>
							<div className="primary">
								<Link to="/" className="brand-logo">
									{this.props.brand.details &&
										<img src={imageResize(`brands/${this.props.brand.details.directory}/template/logo-header.png`, 500)} alt={this.props.brand.details.name} />
									}
								</Link>
								<nav>
									<Nav 
										type="template"
									/>
									
									<div>							
										<div className={`c-dropdown dropdown ${this.state.toggleIcon === 'account' ? 'show' : ''}`}>
										
											<div className="c-avatar c-avatar--xsmall header-avatar-user dropdown-toggle" onClick={(e) => { this.toggleIcon('account', e) }}>
												 <UserAvatar 
												 	className="c-avatar__img"
												 	size="36" 
												 	name={`${user.forename} ${user.surname}`}
												 	src={user.image ? imageResize(user.image ? user.image : group.image, 100) : false}
												 	color="#FFF"
												 />
											</div>
											<div className="c-dropdown__menu -dropdown__menu--large header-account-dropdown has-arrow dropdown-menu dropdown-menu-right">
												<Link to="/account" className="c-dropdown__item dropdown-item">{i18n.t('nav_account')}</Link>
												<a href="#" onClick={() => { this.logout(); }} className="c-dropdown__item dropdown-item">{i18n.t('nav_logout')}</a>
											</div>
										</div>								
																	
										{this.props.brand.details && this.props.brand.details.languages.length > 1 &&
											<div className={`c-dropdown dropdown languages-dropdown ${this.state.toggleIcon === 'language' ? 'show' : ''}`}>
												<div className="c-avatar c-avatar--xsmall dropdown-toggle" onClick={(e) => { this.toggleIcon('language', e) }}>
													<img className="c-avatar__img" src={`/images/flags/${i18n.language}.svg`} alt={i18n.language} />
												</div>
									
												<div className="c-dropdown__menu -dropdown__menu--large has-arrow dropdown-menu dropdown-menu-right">
													<Languages 
														ignore={true}
														forceUpate={() => { this.forceUpdate.bind(this); }}
													/>
												</div>
											</div>
										}
									</div>
									
									<h6>{user.forename} {user.surname}</h6>
								</nav>
							</div>
							<div className="secondary">
								<div className="title">
									<h1>
										{this.props.title}
									</h1>
									<ul>
										{_.map(this.props.breadcrumbs, (crumb, key) => {
											
											return (
												<li key={key}>
													<Link to={crumb.url}>
														{crumb.label}
													</Link>
												</li>
											);
										})}
									</ul>
								</div>
								
								{this.props.progress !== false && parseFloat(this.props.progress) >= 0 && 
									<div className="progress">
										<ProgressBar 
											completed={Math.ceil(parseFloat(this.props.progress))} 
											borderRadius="1px"
											labelSize="10px"
											bgcolor="#FFFFFF"
											baseBgColor="#FFFFFF70"
											height={2}
										/>
									</div>
								}
							</div>
						</header>
											
						<main className="o-page__content">
							{this.props.children}
							<ErrorAlert />
						</main>
						
						<footer>
							<a href="/" className="brand-logo">
								{this.props.brand.details &&
									<img src={imageResize(`brands/${this.props.brand.details.directory}/template/logo-footer.png`, 500)} alt={this.props.brand.details.name} />
								}
							</a>
							<ul>
								<li>
									&copy; 2021 {this.props.brand.details.title}. All Rights Reserved.
								</li>
								<li>
									<Link to={this.props.account.docs}>
										{i18n.t('nav_documentation')}
									</Link>
								</li>
							</ul>
						</footer>
					</div>
				);
				break;
				
			default:
				return (
					<div className={`o-page ${this.state.toggleNav ? this.state.toggleNav : ''}`} onClick={(e) => { this.toggleIcon(false, e) }} ref={node => this.node = node }>
						<div className="o-page__sidebar js-page-sidebar">
							<aside className="c-sidebar">
								<div className="c-sidebar__brand">
									<a href="/">
										{this.props.brand.details &&
											<img src={imageResize(`brands/${this.props.brand.details.directory}/general/logo.png`, 500)} alt={this.props.brand.details.name} />
										}
									</a>
								</div>
						
								<div className="c-sidebar__body">
									<Nav 
										closeNav={() => { this.toggleNav(false); }}
									/>
								</div>				
								<a className="c-sidebar__footer" href="#" onClick={() => { this.logout() }}>
									{i18n.t('nav_logout')} <i className="c-sidebar__footer-icon far fa-power-off"></i>
								</a>
							</aside>
						</div>
						
						<main className="o-page__content">
							{this.delegate()}
							
							<header className="c-navbar u-mb-medium">
								<button className="c-sidebar-toggle js-sidebar-toggle" onClick={(e) => { this.toggleNav() }}>
									<i className="fal fa-bars"></i>
								</button>
						
								<h2 className="c-navbar__title">
									{this.props.breadcrumbs && this.props.breadcrumbs.length > 0 && 
										<Link to={this.props.breadcrumbs[this.props.breadcrumbs.length - 1].url}>
											<i className="fal fa-chevron-left"></i>
										</Link>
									}
									{this.props.title}
								</h2>
								
								{this.state.enableNotifications && 
									<div className={`c-dropdown dropdown u-mr-medium ${this.state.toggleIcon === 'notifications' ? 'show' : ''}`}>
										<div className={`c-notification dropdown-toggle ${this.props.messages.unread ? 'has-indicator ' : ''}`} onClick={(e) => { this.toggleIcon('notifications', e) }}>
											<i className={`c-notification__icon fal ${this.props.messages.unread ? 'fa-bell-on' : 'fa-bell'}`}></i>
										</div>
								
										<div className="c-dropdown__menu c-dropdown__menu--large has-arrow dropdown-menu dropdown-menu-right" >
								
											<span className="c-dropdown__menu-header">
												{i18n.t('nav_notifications')}
											</span>
											<Link to="/notifications/X" className="c-dropdown__item dropdown-item">
												<div className="o-media">
													<div className="o-media__img u-mr-xsmall">
														<span className="c-icon c-icon--info c-icon--xsmall">
															<i className="fal fa-globe"></i>
														</span>
													</div>
												
													<div className="o-media__body">
														<p>Notification copy here</p>
													</div>
												</div>
											</Link>
											<Link to="/notifications" className="c-dropdown__menu-footer">
												All Notifications
											</Link>
										</div>
									</div>
								}
								
								<div className="header-details">
									<h6>{user.forename} {user.surname}</h6>
									<p>{group.name}</p>
								</div>
								
								{user.image && 
									<div className="c-avatar c-avatar--xsmall header-avatar-user dropdown-toggle" onClick={(e) => { this.toggleIcon('account', e) }}>
										 <UserAvatar 
										 	className="c-avatar__img"
										 	size="36" 
										 	name={`${user.forename} ${user.surname}`}
										 	src={user.image ? imageResize(user.image, 100) : false}
										 	color="#FFF"
										 />
									</div>
								}
								
								<div className={`c-dropdown dropdown ${this.state.toggleIcon === 'account' ? 'show' : ''}`}>
									
									<div className="c-avatar c-avatar--xsmall header-avatar-company dropdown-toggle" onClick={(e) => { this.toggleIcon('account', e) }}>
										 <UserAvatar 
										 	className="c-avatar__img"
										 	size="36" 
										 	name={group.name}
										 	src={group.image ? imageResize(group.image, 100) : imageResize(`brands/${this.props.brand.details.directory}/general/icon.png`, 500)}
										 	color="#FFF"
										 />
									</div>
									<div className="c-dropdown__menu -dropdown__menu--large header-account-dropdown has-arrow dropdown-menu dropdown-menu-right">
										<Link to="/account" className="c-dropdown__item dropdown-item">{i18n.t('nav_account')}</Link>
										<a href="#" onClick={() => { this.logout(); }} className="c-dropdown__item dropdown-item">{i18n.t('nav_logout')}</a>
									</div>
								</div>
								
								{this.props.brand.details && this.props.brand.details.languages.length > 1 &&
									<div className={`c-dropdown dropdown languages-dropdown ${this.state.toggleIcon === 'language' ? 'show' : ''}`}>
										<div className="c-avatar c-avatar--xsmall dropdown-toggle" onClick={(e) => { this.toggleIcon('language', e) }}>
											<img className="c-avatar__img" src={`/images/flags/${i18n.language}.svg`} alt={i18n.language} />
										</div>
							
										<div className="c-dropdown__menu -dropdown__menu--large has-arrow dropdown-menu dropdown-menu-right">
											<Languages 
												ignore={true}
												forceUpate={() => { this.forceUpdate.bind(this); }}
											/>
										</div>
									</div>
								}
							</header>
							{this.props.children}
						</main>
						<ErrorAlert />
					</div>
				);
				break;
		}
	}
}


function mapStateToProps({ users, account, tracking, chrome, brand, messages, quizzes }, ownProps){
	return {
		users, 
		account, 
		tracking,
		title: chrome.title,
		breadcrumbs: chrome.breadcrumbs,
		brand,
		messages,
		progress: quizzes.progress
	};
}

export default connect(mapStateToProps, { 
	fetchNotifications,
	userDelegateLogout
})(Layout);