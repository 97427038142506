import { topicsConstants } from '../constants/topics';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 

export function fetchTopicsList(id, callback) {

    return async (dispatch) => {
		
		let url = '/topic/list';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: topicsConstants.TOPIC_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
			 			
		} catch(error) {
			console.log(error)
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveTopicGeneral(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
				
		try {
						
			if(id){
				request = await axiosCreate().patch(`/topic/save/${id}`, values);
			}else{
				request = await axiosCreate().post('/topic/save', values);
			}
			
			dispatch({
				type: topicsConstants.TOPIC_SAVE_GENERAL,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('topics:notification_setup_updated_title'),
					i18n.t('topics:notification_setup_updated_description')
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('topics:notification_setup_added_title'),
					i18n.t('topics:notification_setup_added_description')
				));
			}
			
			if(callback)
				callback(request.data);
			
			dispatch(stopSubmission(formName));

		} catch (error) {
			
			console.log(error);
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function saveTopicModules(formName, id, values, callback) {

	return async (dispatch) => {
						
		try {
						
			const request = await axiosCreate().put(`/topic/modules/${id}`, values);
			
			dispatch({
				type: topicsConstants.TOPIC_SAVE_MODULES,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('topics:notification_content_updated_title'),
				i18n.t('topics:notification_content_updated_description')
			));
			
			if(callback)
				callback(request.data);
			
			dispatch(stopSubmission(formName));

		} catch (error) {
			
			console.log(error);
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function deleteTopic(id, callback) {

	return async (dispatch) => {

		const request = await axiosCreate().delete(`/topic/delete/${id}`);
		
		try {

			dispatch({
				type: topicsConstants.TOPIC_DELETE,
				payload: request
			});
						
			if(callback)
				callback();
			
		} catch (error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function startTopic(topic_id, callback) {

	return async (dispatch) => {
						
		try {
						
			const request = await axiosCreate().post('/topic/start', { id: topic_id });
			
			dispatch({
				type: topicsConstants.TOPIC_START,
				payload: request
			});

			if(callback)
				callback(request.data);
			
		} catch (error) {
						
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function readTopicModule(topic_id, module_id, callback) {

	return async (dispatch) => {
						
		try {
						
			const request = await axiosCreate().post(`/topic/read/${topic_id}`, { id: module_id });
			
			dispatch({
				type: topicsConstants.TOPIC_READ_MODULE,
				payload: request
			});

			if(callback)
				callback(request.data);
			
		} catch (error) {
						
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}


