import { statsConstants } from '../constants/stats';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function userOverview() {

	return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get('/stats/overview')

			dispatch({
				type: statsConstants.USER_OVERVIEW,
				payload: request
			});

		} catch(error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		}
	};
}