// REWORK i18n.t params
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import { startSubmission, stopSubmission } from '../../actions/forms';
import AccountToolbar from './toolbar';
import Timezone from '../../components/users/timezone';

import { setSectionOptions } from '../../actions/chrome';
import _ from 'lodash';

import { userAccount } from '../../actions/account';
import { userSave } from '../../actions/users';
import { imageUrl } from '../../helpers/s3';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond/dist/filepond.min.css';

import * as Doka from '../../packages/react-doka/lib/doka.esm.min';
import '../../packages/react-doka/lib/doka.min.css';

registerPlugin(FilePondPluginFileEncode, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFilePoster, FilePondPluginFileValidateType, FilePondPluginImageCrop, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageEdit);

var formName = 'formAccount';

class Account extends Component {

	constructor(props){
		super(props);
			
		this.props.setSectionOptions(
			'account', 
			i18n.t('users:account_title')
		);
		this.props.stopSubmission(formName);
				
		let user = this.props.account.profile;	
		let files = [];
				
		if(user.image){
			
			files = [
				{
					source: imageUrl(user.image),
					options: {
		              	type: 'local'
		            }
				}
			];
		}
		
		this.state = {
			files: files,
			files_changed: false,
			language: user.language,
			timezone: user.timezone,
			password: false
		}	
					
		this.props.initialize({
			forename: user.forename,
			surname: user.surname,
			email: user.email,
			language: user.language,
			timezone: user.timezone
		});	
	}
		
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onSubmit(values) {
			  				  	
	  	this.props.startSubmission(formName);
			
		let posting = _.clone(values);
		
		if(this.state.files_changed){
			
			let files = _.map(this.avatar.getFiles(), (file, key) => {
			  	return {
				  	filename: file.filename,
				  	encoded: file.getFileEncodeBase64String()
				}
			});
						
			if(files.length > 0){
				posting.image = files[0].encoded;
			}else {
				posting.image = 'none';
			}
		}
		
		posting.language = this.state.language;
		posting.timezone = this.state.timezone;
	  	
	  	this.props.userSave(formName, this.props.account.profile.id, posting, (account) => {
		  	
		  	this.setState({
				files_changed: false
			});
			
			if(account.language !== i18n.language){
								
				i18n.changeLanguage(account.language, () => {
					this.forceUpdate();
			    });
			}
			
			this.props.userAccount();
	  	});
	}
	
	onInputLanguageChange(selected){
				
		this.setState({
			language: selected.value
		});
	}
	
	onInputTimezoneChange(selected){
				
		this.setState({
			timezone: selected.value
		});
	}
	
	onInputPasswordChange(selected){

		this.setState({
			password: selected.target.checked
		});
	}

	render() {
		
		const { handleSubmit } = this.props;

		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>

				<AccountToolbar
					formName={formName}
					section="settings"
				/>
				
				<div className="container">				
					<div className="row">
						<div className="col-xl-8 col-md-12">
							<div className="c-card">
								<h4 className="u-mb-medium">{i18n.t('users:account_title_general')} </h4>
								
								<div className="row">
									<div className="col-xl-6 col-md-12">
										<Field
											label={i18n.t('users:account_label_forename')} 
											name="forename"
											type="text"
											component={InputField}
										/>
									</div>
									<div className="col-xl-6 col-md-12">
										<Field
											label={i18n.t('users:account_label_surname')} 
											name="surname"
											type="text"
											component={InputField}
										/>
									</div>
								</div>
																
								<div className="row">
									<div className="col-xl-6 col-md-12">
										<Field
											label={i18n.t('users:account_label_email')} 
											name="email"
											type="email"
											component={InputField}
										/>
									</div>
									<div className="col-xl-6 col-md-12">
										<Field
											label={i18n.t('users:account_label_language')} 
											name="language"
											type="suggest"
											options={_.map(this.props.brand.details.languages, (language, key) => {
											
												return {
													label: i18n.t(`translation:language_${language}`),
													value: language,
													//imageDropdown: `/images/flags/${language}.svg`
												}
				
											})}
											sort="asc"
											selected={this.state.language}
											onChangeFunc={this.onInputLanguageChange.bind(this)}
											component={InputField}
										/>
									</div>
								</div>
								<div className="row">
									<div className="col-xl-6 col-md-12">
										<Timezone
											label={i18n.t('users:account_label_timezone')}
											selected={this.state.timezone}
											onChangeFunc={this.onInputTimezoneChange.bind(this)}
											component={Field}
										/>
									</div>
								</div>																
							</div>
							<div className="c-card">
								<h4>{i18n.t('users:account_title_password')}</h4>
								<p className="u-mb-medium">{i18n.t('users:account_password_intro')}</p>
								
								<Field
									name="password"
									labelSwitch={i18n.t('users:account_label_password_change')} 
									type="switch"
									selected={this.state.password}
									onChangeFunc={this.onInputPasswordChange.bind(this)}
									component={InputField}
								/> 
								
								{this.state.password && 
								
									<div className="row">
										<div className="col-xl-6 col-md-12">
											<Field
												label={i18n.t('users:account_label_password_new')} 
												name="password_new"
												type="password"
												component={InputField}
											/>
										</div>
										<div className="col-xl-6 col-md-12">
											<Field
												label={i18n.t('users:account_label_password_confirm')} 
												name="password_confirm"
												type="password"
												component={InputField}
											/>
										</div>
									</div>
								}
							</div>
						</div>
						<div className="col-xl-4 col-md-12">
							<div className="c-card">
								<h4 className="u-mb-medium">{i18n.t('users:account_title_avatar')} </h4>
				
								<div className="filepond-container minimal">
									<FilePond 
										maxFiles="1"
										ref={ref => this.avatar = ref}
				                      	allowFileEncode={true}
										acceptedFileTypes="image/*"
				                      	labelIdle={i18n.t('filepond_idle')}
				                      	labelFileLoading={i18n.t('filepond_fileLoading')}
				                      	labelTapToCancel={i18n.t('filepond_tapToCancel')}
				                      	labelFileWaitingForSize={i18n.t('filepond_waitingForSize')}
				                      	files={this.state.files}
									    imageCropAspectRatio="1:1"
									    stylePanelLayout="circle"
									    stylePanelAspectRatio="1:1"
									    styleButtonRemoveItemPosition="center bottom"
									    styleButtonProcessItemPosition="center bottom"
									    styleLoadIndicatorPosition="center bottom"
									    styleProgressIndicatorPosition="center bottom"
									    className="circle"
									    server={{
											process: null,
											load: (source, load, error, progress, abort, headers) => {
																							
												fetch(source)
													.then((res) => {
											            return res.blob();
											        }).then((res) => {
												      	load(res);
											        });
											}
										}}
										onupdatefiles={(files) => {
	
											if(files.length > 0){
	
												let fileItems = files.map(fileItem => fileItem.file);
																								
												if(files[0].origin !== 3){ // NOT LOADED ON INIT
													this.setState({
														files: fileItems,
														files_changed: true
													});
												}
												
											}else{
												this.setState({
													files: [],
													files_changed: true
												});
											}
										}}
										imageEditEditor={Doka.create({
											onconfirm: (output) => {
												this.setState({
													files_changed: true
												});
											}
										})}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</form>
		);
	}
}

const validate = (values) => {	
	
	const errors = {};

	if (!values.forename) {
		errors.forename = 'Enter your forename!';
	}
	
	if (!values.surname) {
		errors.surname = 'Enter your surname!';
	}
	
	if (!values.email) {
		errors.email = 'Enter your email!';
	}
	
	if (!values.language) {
		errors.language = 'Enter your language!';
	}
	
	if (!values.timezone) {
		errors.timezone = 'Enter your timezone!';
	}
	
	if (!values.password_new) {
		errors.password_new = 'Enter your new password!';
	}
	
	if (!values.password_confirm) {
		errors.password_confirm = 'Enter your new password again!';
	}
	
	if (values.password_new !== values.password_confirm) {
		errors.password_confirm = 'New passwords do not match!';
	}
		
	return errors;
}

function mapStateToProps({ account, brand, users, options }, ownProps){
	return {
		account,
		brand,
		users,
		options
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		setSectionOptions,
		userAccount,
		userSave
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(Account)
);