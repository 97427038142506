import React, { Component } from 'react';
import FileViewer from 'react-file-viewer';
import ReactPlayer from 'react-player'
import { imageUrl, imageResize } from '../../helpers/s3';
import ReactAudioPlayer from 'react-audio-player';

class AssetItem extends Component {

	render(){
		
		let asset  = this.props.asset;
		let url = '';
		
		if(!asset){
			return null;
		}
		
		if(asset.type === 'vimeo' || asset.type === 'youtube' || asset.type === 'video'){
			
			switch(asset.type){
				
				case 'vimeo':
					url = `https://vimeo.com/${asset.filename}`;
					break;
					
				case 'youtube':
					url = `https://youtube.com/watch?v=${asset.filename}`;
					break;
					
				default:
					url = imageUrl(asset.filename);
					break;
			}
			
			/*let playIcon = (
				<i className="fad fa-play-circle play-video"></i>
			);*/
			
			let playIcon = (
				<img src={imageUrl('general/play.png')} width="75" alt={asset.title} />
			);

			return (
				<div className="video-wrapper">
					<ReactPlayer
				        url={url}
				        playing={true}
				        controls={true}
				        light={imageUrl(asset.thumbnail)}
				        width="100%"
				        height="100%"
				        config={{
							youtube: {
								playerVars: { 
									modestbranding: 1
								}
							},
							vimeo: {
								title: false
							}
						}}
						playIcon={playIcon}
				     />
				</div>
			);
			
		}else if(asset.type === 'audio'){
			
			return (
				<ReactAudioPlayer
					src={imageUrl(asset.filename)}
					controls
				/>
			);
			
		}else if(asset.type === 'image'){
			
			return (
				<img src={imageResize(asset.filename, 1000)} alt={asset.name} />
			);
			
		}else if(asset.type === 'presentation'){
			
			return (
				<iframe 
					src={`https://view.officeapps.live.com/op/embed.aspx?src=${imageUrl(asset.filename)}`} 
					width="100%"
					height="500px" 
					frameBorder="0"
					style={{
						minHeight: '400px',
						aspectRatio: '4/3',
						height: 'auto',
						maxHeight: '80vh'
					}}
				/>
			);
			
		}else{
					
			return (
				<FileViewer
			        fileType={asset.extension}
			        filePath={imageUrl(asset.filename)}
			     />
			);
		} 
	}
}

export default AssetItem;