import React from 'react'
import { connect } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import promise from 'redux-promise';
import reduxThunk from 'redux-thunk';
import reducers from './reducers';
import RoutesTemplated from './routes/templated';
import { RouteUnprotected, RouteProtected } from './routes/protection';
import ReactGA from 'react-ga';
import Notifications from './components/notifications/index';
import { I18nextProvider, withTranslation, useTranslation } from 'react-i18next';
import i18n from './helpers/i18n';
import Brand from './components/chrome/brand';
import Loading from './components/chrome/loading';

import UserLogin from './containers/users/login';
import UserForgotten from './containers/users/forgotten';
import UserReset from './containers/users/reset';
import UserWelcome from './containers/users/welcome';
import UserLogout from './containers/users/logout';
import Maintenance from './components/errors/maintenance';

import LogRocket from 'logrocket';

LogRocket.init(process.env.REACT_APP_LOG_ROCKET_KEY);

const enhancer = compose(
	applyMiddleware(
		promise, 
		reduxThunk,
		LogRocket.reduxMiddleware()
	)
);

const store = createStore(reducers, enhancer);

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY, {
	debug: process.env.NODE_ENV === 'production' ? false : true
});

const App = () => {	
	
	 const { ready } = useTranslation(['translation', 'dashboard', 'assets', 'retailers', 'topics', 'quizzes', 'regional', 'library', 'settings', 'users', 'achievements', 'categories', 'export', 'errors'], { useSuspense: false });
	 
	 if(ready){

		 return (
			<Provider store={store}>
				<BrowserRouter>
					<Brand />
					<Notifications />
					<Switch>

						{/* NON TEMPLATED ROUTES */}
						<RouteUnprotected path="/welcome/:token" component={UserWelcome} />
						<RouteUnprotected path="/login/forgotten/:token" component={UserReset} />
						<RouteUnprotected path="/login/forgotten" component={UserForgotten} />
						<RouteUnprotected path="/login" component={UserLogin} />
						<RouteProtected path="/logout" component={UserLogout} />
						<Route path="/maintenance" component={Maintenance} />
						
						{/* TEMPLATED ROUTES */}
						<Route path="*" component={RoutesTemplated} />
					</Switch>
				</BrowserRouter>

			</Provider>
		);
	}else{
			
		return (
			<Loading />
		)
	}
}

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
    	<App />
    </I18nextProvider>,
	document.getElementById('root')
);

function mapStateToProps({ overlay, i18n }, ownProps) {
	return {
		overlay,
		i18n
	};
}

export default connect(
  	connect(mapStateToProps)
)(withTranslation(['translation', 'dashboard', 'assets', 'retailers', 'topics', 'quizzes', 'regional', 'library', 'settings', 'users', 'achievements', 'categories', 'export', 'errors'])(App));



//export default connect(mapStateToProps)(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();