import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import _ from 'lodash';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import { setSectionOptions } from '../../../actions/chrome';
import RetailsDetailsToolbar from './toolbar';
import { checkPermission } from '../../../helpers/permissions';
import { generateBack } from '../../../helpers/back';

var formName = 'formRetailersAnalytics';

class RetailersAnalytics extends Component {

	constructor(props){
		super(props);
		
		let { id } = this.props.match.params;
		let own = false;

		if(!id){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
				this.props.history.push('/');
			}
			
			own = true;
			id = this.props.account.group.id;
			this.props.setSectionOptions(
				'settings', 
				i18n.t('users:settings_title'),
				[
					{
						url: 'todo',
						label: 'todo'
					}
				]
			);
			
		}else{

			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'RETAILERS_ANALYTICS')){
				this.props.history.push('/retailers');
			}
		}
		
		if(!id){
			this.props.history.push('/retailers');
		}
					       	       			
		this.state = {
			own: own,
			id: id
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	render() {
	
		return (

			<React.Fragment>
				
				{!this.state.own && 
					<React.Fragment>
						<OverlaySettings closeURL={this.props.parentPath} />
						
						<header className="c-navbar u-mb-medium">
							<h2 className="c-navbar__title">
								Analytics
							</h2>
						</header>
					</React.Fragment>
				}
				
				<RetailsDetailsToolbar
					formName={formName}
					section="analytics"
					id={this.state.id}
					own={this.state.own}
					{...this.props}
				/>

				<div className={this.state.own ? 'container' : null}>
					<div className="c-card">
						Coming soon!
					</div>
				</div>
				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, users, retailers }, ownProps){
	return {
		users,
		retailers,
		account
	};
}

export default connect(mapStateToProps, { 
})(RetailersAnalytics);