import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ModalRoute } from 'react-router-modal';

import { RouteProtected} from './protection';

import Layout from '../components/chrome/layout';
import ModalWrapper from '../components/chrome/overlay';
import GAModal from '../components/tracking/ga_modal';
import Loading from '../components/chrome/loading';

import Error404 from '../components/errors/404';
import Dashboard from '../containers/dashboard';

import Assets from '../containers/assets';
import AssetsView from '../containers/assets/view';
import AssetsEdit from '../containers/assets/edit';

import Library from '../containers/library';

import Collections from '../containers/collections';
import CollectionsEdit from '../containers/collections/edit';

import Categories from '../containers/categories';
import CategoriesEdit from '../containers/categories/edit';

import Users from '../containers/users';

import Retailers from '../containers/retailers';
import RetailersSettings from '../containers/retailers/details/settings';
import RetailersUsers from '../containers/retailers/details/users';
import RetailersAnalytics from '../containers/retailers/details/analytics';

import Topics from '../containers/topics';
import TopicsSetup from '../containers/topics/setup';
import TopicsContent from '../containers/topics/content';
import TopicsView from '../containers/topics/view';

import Quizzes from '../containers/quizzes';
import QuizzesSetup from '../containers/quizzes/setup';
import QuizzesContent from '../containers/quizzes/content';
import QuizzesView from '../containers/quizzes/view';

import RetailersGroups from '../containers/retailers/groups/index';
import RetailersGroupsGroup from '../containers/retailers/groups/edit';
import RetailersGroupsAssigned from '../containers/retailers/groups/assigned';

import EditUser from '../containers/users/user';

import Regional from '../containers/regional';
import RegionalSettings from '../containers/regional/edit';
import RegionalUsers from '../containers/regional/users';


import AchievementsView from '../containers/achievements/view';
import Achievements from '../containers/achievements';

import Account from '../containers/account';
import AccountPreferences from '../containers/account/preferences';

import Export from '../containers/export';

import { userAccount } from '../actions/account';

class RoutesTemplated extends Component {
	
	constructor(props) {
		
		super(props);
						
		if(!localStorage.getItem('user_id')) {
			this.props.history.push('/logout');
		}
				
		this.state = {
			display: false
		}
		
		this.props.userAccount();
	}
	
	componentDidUpdate(nextProps) {
		
		if(!this.state.display && this.props.account.permissions !== false){

			this.setState({
				display: true
			});	
		}
	}
	
	render() {
		
		if(!this.state.display){
			
			return (
				<Loading />
			);
		}
		
		return (
			<React.Fragment>
				<Layout>
					<Switch>
						
						<RouteProtected path="/assets" component={Assets} />
					
						<RouteProtected path="/retailers/groups" component={RetailersGroups} />
						<RouteProtected path="/retailers" component={Retailers} />
						
						<RouteProtected path="/regional" component={Regional} />
						<RouteProtected path="/users" component={Users} />
						
						<RouteProtected path="/modules/:id(\d+)/setup" component={TopicsSetup} />
						<RouteProtected path="/modules/:id(\d+)/lessons" component={TopicsContent} />
						<RouteProtected path="/modules/:module(\d+)/:topic(\d+)/quiz" component={QuizzesView} />
						<RouteProtected path="/modules/:id(\d+)/:topic(\d+)" component={TopicsView} />
						<RouteProtected path="/modules/:id(\d+)" component={TopicsView} />
						<RouteProtected path="/modules/add" component={TopicsSetup} />
						<RouteProtected path="/modules" component={Topics} />
						
						<RouteProtected path="/quizzes/:id(\d+)/setup" component={QuizzesSetup} />
						<RouteProtected path="/quizzes/:id(\d+)/questions" component={QuizzesContent} />
						<RouteProtected path="/quizzes/:id(\d+)" component={QuizzesView} />
						<RouteProtected path="/quizzes/add" component={QuizzesSetup} />
						<RouteProtected path="/quizzes" component={Quizzes} />
						
						<RouteProtected path="/achievements" component={Achievements} />
												
						<RouteProtected path="/account/preferences" component={AccountPreferences} />
						<RouteProtected path="/account" component={Account} />
						
						<RouteProtected path="/categories" component={Categories} />
																		
						<RouteProtected path="/export" component={Export} />
																		
						<RouteProtected exact path="/" component={Dashboard} />
						
						{/* NO MATCH, SHOW 404 */}
						<Route path="*" component={Error404} /> 
					</Switch> 
				</Layout>
				<Switch>
								
					{/* MODALS */}
					<ModalRoute path="*/library" component={GAModal(Library)} />
										
					<ModalRoute path="*/collection/:id(\d+)/edit" component={GAModal(CollectionsEdit)} />
					<ModalRoute path="*/collection/:id(\d+)" component={GAModal(Collections)} />
					<ModalRoute path="*/collection/add" component={GAModal(CollectionsEdit)} />
					
					<ModalRoute path="*/asset/:id(\d+)/edit" component={GAModal(AssetsEdit)} />
					<ModalRoute path="*/asset/:id(\d+)" component={GAModal(AssetsView)} />
					<ModalRoute path="*/asset" component={GAModal(AssetsEdit)} />	
					
					<ModalRoute path="*/group/:id(\d+)/assigned" component={GAModal(RetailersGroupsAssigned)} />
					<ModalRoute path="*/group/:id(\d+)" component={GAModal(RetailersGroupsGroup)} />
					<ModalRoute path="*/group/add" component={GAModal(RetailersGroupsGroup)} />
					
					<ModalRoute path="*/user/:type/:id(\d+)" component={GAModal(EditUser)} />
					<ModalRoute path="*/user/:type" component={GAModal(EditUser)} />	
					
					<ModalRoute path="*/retailer/:id(\d+)/analytics" component={GAModal(RetailersAnalytics)} />
					<ModalRoute path="*/retailer/:id(\d+)/users" component={GAModal(RetailersUsers)} />
					<ModalRoute path="*/retailer/:id" component={GAModal(RetailersSettings)} />
					<ModalRoute path="*/retailer/add" component={GAModal(RetailersSettings)} />
					
					<ModalRoute path="*/region/:id(\d+)/users" component={GAModal(RegionalUsers)} />
					<ModalRoute path="*/region/:id" component={GAModal(RegionalSettings)} />
					<ModalRoute path="*/region/add" component={GAModal(RegionalSettings)} />
					
					<ModalRoute path="*/category/:id(\d+)" component={GAModal(CategoriesEdit)} />
					<ModalRoute path="*/category" component={GAModal(CategoriesEdit)} />
					
					<ModalRoute path="*/achievement/:id(\d+)" component={GAModal(AchievementsView)} />
				</Switch>
				<ModalWrapper />
			</React.Fragment>
		)
	}
}

function mapStateToProps({ account }, ownProps) {
	return {
		account
	 };
}

export default connect(mapStateToProps, { 
	userAccount
})(RoutesTemplated);