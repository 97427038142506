import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import _ from 'lodash';
import InputField from '../../../components/forms/field.js';
import { startSubmission, stopSubmission } from '../../../actions/forms';
import Loading from '../../../components/chrome/loading';

import RetailersGroupsToolbar from './toolbar';
import { checkPermission } from '../../../helpers/permissions';
import RetailersList from '../../../components/retailers/list';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import { fetchRetailersList, fetchRetailersGroups, assignRetailersGroup } from '../../../actions/retailers';

import EmptySection from '../../../components/chrome/empty';

var formName = 'formRetailersGroupsAssign';

class RetailersGroupsAssigned extends Component {

	constructor(props){
		super(props);
		
		let { id } = this.props.match.params;

		if(!id){
			this.props.history.push('/');
		}
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'RETAILERS_GROUPS_EDIT')){
			this.props.history.push('/');
		}

		this.props.fetchRetailersList();
		
		this.props.fetchRetailersGroups(id, () => {
	       	
	       	if(this.props.retailers.groups && !this.props.retailers.groups[id]){
				this.props.history.push('/retailers/groups');
			}
       	});
       	
       	this.state = {
	       	id: id,
	       	retailer: false
       	}
		
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onSubmit(values) {
		
	  	this.props.startSubmission(formName);
	  		  	
	  	values.retailer = _.map(this.state.retailer, (retailer, key) => {					
			  	
		  	if(retailer.value){
			  	return retailer.value;
		  	}else{
			  	return retailer;
		  	}
		  	
		}, this);
			  	
	  	this.props.assignRetailersGroup(formName, this.state.id, values, () => {
		  	
		  	this.setState({
				retailer: false
			});
			
	  	});
	}
	
	onInputRetailerChange(selected){
				
		this.setState({
			retailer: selected
		});
	}
	
	renderAdd(){
		
		let { retailers } = this.props;
		let group = retailers.groups[this.state.id];
			
		let unassigned = [];
		
		if(retailers.retailers){
			
			unassigned = retailers.retailers;

			if(group.retailers){
				unassigned = _.filter(unassigned, retailer => (!group.retailers.includes(retailer.id)) );
			}
		}
		
		
		if(_.isEmpty(unassigned)){
			
			return (
				<React.Fragment>
					<p>{i18n.t('retailers:groups_assign_label_retailer_empty')} </p>
				</React.Fragment>
			)
			
		}else{
			
			return (
				<React.Fragment>
					
					<Field
						label={i18n.t('retailers:groups_assign_label_retailer', { count: this.state.retailer.length })} 
						name="retailer"
						type="suggest"
						options={_.map(unassigned, (retailer, key) => {
																			
							return ({
								label: retailer.name,
								value: retailer.id							
							});
						})}
						multiple={true}
						selected={this.state.retailer}
						onChangeFunc={this.onInputRetailerChange.bind(this)}
						component={InputField}
					/>
					
					
				</React.Fragment>
			)
		}
	}
	
	renderList(){
		
		let { retailers } = this.props;
		let group = retailers.groups[this.state.id];
			
		let assigned = [];
		
		if(retailers.retailers && group.retailers){
			assigned = _.filter(retailers.retailers, retailer => (group.retailers.includes(retailer.id)) );
		}
		
		if(_.isEmpty(assigned)){
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t('retailers:empty_group_assigned_title')}
					description={i18n.t('retailers:empty_group_assigned_description')}
				/>
			)
			
		}else{
			
			return (
				<React.Fragment>
					<h4 className="u-mb-medium">{i18n.t('retailers:title_groups_assigned_list')}</h4>
					<RetailersList 
						data={assigned}
						permissions={this.props.account.permissions}	
						{...this.props}
					/>										
				</React.Fragment>
			)
		}
	}
	
	renderPage(){
		
		const { retailers } = this.props;
		
		if(!retailers.retailers || !retailers.groups || !retailers.groups[this.state.id]){
			return (
				<Loading />
			);
		}
				
		return (
			<div className="row">
				<div className="col-md-4">
					<div className="c-card">
						<h4 className="u-mb-medium">{i18n.t('retailers:title_groups_assigned_add', { count: this.state.retailer.length })}</h4>
						{this.renderAdd()}
					</div>
				</div>
			
				<div className="col-md-8">
					<div className="c-card">
						{this.renderList()}
					</div>
				</div>
			</div>	
		);
	}

	render() {
		
		let { retailers } = this.props;
		let title = '';
		let buttons = [];
		
		if(retailers.groups && retailers.groups[this.state.id]){
			title = retailers.groups[this.state.id].name;
		}
		
		const { handleSubmit } = this.props;
		
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>
				
				<RetailersGroupsToolbar
					section="assigned"
					id={this.state.id}
					retailer={this.state.retailer}
					formName={formName}
					{...this.props}
				/>
				
				{this.renderPage()}
					
			</form>
		);
		
	}
}

const validate = (values) => {	
	
	const errors = {};

	if (!values.retailer) {
		errors.retailer = 'Select a retailer to assign to this group!';
	}
	
	return errors;
}

function mapStateToProps({ account, retailers }, ownProps){
	return {
		account,
		retailers
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		startSubmission, 
		stopSubmission,
		fetchRetailersList, 
		fetchRetailersGroups,
		assignRetailersGroup
	} )(RetailersGroupsAssigned)
);