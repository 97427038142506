import React, { Component } from 'react';
import { connect } from 'react-redux';
import Toolbar from '../../components/chrome/toolbar';
import i18n from '../../helpers/i18n'; 
import Swal from 'sweetalert2';
import { checkPermission } from '../../helpers/permissions';
import moment from 'moment';
import { showNotification } from '../../actions/notifications';
import { deleteTopic } from '../../actions/topics';

class TopicsToolbar extends Component {

	render() {
		
		let tabs = [];
		let buttons = [];
		let filter = false;

		if(this.props.id){
			
			tabs = [
				{
					label: i18n.t('topics:toolbar_setup'),
					url: `/modules/${this.props.id}/setup`,
					selected: this.props.section === 'setup' ? true : false
				},
				{
					label: i18n.t('topics:toolbar_topics'),
					url: `/modules/${this.props.id}/lessons`,
					selected: this.props.section === 'content' ? true : false,
					counter: this.props.topic ? this.props.topic.modules.length : 0
					
				}
			];
			
		}else{
			
			tabs = [{
				label: i18n.t('topics:toolbar_setup'),
				url: `/modules/add`,
				selected: this.props.section === 'setup' ? true : false
			}];
		}

		switch(this.props.section){
				
			case 'setup':
				if(this.props.id){
											
					buttons.push({
						onClick: () => {
							
							Swal.fire({
								title: i18n.t('topics:delete_dialog_title'),
								text: i18n.t('topics:delete_dialog_description'),
								icon: 'warning',
								showCancelButton: true,
								confirmButtonText: i18n.t('dialog_confirm'),
								cancelButtonText: i18n.t('dialog_cancel'),
								focusConfirm: false
							}).then((result) => {

								if(result.value) {
																			
									this.props.deleteTopic(this.props.id, () => {
										
										this.props.showNotification(
											'success',
											i18n.t('topics:notification_setup_deleted_title'),
											i18n.t('topics:notification_setup_deleted_description')
										);
										
										this.props.history.push('/modules');
									});
								}
							});
						},
						outline: true,
						className: "c-btn--danger",
						label: i18n.t('topics:settings_delete_submit'),
					});
				}
				
				buttons.push({
					label: this.props.id ? i18n.t('topics:settings_save_submit') : i18n.t('topics:settings_save_continue'),
					submit: this.props.formName,
					proceed: this.props.id ? false : true
				});
				break;
				
			case 'content':
				buttons.push({
					label: i18n.t('topics:content_save_submit'),
					submit: this.props.formName
				});
				break;
			
			default:
			
				break;			
		}

		return (
			<Toolbar
				tabs={tabs}
				buttons={buttons}
				filter={filter}
			/>			
		);
	}
}

function mapStateToProps({ account }, ownProps){
	return {
		account
	};
}

export default connect(mapStateToProps, { 
	deleteTopic,
	showNotification
})(TopicsToolbar);