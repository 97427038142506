import { categoriesConstants } from '../constants/categories';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 

export function fetchCategoriesList(id, callback) {

	return async (dispatch) => {
		
		let url = '/categories/list';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);

			dispatch({ 
				type: categoriesConstants.CATEGORIES_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
						 
		} catch(error) {
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveCategory(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate().patch(`/categories/save/${id}`, values);
			}else{
				request = await axiosCreate().post('/categories/save', values);
			}

			dispatch({
				type: categoriesConstants.CATEGORY_SAVE,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('categories:notification_category_updated_title'),
					i18n.t('categories:notification_category_updated_description')
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('categories:notification_category_added_title'),
					i18n.t('categories:notification_category_added_description')
				));
			}
			
			callback(request.data.categories[0].id);
			dispatch(stopSubmission(formName));

		} catch (error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function saveSort(values, callback) {

	return async (dispatch) => {
		
		let request = '';
		
		try {
						
			request = await axiosCreate().post('/categories/sort', { categories: values });

			dispatch({
				type: categoriesConstants.CATEGORIES_SORT,
				payload: request
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('categories:notification_category_sorted_title'),
				i18n.t('categories:notification_category_sorted_description')
			));
			
			if(callback){
				callback();
			}
			
		} catch (error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}