import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import EmptySection from '../../components/chrome/empty';

import Loading from '../../components/chrome/loading';
import RetailersList from '../../components/retailers/list';

import { setSectionOptions } from '../../actions/chrome';
import RetailersToolbar from './toolbar';
import { checkPermission } from '../../helpers/permissions';
import { fetchRetailersList } from '../../actions/retailers';

class Retailers extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'RETAILERS')){
			
			if(checkPermission(this.props.account.permissions, 'RETAILERS_GROUPS')){
				this.props.history.push('/retailers/groups');
			}else{
				this.props.history.push('/');
			}
		}
		
		this.props.setSectionOptions(
			'retailers', 
			i18n.t('retailers:title')
		);
		this.props.fetchRetailersList();
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	renderPage(){
		
		let { retailers } = this.props;

		if(!retailers.retailers){
			return (
				<Loading />
			);
		}
		
		if(_.isEmpty(this.props.retailers.retailers)){
			
			let cta = false;
			
			if(checkPermission(this.props.account.permissions, 'RETAILERS_ADD')){
				cta = {
					label: i18n.t('retailers:action_add'),
					url: `${this.props.location.pathname}/retailer/add`
				}
			}
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t('retailers:empty_title')}
					description={i18n.t('retailers:empty_description')}
					cta={cta}
				/>
			);			
		}
					
		return (
			<div className="container">				
				<div className="c-card">
					<RetailersList
						data={this.props.retailers.retailers}
						permissions={this.props.account.permissions}	
					/>
				</div>
			</div>
		);
	}
	
	render() {
			
		return (

			<React.Fragment>
				
				{checkPermission(this.props.account.permissions, 'RETAILERS_GROUPS') && 
					<RetailersToolbar
						section="list"
					/>
				}
				
				{this.renderPage()}				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, retailers }, ownProps){
	return {
		account,
		retailers
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchRetailersList
})(Retailers);