import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import TopicsToolbar from './toolbar';
import moment from 'moment-timezone';
import 'moment/min/locales';
import Loading from '../../components/chrome/loading';
import { startSubmission, stopSubmission } from '../../actions/forms';
import AssetPicker from '../../components/assets/picker';

import _ from 'lodash';
import Swal from 'sweetalert2';
import { fetchTopicsList, saveTopicModules } from '../../actions/topics';
import { fetchQuizzesList } from '../../actions/quizzes';

import { setSectionOptions } from '../../actions/chrome';

import { checkPermission } from '../../helpers/permissions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import { DefaultEditor } from 'react-simple-wysiwyg';

var formName = 'formTopicContent';

class TopicContent extends Component {

	constructor(props){
		super(props);
						
		const { id } = this.props.match.params;
		
        if(!id){
	       	this.props.history.push('/modules');
		}else{
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'TOPICS_EDIT')){
				this.props.history.push('/modules');
			}
		}
		
		this.props.stopSubmission(formName);
		
		this.state = {
			id: id,
			modules: [{
				status: true,
				//content: EditorState.createEmpty()
				content: [{
					status: true,
					type: 'text',
					content: ''
				}]
			}],
			module: 0
		}
		
		this.props.fetchQuizzesList();
		
		this.props.fetchTopicsList(id, () => {
		       	
	       	if(this.props.topics.topics && !this.props.topics.topics[id]){
				this.props.history.push('/modules');
			}else{
				this.updateDomElements();
			}
       	});
       	
		this.updateDomElements();     
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	updateDomElements(){
				
		if(this.props.topics.topics && this.props.topics.topics[this.state.id]){
		
			const topic = this.props.topics.topics[this.state.id];		
			let init = {};
			
			if(topic.modules.length > 0){
				
				this.setState({
					modules: _.map(topic.modules, (module, key) => {					
						module.status = true;
						
						init[`title_${key}`] = module.title;
						init[`summary_${key}`] = module.summary;
										
						module.content = _.map(module.content, (content, key2) => {		
							
							content.status = true;
							
							if(this.state.module === key){
								init[`content_${key2}`] = content.content;
							}
							
							return content;
						});
						
						return module;
					})
				});
			}
			
			this.props.setSectionOptions(
				'modules', 
				topic.title,
				[
					{
						url: '/modules',
						label: 'Modules'
					}
				]
			);
			this.props.initialize(init);
		}else{
			this.props.setSectionOptions(
				'modules', 
				'Modules',
				[
					{
						url: '/modules',
						label: 'Modules'
					}
				]
			);
		}
	}
	
	onSubmit(values) {
		
		this.props.startSubmission(formName);
	  	
	  	var posting = { 
		  	modules: _.map(this.state.modules, (module) => {
			  	//module.content = draftToHtml(convertToRaw(module.content.getCurrentContent()));
			  	//module.content = module.content; //TEMP
				module.content = _.filter(module.content, { 'status': true });
			  	return module;
		  	})
		}

	  	this.props.saveTopicModules(formName, this.state.id, posting);
	}
	
	updateModule(type, value){
		
		let modules = this.state.modules;
		
		modules[this.state.module][type] = value;
		
		this.setState({
			modules: modules
		});
	}
	
	paginateModule(type, key, ret){
		
		let newKey = false;
		let checkKey = 0;
		
		if(key === 0){
			
			checkKey = 1;
			
			while(newKey === false) {
				
				if(this.state.modules[checkKey].status){
					newKey = checkKey;
				}else{
					++checkKey;
				}
			}
		}
		
		if(key > 0 || !newKey){
			
			checkKey = key-1;
			
			while(newKey === false && checkKey >= 0) {
				
				if(this.state.modules[checkKey].status){
					newKey = checkKey;
				}else{
					--checkKey;
				}
			}
		}
				
		if(ret){
			return newKey
		}else{	
			this.setState({
				module: newKey
			});
		}
	}
	
	renderPage(){
		
		const { topics } = this.props;
		
		if(!topics.topics || !topics.topics[this.state.id] || !this.props.quizzes.quizzes){
			return (
				<Loading />
			);
		}
		
		const topic = topics.topics[this.state.id];
		const module = this.state.modules[this.state.module];
		const active = _.filter(this.state.modules, { 'status': true });
		const activeBlocks = _.filter(this.state.modules[this.state.module].content, { 'status': true });
		const quizzes = _.filter(this.props.quizzes.quizzes, quiz => quiz.status === 'approved');
				
		let quiz_warnings = [];
		
		if(module.quiz_id){
						
			const quiz = _.find(quizzes, { id: module.quiz_id });
			
			if(quiz){
				
				if(topic.public == '1' && quiz.public == '0'){
					quiz_warnings.push('This quiz will be available to retailers within this lesson, despite it being disabled on the quiz itself.');
				}
				
				if(quiz.date_launch && quiz.date_launch > moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss') && topic.date_launch < quiz.date_launch){
					quiz_warnings.push(`This quiz will be available within this lesson, despite it being set to launch ${moment(quiz.date_launch).format('LLL')}.`);
				}
			}
		}
				
		return (
			<div className="container">				
				<div className="row">
					<div className="col-xl-4 col-md-12">
					
						<div className="c-card">
														
							<h4 className="u-mb-medium">{i18n.t('topics:content_title_topics')} </h4>				
							
							<DragDropContext
								onDragEnd={(result) => {
									if (!result.destination) return;
									
									const modules = Array.from(this.state.modules);
									const [reorderedItem] = modules.splice(result.source.index, 1);
									modules.splice(result.destination.index, 0, reorderedItem);
															
									let new_id = this.state.module;
									
									if(this.state.module === result.source.index){
										new_id = result.destination.index;
									}else if(this.state.module > result.destination.index){
										--new_id;
									}else{
										new_id = this.paginateModule(new_id === 0 ? 'next' : 'previous', new_id, true);
									}
									
									let init = {}
									
									this.setState({
										modules: modules,
										module: new_id
									});
									
									_.forEach(modules, (module, key) => {					
										init[`title_${key}`] = module.title;
										init[`summary_${key}`] = module.summary;
									});
									
									if(this.state.modules[new_id].content && this.state.modules[new_id].content.length > 0){
										
										_.forEach(this.state.modules[new_id].content, (content, key) => {					
											init[`content_${key}`] = content.content;
										});	
																			
									}else{
										init[`content_0`] = '';
									}
									
									this.props.initialize(init);
								}}
							>
								<Droppable droppableId="modules">
								    {(provided) => (
									    
									    <ul className="modules" {...provided.droppableProps} ref={provided.innerRef}>
			
											{_.map(this.state.modules, (module, key) => {
												
												if(!module.status){
													return null;
												}
																								
												return (
													<Draggable key={key} draggableId={key.toString()} index={key}>
														{(provided) => (
															<li ref={provided.innerRef} {...provided.draggableProps}>
																<div 
																	className={`c-card clickable reduced u-mb-small ${this.state.module === key ? 'selected' : ''}`} 
																	key={key}
																	onClick={() => {
																		this.setState({
																			module: key
																		});
																																				
																		if(this.state.modules[key].content && this.state.modules[key].content.length > 0){
																			
																			_.forEach(this.state.modules[key].content, (content, key2) => {	
																				this.props.change(`content_${key2}`, content.content);	
																			});
																			
																		}else{
																			this.props.change('content_0', '');
																		}
																	}}
																>
																	<h6 className="split">
																		{module.title ? module.title : i18n.t('topics:content_topic_unnamed')}
																		
																		{active.length > 1 && 
																			<div className="options">
																				<i 
																					className="far fa-arrows"
																					{...provided.dragHandleProps}
																				></i>
																				<i 
																					className="far fa-trash"
																					onClick={() => {
																						
																						Swal.fire({
																							title: i18n.t('topics:delete_topic_dialog_title'),
																							text: i18n.t('topics:delete_topic_dialog_description'),
																							icon: 'warning',
																							showCancelButton: true,
																							confirmButtonText: i18n.t('dialog_confirm'),
																							cancelButtonText: i18n.t('dialog_cancel'),
																							focusConfirm: false
																						}).then((result) => {
																				
																							if (result.value) {
																								
																								let existing = this.state.modules;
																																
																								existing[key].status = false;
																								
																								let newKey = false;
																								let checkKey = 0;
																								
																								this.paginateModule(key === 0 ? 'next' : 'previous', key);
																															
																								this.setState({
																									modules: existing
																								});
																							}
																						});														
																					}}
																				></i>
																			</div>												
																		}
																	</h6>
																	
																	{module.summary && module.summary !== '' &&  
																		<small>{module.summary}</small>
																	}											
																</div>
															</li>
															
														)}
													</Draggable>														
													
												);
											})}
											{provided.placeholder}
										</ul>
									)}
									
								</Droppable>
							</DragDropContext>
							
							<div id="cta">
								<button
									type="button"
									className="c-btn c-btn--info c-btn--outline"
									onClick={() => {
										
										let existing = this.state.modules;
										const newKey = existing.length;
																				
										existing[newKey] = {
											status: true,
											//content: EditorState.createEmpty(),
											content: [{
												status: true, 
												type: 'text',
												content: ''
											}]
										}
										
										this.setState({
											modules: existing,
											module: newKey
										});
										
										this.props.change('content_0', '');
									}}
								>
									{i18n.t('topics:content_topic_add')}
								</button>
							</div>
						</div>
					</div>				
					<div className="col-xl-8 col-md-12">
						
						<div className="c-card" key={this.state.module}>
														
							<h4 className="u-mb-medium">{i18n.t('topics:content_title_editor')} </h4>				
																							
							<Field
								label={i18n.t('topics:settings_content_topic_title')} 
								name={`title_${this.state.module}`}
								type="text"
								value={module.title ? module.title : ''}
								onChangeFunc={(event) => {
									this.updateModule('title', event.target.value)
									
								}}
								component={InputField}
							/>
							
							<Field
								label={i18n.t('topics:settings_content_topic_summary')} 
								name={`summary_${this.state.module}`}
								type="textarea-auto"
								maxLength={250}
								maxLengthCounter={true}
								value={module.summary ? module.summary : ''}
								onChangeFunc={(event) => {
									this.updateModule('summary', event.target.value)
									
								}}
								component={InputField}
							/>
							
							{topic.quiz_pass_rate !== null && 
															
								<React.Fragment>
									<Field
										label={i18n.t('topics:settings_content_topic_quiz')} 
										name={`quiz_${this.state.module}`}
										type="suggest"
										options={_.map(quizzes, (quiz) => {
											
											return {
												label: quiz.title,
												value: quiz.id
											}
										})}
										selected={module.quiz_id ? module.quiz_id : false}
										onChangeFunc={(selected) => {
											this.updateModule('quiz_id', selected.value)
										}}
										component={InputField}
									/>
									
									{quiz_warnings.length > 0 &&
										
										<small className="u-mb-small" style={{ display: 'block', opacity: '0.5' }}>
											{_.map(quiz_warnings, (warning, key) => {
											
												return (
													<p 
														key={key}
														style={{ fontSize: '11px' }}
													>
														{warning}
													</p>
												);
											})}
										</small>
										
									}								
								</React.Fragment>
							}
							
							<div className="c-field">
								<label className="c-field__label">{i18n.t('topics:settings_content_topic_content', { count: activeBlocks.length })}</label>
								<DragDropContext
									onDragEnd={(result) => {
										if (!result.destination) return;
										
										let existing = this.state.modules[this.state.module].content;
										
										existing = Array.from(existing);
										const [reorderedItem] = existing.splice(result.source.index, 1);
										existing.splice(result.destination.index, 0, reorderedItem);
																							
										_.forEach(existing, (content, key) => {					
											this.props.change(`content_${key}`, content.content);
										});
										
										this.updateModule('content', existing);
									}}
								>
									<Droppable droppableId="module_content">
										{(provided) => (
											
											<ul className="module_content" {...provided.droppableProps} ref={provided.innerRef}>
								
												{_.map(this.state.modules[this.state.module].content, (content, key) => {
													
													if(!content.status){
														return null;
													}
													
													return (
														<Draggable key={key} draggableId={key.toString()} index={key}>
															{(provided) => (
																<li ref={provided.innerRef} {...provided.draggableProps}>
																	
																	<div 
																		className="c-card reduced u-mb-small"
																	>
																		<h6 className="split">
																			<Field
																				type="suggest"
																				options={[
																					{
																						label: 'Text',
																						value: 'text'
																					},
																					{
																						label: 'Asset',
																						value: 'asset'
																					}
																					
																				]}
																				selected={content.type}
																				onChangeFunc={(selected) => {
																					let existing = this.state.modules[this.state.module].content;
																					existing[key].type = selected.value;
																					existing[key].content = null;
																					this.updateModule('content', existing);
																				}}
																				component={InputField}
																			/>
																																						
																			{activeBlocks.length > 1 && 
																				<div className="options" style={{ marginTop: '8px' }}>
																					<i 
																						className="far fa-arrows"
																						{...provided.dragHandleProps}
																					></i>
																					<i 
																						className="far fa-trash"
																						onClick={() => {
																							
																							Swal.fire({
																								title: i18n.t('topics:delete_content_dialog_title'),
																								text: i18n.t('topics:delete_content_dialog_description'),
																								icon: 'warning',
																								showCancelButton: true,
																								confirmButtonText: i18n.t('dialog_confirm'),
																								cancelButtonText: i18n.t('dialog_cancel'),
																								focusConfirm: false
																							}).then((result) => {
																					
																								if (result.value) {
																									let existing = this.state.modules[this.state.module].content;
																									existing[key].status = false;
																									this.updateModule('content', existing);
																								}
																							});														
																						}}
																					></i>
																				</div>												
																			}
																		</h6>
																		
																		
																																	
																		
																																				
																		{content.type === 'asset' && 
																			<AssetPicker
																				types={['image','video','audio','document']}
																				max={1}
																				selected={content.content ? [{ type: 'asset', id: parseFloat(content.content) }] : false}
																				placeholder={i18n.t('quizzes:settings_asset_empty')}
																				onSelect={(selected) => {
																					
																					let asset_id = false;
																														
																					if(selected && selected.length > 0){
																						asset_id = selected[0].id;
																					}
																														
																					let existing = this.state.modules[this.state.module].content;
																					existing[key].content = asset_id;
																					this.updateModule('content', existing);
																				}}
																			/>
																		}
																		
																	</div>									
																</li>
																
															)}
														</Draggable>														
														
													);
												})}
												{provided.placeholder}
											</ul>
										)}
										
									</Droppable>
								</DragDropContext>
								<button
									type="button"
									className="c-btn c-btn--info c-btn--outline"
									onClick={() => {
										
										let existing = this.state.modules[this.state.module].content;
										const newKey = existing.length;
																				
										existing[newKey] = {
											status: true, 
											type: 'text',
											content: ''
										}
																				
										this.updateModule('content', existing);
										this.props.change(`content_${newKey}`, '');
									}}
								>
									{i18n.t('topics:content_content_add')}
								</button>
								
								
								
								
								
								
							</div>
							
							
							
							
							
							
							
						</div>				
					</div>
				</div>
			</div>
		)
	}
	
	render() {
	
		const { handleSubmit, topics } = this.props;
				
		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
			
				<TopicsToolbar 
					formName={formName}
					id={this.state.id}
					section="content"
					history={this.props.history}
					topic={(topics.topics && topics.topics[this.state.id]) ? topics.topics[this.state.id] : false}
				/>
				
				{this.renderPage()}
			</form>				
		);
	}
}

const validate = (values, props) => {	
			
	const errors = {};
	
	let i = 0;
	
	while (i < 200) {
		
		if (!values[`title_${i}`]) {
			errors[`title_${i}`] = 'Please enter a title!';
		}
		
		if (!values[`summary_${i}`]) {
			errors[`summary_${i}`] = 'Please enter a summary!';
		}
		
		if (!values[`content_${i}`]) {
			errors[`content_${i}`] = 'Please enter content!';
		}
		
		i++;
	}
	
	return errors;
}

function mapStateToProps({ topics, account, quizzes }, ownProps){
	return {
		topics,
		account,
		quizzes
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		setSectionOptions,
		fetchTopicsList,
		saveTopicModules,
		fetchQuizzesList
	})(
	reduxForm({
		validate: validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(TopicContent)
);
