import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

export default class DropzoneArea extends Component {
	
	constructor(props){
		super(props);

		this.state = {
			files: [],
			uploadStatus: false,
			uploadIcon: false
		}
	}
		
	render(){
		
		if(this.props.disabled){
			
			return(
				<React.Fragment>
					{this.props.children}
				</React.Fragment>
			);
		}
		
		return (
			<Dropzone 
				onDropAccepted={files => {
					
					this.setState({
						uploadStatus: this.props.statusUploadText ? this.props.statusUploadText : 'dropzone_uploading',
						uploadIcon: this.props.statusUploadIcon ? this.props.statusUploadIcon : 'fa-spinner-third fa-spin'
					});
					
					let reader = [];
					
					files.forEach((file, index) => {

						reader[index] = new FileReader();
						reader[index].readAsDataURL(file);
	
						reader[index].onload = (event) => {
							this.setState({					          	
					          	files: this.state.files.concat([{ 
						          	filename: file.name, 
						          	encoded:  event.target.result.split(',')[1]
						        }])
					        });
					        
					        if(this.state.files.length === files.length){
						        this.props.processFiles(
						        	this.state.files,
									() => {
										this.setState({
											uploadStatus: false
										});
									},
									() => {
										this.setState({
											uploadStatus: false
										});
									}
						        );
						        
						        this.setState({
									files: []
								});
					        }
						};
					}, this);
				}}
				onDragEnter={() => {
					this.setState({
						uploadStatus: this.props.statusDragText ? this.props.statusDragText : 'dropzone_hovering',
						uploadIcon: this.props.statusDragIcon ? this.props.statusDragIcon : 'fa-plus'
					});
				}}
				onDragLeave={() => {
					this.setState({
						uploadStatus: false
					});
				}}
				onDropRejected={() => {
					this.setState({
						uploadStatus: false
					});
				}}
				noClick={true}
			>
				{({getRootProps, getInputProps}) => (
					<div {...getRootProps()} className={`dropzone ${this.state.uploadStatus}`}>
						
						<input {...getInputProps()} />
						
						{this.state.uploadStatus &&
							<div className={`status ${this.props.border ? 'border' : null}`}>
								<div>
									<i className={`fal ${this.state.uploadIcon}`}></i>
								</div>
							</div>
						}
							
						{this.props.children}				
					</div>
				)}
			</Dropzone>
		);
	}
}