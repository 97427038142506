import { libraryConstants } from '../constants/library';

export function makeSelection(type, assets, action, callback){

	return async (dispatch) => {

	    if(!action){
			action = 'add';
		}
		
	    dispatch({ 
			type: libraryConstants.LIBRARY_SELECTION,
			payload: {
				type: type,
				assets: assets,
				action: action
			}
		});
		
		if(callback)
			callback();
	}
}

export function setupLibrary(types, max, assets, onSelect) {

    return async (dispatch) => {
	    	    
	    dispatch({ 
			type: libraryConstants.LIBRARY_SETUP,
			payload: {
				types: types,
				selected: assets,
				max: max,
				onSelect: onSelect
			}
		});
	}
}