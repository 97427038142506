import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import Table from '../../../components/chrome/table';
import _ from 'lodash';
import OverlaySettings from '../../../components/chrome/overlay_settings';

import Loading from '../../../components/chrome/loading';
import EmptySection from '../../../components/chrome/empty';
import { imageResize } from '../../../helpers/s3';

import { setSectionOptions } from '../../../actions/chrome';
import RetailsDetailsToolbar from './toolbar';
import { checkPermission } from '../../../helpers/permissions';
import { fetchRetailersList } from '../../../actions/retailers';
import { usersFetch, userReinvite, userDelegateLogin } from '../../../actions/users';
import { generateBack } from '../../../helpers/back';

var formName = 'formRetailersUsers';

class RetailersUsers extends Component {

	constructor(props){
		super(props);
		
		let { id } = this.props.match.params;
		let own = false;

		if(!id){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'SETTINGS')){
				this.props.history.push('/');
			}
			
			own = true;
			id = this.props.account.group.id;
			this.props.setSectionOptions(
				'settings', 
				i18n.t('users:settings_title'),
				[
					{
						url: 'todo',
						label: 'todo'
					}
				]
			);
			
		}else{

			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'RETAILERS_USERS')){
				this.props.history.push('/retailers');
			}
		}
		
		if(!id){
			this.props.history.push('/retailers');
		}
		
		this.props.fetchRetailersList(id, () => {
	       	
	       	if(this.props.retailers.retailers){
		       
		       	if(!this.props.retailers.retailers[id]){
				
					if(own){
						this.props.history.push('/settings');
					}else{
						this.props.history.push('/retailers');
					}
					
				}else{
					this.updateDomElements();
				}
			}
       	});

		this.props.usersFetch(false, id);
					       	       			
		this.state = {
			own: own,
			id: id
		}
		
		this.updateDomElements();
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	updateDomElements(){
		
		if(this.props.retailers.retailers && this.state.id){
		
			//let retailer = this.props.retailers.retailers[this.state.id];			
		}
	}
	
	renderPage(){
		
		let { retailers } = this.props;
				
		let users = [];
		
		if(retailers.retailers[this.state.id].users && this.props.users.users){
						
			_.forEach(retailers.retailers[this.state.id].users, (user, key) => {	
				
				if(this.props.users.users[user]){
					users.push(this.props.users.users[user]);
				}
			}, this)			
		}
								
		if(_.isEmpty(users)){

			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t('retailers:empty_users_title', { type: this.state.type })}
					description={i18n.t('retailers:empty_users_description', { type: this.state.type })}
					cta={{
						label: i18n.t('retailers:action_add_user'),
						url: this.state.own ? '/settings/users/user/retailer' : `${this.props.parentPath}/user/retailer?id=${this.state.id}&b=${generateBack(window.location.pathname)}`
					}}
				/>
			);
		}
		
		let back = generateBack(window.location.pathname);
		
		return (
			<div className={this.state.own ? 'container' : null}>
				<div className="c-card">
					<Table 
						columns={[
							{
								key: "image",
								label: "",
								format: 'image_circle',
								className: 'tight'
							},
							{
								key: "name",
								label: "Name",
								sortable: true
							},
							{
								key: "status",
								label: "Status",
								sortable: true
							},
							{
								key: "actions",
								label: "Actions",
								className: "tight right"
							}
						]}
						data={_.map(users, (user, key) => {
										
							let name = `${user.forename} ${user.surname}`;	
							let actions = ['edit'];
														
							if(user.active === '0'){
								actions.push('resend');
							}else if(checkPermission(this.props.account.permissions, 'RETAILERS_USERS_LOGIN')){
								actions.push('login');
							}
							
							return ({
								id: user.id,
								image: {
									src: user.image ? imageResize(user.image, 100) : false,
									icon: 'fal fa-user',
									alt: name
								},
								name: name,
								status: user.active === '1' ? i18n.t('retailers:users_label_status_active') : i18n.t('retailers:users_label_status_invited'),
								actions: actions								
							});
						})}
						actions={{
							edit: {
								tooltip: i18n.t('retailers:users_action_edit'),
								icon: 'fal fa-edit',
								url: this.state.own ? '/settings/users/user/retailer/[ID]' : `${this.props.parentPath}/user/retailer/[ID]?b=${back}`
							},
							resend: {
								tooltip: i18n.t('retailers:users_action_resend_invite'),
								icon: 'fal fa-envelope',
								onClick: (id, e) => {
									this.props.userReinvite(id);
								}
							},
							login: {
								tooltip: i18n.t('retailers:users_action_login'),
								icon: 'fal fa-sign-in',
								onClick: (id, e) => {
									this.props.userDelegateLogin(id, () => {
										this.props.history.push('/');
									});
								}
							},
						}}
						sort={{
							column: 'name',
							order: 'asc'
						}}
						rowClick={(row) => {
							if(this.state.own){
								this.props.history.push(`/settings/users/user/retailer/${row.id}`);
							}else{
								this.props.history.push(`${this.props.parentPath}/user/retailer/${row.id}?b=${back}`);
							}
						}}
					/>
				</div>
			</div>
		)
	}
	render() {
		
		let { retailers } = this.props;
				
		if(!retailers.retailers || !retailers.retailers[this.state.id]){
			return (
				<Loading />
			);
		}
		
		return (

			<React.Fragment>
				
				{!this.state.own && 
					<React.Fragment>
						<OverlaySettings closeURL={this.props.parentPath} />
						
						<header className="c-navbar u-mb-medium">
							<h2 className="c-navbar__title">
								{retailers.retailers[this.state.id].name}
							</h2>
						</header>
					</React.Fragment>
				}
				
				<RetailsDetailsToolbar
					formName={formName}
					section="users"
					id={this.state.id}
					own={this.state.own}
					{...this.props}
				/>

				{this.renderPage()}
				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, users, retailers }, ownProps){
	return {
		users,
		retailers,
		account
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchRetailersList,
	usersFetch,
	userReinvite,
	userDelegateLogin
})(RetailersUsers);