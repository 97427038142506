// REWORK i18n.t params
import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import { usersFetch, userSavePreferences } from '../../actions/users';
import { userAccount } from '../../actions/account';

import { startSubmission, stopSubmission } from '../../actions/forms';
import AccountToolbar from './toolbar';

import { setSectionOptions } from '../../actions/chrome';

var formName = 'formAccountPreferences';

class AccountPreferences extends Component {

	constructor(props){
		super(props);
			
		this.props.setSectionOptions(
			'account', 
			i18n.t('users:account_title')
		);
		this.props.stopSubmission(formName);
		
		let user = this.props.account.profile;			

		this.state = {
			email: user.notification_email ? true : false,
			mobile: user.mobile !== null ? user.mobile : ''
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	onSubmit(values) {
			  				  	
	  	this.props.startSubmission(formName);
			
		let posting = {};
			
		if(this.state.email){
			posting.notification_email = 'yes';
		}else{
			posting.notification_email = 'no';
		}
		
	  	this.props.userSavePreferences(formName, this.props.account.profile.id, posting, () => {
		  	this.props.userAccount();
	  	});
	}
	
	onInputEmailChange(selected){
				
		this.setState({
			email: selected.target.checked
		});
	}
		
	onInputMobileChange(value){
		
		this.setState({
			mobile: value
		});
	}

	render() {

		const { handleSubmit } = this.props;

		return (

			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>

				<AccountToolbar
					formName={formName}
					section="preferences"
				/>
				
				<div className="container">				
					<div className="row">
					
						<div className="col-xl-4 col-md-12">
							<div className="c-card">						
								<Field
									name="email"
									labelSwitch={i18n.t('users:account_preferences_label_email')} 
									type="switch"
									selected={this.state.email}
									onChangeFunc={this.onInputEmailChange.bind(this)}
									component={InputField}
								/> 
								
								{this.state.email && 
									<InputField
										label={i18n.t('users:account_preferences_label_email_address')} 
										type="echo"
										value={this.props.account.profile.email}
									/>
								}
							</div>
						</div>
					</div>
				</div>
				
			</form>
		);
	}
}

const validate = (values) => {	
	
	const errors = {};
	
	if (!values.mobile) {
		errors.mobile = 'Please enter your mobile!';
	}
	
	return errors;
}

function mapStateToProps({ account, users }, ownProps){
	return {
		account, 
		users
	};
}

export default connect(
	mapStateToProps, {
		startSubmission, 
		stopSubmission,
		setSectionOptions,
		usersFetch, 
		userSavePreferences,
		userAccount
	})(
	reduxForm({
		validate,
		form: formName,
		enableReinitialize: true,
		keepDirtyOnReinitialize: true,
		onSubmitFail: (errors, dispatch) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
	})(AccountPreferences)
);