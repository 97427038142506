import { achievementsConstants } from '../constants/achievements';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 

export function fetchAchievementsList(callback) {

    return async (dispatch) => {

		try {
			const request = await axiosCreate().get('/achievement/list');
										
			dispatch({ 
				type: achievementsConstants.ACHIEVEMENTS_LIST,
				payload: request
			});
			
			if(callback){
				callback();
			}
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}