import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import EmptySection from '../../components/chrome/empty';
import Table from '../../components/chrome/table';

import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import { imageResize } from '../../helpers/s3';

import { setSectionOptions } from '../../actions/chrome';
import { checkPermission } from '../../helpers/permissions';
import { usersFetch, userReinvite, userDelegateLogin } from '../../actions/users';
import { fetchRetailersList } from '../../actions/retailers';

class Users extends Component {

	constructor(props){
		super(props);			
			
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'RETAILERS_USERS') && !checkPermission(this.props.account.permissions, 'INTERNAL_USERS') && !checkPermission(this.props.account.permissions, 'REGIONAL_USERS')){
			this.props.history.push('/');
		}
		
		this.props.setSectionOptions(
			'users', 
			i18n.t('users:title')
		);
		this.props.usersFetch();
		
		if(checkPermission(this.props.account.permissions, 'RETAILERS_USERS')){
			this.props.fetchRetailersList();
		}
		
		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		
		let type = 'internal';
		
		if(queryString.type){
			type = queryString.type;
		}
		
		this.state = {
			type: type,
			search: false,
			filters: {
				type: false,
				status: false,
				keyword: false
			}
		}
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	filterData(type){
		
		let users = this.props.users.users;
		
		let data = {
			regional: [],
			internal: [],
			retailer: []
		};
				
		// APPLY FILTERS
		if(this.state.search){
			
			if(this.state.filters.keyword){
				users = _.filter(users, user => (user.forename.toLowerCase().includes(this.state.filters.keyword.toLowerCase()) || user.surname.toLowerCase().includes(this.state.filters.keyword.toLowerCase()) ));
			}
		}
				
		if(type === 'counter' || this.state.type === 'regional'){
			data.regional = _.filter(users, user => user.role === 'regional');
		}
		
		if(type === 'counter' || this.state.type === 'internal'){
			data.internal = _.filter(users, user => user.role === 'internal');
		}
		
		if(type === 'counter' || this.state.type === 'retailer'){
			data.retailer = _.filter(users, user => user.role === 'retailer');
		}
						
		if(type === 'counter'){
			
			return {
				regional: data.regional.length,
				internal: data.internal.length,
				retailer: data.retailer.length
			};
					
		}else{
			return data[this.state.type];
		}
	}
	
	renderTable(){

		let data = this.filterData();
				
		if(_.isEmpty(data)){
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t(`users:empty_${this.state.type}_title`)}
					description={i18n.t(`users:empty_${this.state.type}_description`)}
					cta={{
						label: i18n.t(`users:action_${this.state.type}_add`),
						url: `/users/user/${this.state.type}`
					}}
				/>
			);			
		}
		
		let rowClick = (row) => {
			this.props.history.push(`/users/user/${this.state.type}/${row.id}`);
		};
									
		let cols = [
			{
				key: "image",
				label: "",
				format: 'image_circle',
				className: 'tight'
			},
			{
				key: "name",
				label: "Name",
				sortable: true
			}
		];
						
		switch(this.state.type){
			
			case 'retailer':				
				cols.push({
					key: "retailer",
					label: "Retailer",
					sortable: true
				});
			
				break;	
				
			case 'internal':
				cols.push({
					key: "retailer",
					label: "Retailer Access",
					sortable: true
				});
				break;		
				
			default:
			
				break;
		}
		
		cols.push({
			key: "status",
			label: "Status",
			sortable: true
		});

		cols.push({
			key: "actions",
			label: "Actions",
			className: "tight right"
		});
		
		return (
			<div className="container">
				<div className="c-card">
					<Table 
						columns={cols}
						data={_.map(data, (user, key) => {
			
							let name = `${user.forename} ${user.surname}`;	
							let retailer = false;
							let actions = ['edit'];
											
							if(user.active === '0'){
								actions.push('resend');
							}else if(user.role == 'regional' && checkPermission(this.props.account.permissions, 'REGIONAL_USERS_LOGIN')){
								actions.push('login');
							}else if(user.role == 'internal' && checkPermission(this.props.account.permissions, 'RETAILERS_USERS_LOGIN')){
								actions.push('login');
							}else if(user.role == 'retailer' && checkPermission(this.props.account.permissions, 'INTERNAL_USERS_LOGIN')){
								actions.push('login');
							}
							
							switch(user.role){
							
								case 'retailer':
									
									if(this.props.retailers.retailers){
										
										if(retailer = _.find(this.props.retailers.retailers, (retailer) => {
											return _.includes(retailer.users, user.id)
										})){
											retailer = {
												value: retailer.name,
												url: `/users/retailer/${retailer.id}`
											}
										}									
									}
									break;
									
								case 'internal':
									if(user.retailers){
										
										if(Array.isArray(user.retailers)){
											retailer = `${user.retailers.length} Group${user.retailers.length > 1 ? 's' : ''}`;
										}else{
											retailer = 'All';
										}
									}else{
										retailer = 'None';
									}
									break;
							}
							
							return ({
								id: user.id,
								image: {
									src: user.image ? imageResize(user.image, 100) : false,
									icon: 'fal fa-user',
									alt: name
								},
								name: name,
								retailer: retailer,
								status: user.active === '1' ? i18n.t('users:users_label_status_active') : i18n.t('users:users_label_status_invited'),
								actions: actions								
							});
						})}
						actions={{
							edit: {
								tooltip: i18n.t('users:users_action_edit'),
								icon: 'fal fa-edit',
								url: `/users/user/${this.state.type}/[ID]`
							},
							resend: {
								tooltip: i18n.t('users:users_action_resend_invite'),
								icon: 'fal fa-envelope',
								onClick: (id, e) => {
									this.props.userReinvite(id);
								}
							},
							login: {
								tooltip: i18n.t('users:users_action_login'),
								icon: 'fal fa-sign-in',
								onClick: (id, e) => {
									this.props.userDelegateLogin(id, () => {
										this.props.history.push('/');
									});
								}
							},
						}}
						sort={{
							column: 'name',
							order: 'asc'
						}}
						rowClick={(row) => {
							this.props.history.push(`/users/user/${this.state.type}/${row.id}`);
						}}
					/>
				</div>
			</div>
		);
	}
	
	onChangeType(type){
		this.setState({
			type: type,
		});
	}
	
	render() {
		
		let { users } = this.props;
		
		if(!users.users){
			return (
				<Loading />
			);
		}
		
		let counter = this.filterData('counter');
		let tabs = [];
		let buttons = [];
		
		if(checkPermission(this.props.account.permissions, 'REGIONAL_USERS')){
			tabs.push({
				label: i18n.t('users:toolbar_regional'),
				counter: String(counter.regional),
				onClick: () => { this.onChangeType('regional') },
				selected: this.state.type === 'regional' ? true : false
			});
		}
		
		if(checkPermission(this.props.account.permissions, 'INTERNAL_USERS')){
			tabs.push({
				label: i18n.t('users:toolbar_internal'),
				counter: String(counter.internal),
				onClick: () => { this.onChangeType('internal') },
				selected: this.state.type === 'internal' ? true : false
			});
		}
		
		if(checkPermission(this.props.account.permissions, 'RETAILERS_USERS')){
			tabs.push({
				label: i18n.t('users:toolbar_retailers'),
				counter: String(counter.retailer),
				onClick: () => { this.onChangeType('retailer') },
				selected: this.state.type === 'retailer' ? true : false
			});
		}
		
		
		if(checkPermission(this.props.account.permissions, 'INTERNAL_USERS_ADD')){
			buttons.push({
				label: i18n.t('users:action_internal_add'),
				url: '/users/user/internal'
			})
		}
		
		if(checkPermission(this.props.account.permissions, 'RETAILERS_USERS_ADD')){
			buttons.push({
				label: i18n.t('users:action_retailer_add'),
				url: '/users/user/retailer'
			})
		}
					
		return (

			<React.Fragment>
				<Toolbar
					tabs={tabs}
					buttons={buttons}
					filter={{
						toggleFunc: (status) => {
							this.setState({
								search: status
							});
						},
						onChangeFunc: (event, type) => { 
							
							let filters = this.state.filters;
							
							switch(type){
								
								case 'keyword':
									filters.keyword = event.target.value;
									break;
									
								case 'type':
								case 'status':
									filters[type] = event.value;
									break;	
								
								default:
									return;
							}
					
							this.setState({
								filters: filters
							})
						},
						fields: [
							/*{
								placeholder: i18n.t('quizzes:filter_type'),
								name: "type",
								type: 'suggest',
								options: [
									{
										value: 1,
										label: 'Item 1'
									},
									{
										value: 2,
										label: 'Item 2'
									}
								]
							},
							{
								placeholder: i18n.t('quizzes:filter_status'),
								name: "status",
								type: 'suggest',
								options: [
									{
										value: 1,
										label: 'Item 1'
									},
									{
										value: 2,
										label: 'Item 2'
									}
								]
							},*/
							{
								placeholder: i18n.t('users:filter_keyword'),
								name: "keyword",
								type: 'text'
							}
						],
						focus: 'keyword'
					}}
				/>
				
				{this.renderTable()}				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, users, retailers }, ownProps){
	return {
		account,
		users,
		retailers
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	usersFetch, 
	userReinvite, 
	userDelegateLogin,
	fetchRetailersList
})(Users);