import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import EmptySection from '../../components/chrome/empty';
import Table from '../../components/chrome/table';
import moment from 'moment-timezone';
import 'moment/min/locales';
import Loading from '../../components/chrome/loading';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import AchievementsGridItem from '../../components/achievements/grid_item';
import { imageResize } from '../../helpers/s3';

import { setSectionOptions } from '../../actions/chrome';
import { checkPermission } from '../../helpers/permissions';
import { fetchAchievementsList } from '../../actions/achievements';

class Achievements extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'ACHIEVEMENTS')){
			this.props.history.push('/');
		}
		
		this.props.setSectionOptions(
			'achievements', 
			i18n.t('achievements:title')
		);
		
		this.props.fetchAchievementsList();	
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	
	handleResize(){
		
		let width = window.innerWidth;
		let columns = 1;
		
		if(width >= 1200){
			columns = 6;
		}else if(width >= 992){
			columns = 3;
		}else if(width >= 576){
			columns = 2;
		}
		
		this.setState({
			columns: columns
		});
	}
	
	render() {
		
		let { achievements } = this.props;
		
		if(!achievements.achievements){
			return (
				<Loading />
			);
		}
		
		let data = _.orderBy(this.props.achievements.achievements, ['completed', 'desc']);
		
		return(
			<div className="container">
				<div id="grid_progress">
					<div className="available c-card u-pb-zero">
						
						{_.isEmpty(data) && 
							<EmptySection
								icon="fa-exclamation-circle"
								title={i18n.t(`achievements:empty_achievements_title`)}
								description={i18n.t(`achievements:empty_achievements_description`)}
							/>
						||
							<div className="row u-pb-zero topics">
								{_.map(data, (item, key) => {	
				
									return (
										<AchievementsGridItem 
											key={key}
											topic={item}
											icon={'fa-trophy'}
											size="small"
										/>						
									);
								})}
							</div>
						}
					</div>
				</div>
			</div>
		);			
	}
}

function mapStateToProps({ account, achievements }, ownProps){
	return {
		account,
		achievements
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchAchievementsList
})(Achievements);