import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import AssetItem from '../../components/assets/item';
import { fetchAssetsList } from '../../actions/assets';
import Loading from '../../components/chrome/loading';
import AssetToolbar from './toolbar';

class AssetsView extends Component {

	constructor(props){
		super(props);
		
		const { id } = this.props.match.params;

		if(!id){
			this.props.history.push(this.props.parentPath);
		}		
				
		this.state = {
			id: id
		}
		
		this.props.fetchAssetsList(id, () => {
		       	
	       	if(this.props.assets.assets && !this.props.assets.assets[id]){
				this.props.history.push(this.props.parentPath);
			}
       	});
	}
		
	render() {
		
		if(!this.props.assets.assets || !this.props.assets.assets[this.state.id]){
			return (
				<Loading />
			);
		}
		
		let asset  = this.props.assets.assets[this.state.id];
		
		return (
			<React.Fragment>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{asset.name}
					</h2>
				</header>
				
				<AssetToolbar
					id={this.state.id}
					section="view"
					readonly={asset.readonly === 1 ? true : false}
					{...this.props}
				/>
				
				<div className="view-section">
					<AssetItem
						asset={this.props.assets.assets[this.state.id]}
					/>
				</div>
			</React.Fragment>
		);
	}
}

function mapStateToProps({ assets, overlay }, ownProps){
	return {
		assets,
		overlay
	};
}

export default connect(mapStateToProps, { 
	fetchAssetsList
})(AssetsView);