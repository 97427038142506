import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { LazyImage } from "react-lazy-images";
import { imageResize, imageUrl } from '../../helpers/s3';
import i18n from '../../helpers/i18n'; 
import { setupLibrary } from '../../actions/library';
import { fetchAssetsList } from '../../actions/assets';
import AssetItem from '../../components/assets/item';

class AssetPicker extends Component {
	
	constructor(props) {
		super(props);
				
		this.state = {
			image: this.props.selected
		}
		
		this.setup();
		this.props.fetchAssetsList();
	}
	
	componentDidUpdate(){
		this.setup();
	}
	
	setup(){
		this.props.setupLibrary(
			this.props.types ? this.props.types : false,
			this.props.max ? this.props.max : false,
			this.props.selected ? this.props.selected : [],
			this.props.onSelect
		);
	}
	
	render(){
						
		if(!this.props.assets.assets){
			return null;
		}
		
		let item = false;
		
		if(this.props.placeholder){
			item = this.props.placeholder;
		}
		
		let label = i18n.t('library:picker_label_choose');
		
		if(this.props.selected){
			
			label = i18n.t('library:picker_label_change');
			
			if(!this.props.max || this.props.max > 1){
				item = i18n.t('library:picker_selected', { count: this.props.selected.length });
			}else{	
				item = (
					<AssetItem
						asset={this.props.assets.assets[this.props.selected[0].id]}
					/>
				);				
			}			
		}
		
		return (
				<React.Fragment>
					{item && 
						<div>
							{item}
						</div>
					}
					
					<button 
						type="button"
						className="c-btn u-mt-xsmall c-btn--info c-btn--danger c-btn--outline"
						onClick={() => {
							this.props.history.push(`${this.props.location.pathname}/library`);
						}}
					>
						{label}
					</button>
		        </React.Fragment>
			);
	}
}

function mapStateToProps({ assets }, ownProps){
	return {
		assets
	};
}

export default withRouter(connect(mapStateToProps, { 
	setupLibrary,
	fetchAssetsList
})(AssetPicker));