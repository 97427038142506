import { quizzesConstants } from '../constants/quizzes';
import _ from 'lodash';

export default function(state = {}, action) {
	
	let quizzes = false;

	switch (action.type) {
		      
		case quizzesConstants.QUIZ_LIST:

			return { 
				...state, 
				quizzes: _.mapKeys(action.payload.data, 'id'),
			}
			
		case quizzesConstants.QUIZ_SAVE_GENERAL:
		case quizzesConstants.QUIZ_SAVE_QUESTIONS:
		case quizzesConstants.QUIZ_START:
		case quizzesConstants.QUIZ_SAVE_ANSWERS:

			return { 
				...state, 
				quizzes: { ...state.quizzes, ..._.mapKeys(action.payload.data.quizzes, 'id') }
			}

		case quizzesConstants.QUIZ_DELETE:
								
			quizzes = state.quizzes;
			
			if(action.payload.data.deleted){
			
				if(quizzes[action.payload.data.deleted]){
					delete(quizzes[action.payload.data.deleted]);
				}
				
			}else{
				quizzes = { ...state.quizzes, ..._.mapKeys(action.payload.data.quizzes, 'id') };
			}

			return { 
				...state, 
				quizzes: quizzes
			}	
			
		case quizzesConstants.QUIZ_PROGRESS:
		
			return { 
				...state, 
				progress: action.payload
			}
			
		
											
		default:
			return state;
	}
}