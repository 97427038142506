import { assetsConstants } from '../constants/assets';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';
import { stopSubmission } from './forms';
import { showNotification } from './notifications';
import i18n from '../helpers/i18n'; 

export function fetchCollectionsList(id) {

    return async (dispatch) => {
		
		let url = '/collection/list';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: assetsConstants.COLLECTION_LIST,
				payload: request
			});
			 			
		} catch(error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchAssetsList(id, callback) {

    return async (dispatch) => {
		
		let url = '/asset/list';
		
		if(id){
			url += `?id=${id}`;
		}
		
		try {
			const request = await axiosCreate().get(url);
										
			dispatch({ 
				type: assetsConstants.ASSET_LIST,
				payload: request
			});
			
			if(callback)
				callback();
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function saveCollection(formName, id, values, callback) {

	return async (dispatch) => {
		
		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate().patch(`/collection/save/${id}`, values);
			}else{
				request = await axiosCreate().post('/collection/save', values);
			}

			dispatch({
				type: assetsConstants.COLLECTION_SAVE,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_collections_updated_title'),
					i18n.t('assets:notification_collections_updated_description')
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_collections_added_title'),
					i18n.t('assets:notification_collections_added_description')
				));
			}
			
			
			callback(request.data.collections[0].id);
			dispatch(stopSubmission(formName));

		} catch (error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function saveAsset(formName, id, values, uploadProgress, callback, callbackError) {

	return async (dispatch) => {

		let request = '';
		
		try {
						
			if(id){
				request = await axiosCreate(uploadProgress).patch(`/asset/save/${id}`, values);
			}else{
				request = await axiosCreate(uploadProgress).post('/asset/save', values);
			}			
		
			dispatch({
				type: assetsConstants.ASSET_SAVE,
				payload: request
			});
			
			if(id){
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_updated_title'),
					i18n.t('assets:notification_updated_description'),
					request.data.assets[0].thumbnail
				));
			}else{
				
				if((values.files && values.files.length > 0) || (values.remote && values.remote.length > 0)){
					
					let count = 0;
					
					if(values.files){
						count = values.files.length;
					}else{
						count = values.remote.length;
					}
					
					dispatch(showNotification(
						'success',
						i18n.t('assets:notification_added_title', { count: count }),
						i18n.t('assets:notification_added_description', { count: count }),
						request.data.assets[0].thumbnail
					));
				}else{
					dispatch(showNotification(
						'success',
						i18n.t('assets:notification_added_title'),
						i18n.t('assets:notification_added_description'),
						request.data.assets[0].thumbnail
					));
				}
			}
			
			if(callback)
				callback(request.data.assets);				
			
			if(formName)
				dispatch(stopSubmission(formName));

		} catch (error) {
			
			if(callbackError)
				callbackError();
			
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
			
			dispatch(stopSubmission(formName));
		};
	}
}

export function toggleAssetHeadline(id, status, callback) {

	return async (dispatch) => {
		
		try {
									
			const request = await axiosCreate().patch(`/asset/save/${id}`, { headline: status });			
		
			dispatch({
				type: assetsConstants.ASSET_HEADLINE,
				payload: request
			});
			
			if(status === 1){
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_headline_enabled_title'),
					i18n.t('assets:notification_headline_enabled_description'),
					request.data.assets[0].thumbnail
				));
			}else{
				dispatch(showNotification(
					'success',
					i18n.t('assets:notification_headline_disabled_title'),
					i18n.t('assets:notification_headline_disabled_description'),
					request.data.assets[0].thumbnail
				));
			}	
			
			if(callback)
				callback();

		} catch (error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function deleteAsset(id, callback) {

	return async (dispatch) => {
		
		try {
						
			await axiosCreate().delete('/asset/delete');

			dispatch({
				type: assetsConstants.ASSET_DELETE,
				id: id
			});
			
			dispatch(showNotification(
				'success',
				i18n.t('assets:notification_deleted_title'),
				i18n.t('assets:notification_deleted_description')
			));

			if(callback)
				callback();

		} catch (error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function downloadAsset(id, callback) {

    return async (dispatch) => {
		
		try {
			const request = await axiosCreate().post('/asset/download', { files: id });
										
			dispatch({ 
				type: assetsConstants.ASSET_DOWNLOAD,
				payload: request
			});
						
			if(callback)
				callback(request.data.url, request.data.filename)
			 			
		} catch(error) {
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}

export function fetchRemoteVideoDetails(source, id, callback) {

    return async (dispatch) => {
		
		try {
			const request = await axiosCreate().get(`/asset/remote-video?source=${source}&id=${id}`);
										
			dispatch({ 
				type: assetsConstants.REMOTE_VIDEO_DETAILS,
				payload: request
			});
			
			if(callback)
				callback(request.data.data);
			 			
		} catch(error) {

			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		};
	}
}