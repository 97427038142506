import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../../helpers/i18n'; 
import Table from '../../../components/chrome/table';
import _ from 'lodash';

import Loading from '../../../components/chrome/loading';

import { setSectionOptions } from '../../../actions/chrome';
import GroupsToolbar from '../toolbar';
import { checkPermission } from '../../../helpers/permissions';

import { fetchRetailersGroups } from '../../../actions/retailers';
import EmptySection from '../../../components/chrome/empty';


class RetailersGroups extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'RETAILERS_GROUPS')){
			this.props.history.push('/');
		}
		
		this.props.setSectionOptions(
			'retailers', 
			i18n.t('retailers:title')
		);
		this.props.fetchRetailersGroups();
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
	}
	
	renderPage(){
		
		let { retailers } = this.props;

		if(!retailers.groups){
			return (
				<Loading />
			);
		}
		
		if(_.isEmpty(this.props.retailers.groups)){
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t('retailers:empty_group_title')}
					description={i18n.t('retailers:empty_group_description')}
					cta={{
						label: i18n.t('retailers:action_group_add'),
						url: `${this.props.location.pathname}/group/add`
					}}
				/>
			);			
		}
		
		return (
			<div className="container">				
				<div className="c-card">
					
					<Table 
						columns={[
							{
								key: "name",
								label: "Name",
								sortable: true
							},
							{
								key: "assigned",
								label: "Assigned Retailers",
								sortable: true
							},
							{
								key: "actions",
								label: "Actions",
								className: "tight right"
							}
						]}
						data={_.map(this.props.retailers.groups, (group, key) => {
																								
							return ({
								id: group.id,
								name: group.name,
								assigned: group.retailers ? group.retailers.length : 0,
								actions: ['edit']								
							});
						})}
						actions={{
							edit: {
								tooltip: i18n.t('retailers:sub_action_edit'),
								icon: 'fal fa-edit',
								url: '/retailers/groups/group/[ID]'
							}
						}}
						sort={{
							column: 'name',
							order: 'asc'
						}}
						rowClick={(row) => {
							this.props.history.push(`/retailers/groups/group/${row.id}`);
						}}
					/>
					
					
					
					
				</div>
			</div>

		);
	}
	render() {
				
		
		
		return (

			<React.Fragment>
				
				<GroupsToolbar
					section="groups"
				/>
				
				{this.renderPage()}				
			</React.Fragment>
		);
	}
}

function mapStateToProps({ account, retailers }, ownProps){
	return {
		account,
		retailers
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchRetailersGroups
})(RetailersGroups);