import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18n from '../../helpers/i18n'; 
import _ from 'lodash';
import EmptySection from '../../components/chrome/empty';
import Table from '../../components/chrome/table';
import moment from 'moment-timezone';
import 'moment/min/locales';
import Loading from '../../components/chrome/loading';
import Toolbar from '../../components/chrome/toolbar';
import CountUp from 'react-countup';
import QuizGridItem from '../../components/quizzes/grid_item';
import { imageResize } from '../../helpers/s3';

import { setSectionOptions } from '../../actions/chrome';
import { checkPermission } from '../../helpers/permissions';
import { fetchQuizzesList } from '../../actions/quizzes';

class Quizzes extends Component {

	constructor(props){
		super(props);
		
		// MUST HAVE CORRECT PERMISSIONS!
		if(!checkPermission(this.props.account.permissions, 'QUIZZES')){
			this.props.history.push('/');
		}
		
		this.props.setSectionOptions(
			'quizzes', 
			i18n.t('quizzes:title')
		);
		
		this.props.fetchQuizzesList();

		const query = require('query-string');
		const queryString = query.parse(this.props.location.search);
		
		let type = 'approved';
		
		if(queryString.type){
			type = queryString.type;
		}
		
		this.state = {
			type: type,
			search: false,
			filters: {
				type: false,
				status: false,
				keyword: false
			}
		}		
	}
	
	componentDidMount(){
		window.scrollTo(0,0);
		window.addEventListener('resize', this.handleResize.bind(this));
		this.handleResize();
	}
	
	componentWillUnmount(){
		window.removeEventListener('resize', this.handleResize.bind(this));
	}
	
	handleResize(){
		
		let width = window.innerWidth;
		let columns = 1;
		
		if(width >= 1200){
			columns = 6;
		}else if(width >= 992){
			columns = 3;
		}else if(width >= 576){
			columns = 2;
		}
		
		this.setState({
			columns: columns
		});
	}
	
	filterData(type){
		
		let now = moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
		let quizzes = this.props.quizzes.quizzes;

		let data = {
			approved: [],
			upcoming: [],
			draft: [],
			disabled: []
		};
				
		// APPLY FILTERS
		if(this.state.search){
			
			if(this.state.filters.keyword){
				quizzes = _.filter(quizzes, quiz => (quiz.title.toLowerCase().includes(this.state.filters.keyword.toLowerCase()) ));
			}
		}
				
		if(type === 'counter' || this.state.type === 'approved'){
			data.approved = _.filter(quizzes, quiz => (quiz.status === 'approved' && (quiz.date_launch == null || moment(quiz.date_launch).format('YYYY-MM-DD HH:mm:ss') <= now && quiz.questions.length > 0)));
		}
		
		if(type === 'counter' || this.state.type === 'upcoming'){
			data.upcoming = _.filter(quizzes, quiz => (quiz.status === 'approved' && quiz.date_launch !== null && moment(quiz.date_launch).format('YYYY-MM-DD HH:mm:ss') > now && quiz.questions.length > 0));
		}

		if(type === 'counter' || this.state.type === 'draft'){
			data.draft = _.filter(quizzes, quiz => (quiz.status === 'draft' || (quiz.status === 'approved' && quiz.questions.length === 0)));
		}
	
		if(type === 'counter' || this.state.type === 'disabled'){
			data.disabled = _.filter(quizzes, quiz => quiz.status === 'disabled');
		}
						
		if(type === 'counter'){
			
			return {
				approved: data.approved.length,
				upcoming: data.upcoming.length,
				draft: data.draft.length,
				disabled: data.disabled.length
			};
					
		}else{
			return data[this.state.type];
		}
	}
	
	renderTable(){

		let data = this.filterData();
				
		if(_.isEmpty(data)){
			
			return (
				<EmptySection
					icon="fa-exclamation-circle"
					title={i18n.t(`quizzes:empty_${this.state.type}_title`)}
					description={i18n.t(`quizzes:empty_${this.state.type}_description`)}
					cta={{
						label: i18n.t('quizzes:action_add'),
						url: '/quizzes/add'
					}}
				/>
			);			
		}
		
		let actions = [];
		let rowClick = (row) => {
			this.props.history.push(`/quizzes/${row.id}/setup`);
		};
		
		let cols = [
			{
				key: "image",
				label: "",
				format: 'image_circle',
				className: 'tight'
			},
			{
				key: "name",
				label: "Title",
				sortable: true
			},
			{
				key: "access",
				label: "Access Via",
				sortable: true
			},
			{
				key: "public",
				label: "Retailer Access",
				format: 'icon'
			},
			{
				key: "launch_date",
				label: "Launch Date",
				sortable: true,
				format: 'datetime'
			},
			{
				key: "questions",
				label: "Questions",
				sortable: true
			}
		];
		
		switch(this.state.type){
			
			case 'approved':
			case 'disabled':
				cols.push({
					key: "completed",
					label: "Completed",
					sortable: true
				});
				
				cols.push({
					key: "percent",
					label: "Average Result",
					sortable: true
				});
				break;
		}
		
		cols.push({
			key: "actions",
			label: "Actions",
			className: "tight right"
		});

		return (
			<div className="container">
				<div className="c-card">
					<Table 
						columns={cols}
						data={_.map(data, (quiz, key) => {
							
							let actions = [];
							
							if(checkPermission(this.props.account.permissions, 'QUIZZES_EDIT')){
								actions.push('edit');
							}
							
							if(checkPermission(this.props.account.permissions, 'QUIZZES_ANALYTICS') && (quiz.status == 'approved' || quiz.status == 'disabled') && (quiz.date_launch == null || moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss') >= moment(quiz.date_launch).format('YYYY-MM-DD HH:mm:ss'))){
								//actions.push('analytics');
							}
							
							return ({
								id: quiz.id,
								image: {
									src: quiz.image_filename ? imageResize(quiz.image_filename, 100, 100) : false,
									icon: 'fal fa-badge-check',
									alt: quiz.title
								},
								name: quiz.title,
								questions: quiz.questions ? quiz.questions.length : 0,
								public: `fal ${quiz.public === 1 ? 'fa-check' : 'fa-times'}`,
								access: quiz.date_launch == null ? 'Within Module Only' : 'Within Module or Direct',
								launch_date: quiz.date_launch == null ? false : moment(quiz.date_launch).format('YYYY-MM-DD HH:mm:ss'),
								completed: quiz.completions ? quiz.completions : 0,
								percent: `${quiz.average_score ? quiz.average_score : 0}%`,
								actions: actions							
							});
						})}
						actions={{
							edit: {
								tooltip: i18n.t('quizzes:action_edit'),
								icon: 'fal fa-edit',
								url: '/quizzes/[ID]/setup'
							},
							analytics: {
								tooltip: i18n.t('quizzes:action_analytics'),
								icon: 'fal fa-analytics',
								url: '/quizzes/[ID]/analytics'
							}
						}}
						sort={{
							column: 'name',
							order: 'asc'
						}}
						rowClick={rowClick}
					/>
				</div>
			</div>
		);
	}
	
	renderGrid(){

		let now = moment().tz('UTC').format('YYYY-MM-DD HH:mm:ss');
		let publicQuiz = [1];
		
		if(this.props.account.role.toLowerCase() == 'internal'){
			publicQuiz.push(0);
		}		
		
		let data = {
			pending: _.filter(this.props.quizzes.quizzes, quiz => ((quiz.date_launch !== null && moment(quiz.date_launch).format('YYYY-MM-DD HH:mm:ss') <= now) && quiz.completed == null && publicQuiz.includes(quiz.public))),
			completed: _.filter(this.props.quizzes.quizzes, quiz => ((quiz.date_launch !== null && moment(quiz.date_launch).format('YYYY-MM-DD HH:mm:ss') <= now) && quiz.completed !== null && publicQuiz.includes(quiz.public)))
		}
				
		return(
			<div className="container">
				<div id="grid_progress">
					<div className="available c-card u-pb-zero">
						{_.isEmpty(data.pending) && 
							<EmptySection
								icon="fa-exclamation-circle"
								title={i18n.t(`quizzes:empty_quiz_incomplete_title`)}
								description={i18n.t(`quizzes:empty_quiz_incomplete_description`)}
							/>
						||
							<div className="row u-pb-zero quizzes">
								{_.map(data.pending, (item, key) => {	
				
									return (
										<QuizGridItem 
											key={key}
											quiz={item}
											icon={'fa-eye'}
											size="large"
										/>						
									);
								})}
							</div>
						}
					</div>
					
					{!_.isEmpty(data.completed) && 
						<div className="completed">
							<div className="c-card grid-scroller" style={{ marginBottom: '0px' }}>
								<h4>
									{i18n.t(`quizzes:quizzes_completed_title`)}
									<span className="c-badge c-badge--small badge-light u-ml-xsmall">
										<CountUp 
											delay={0} 
											duration={1} 
											end={data.completed.length}
											separator="," 
											preserveValue={true}
										/>
									</span>
									
									{data.length > 0 && data.length > this.state.columns && 
										<div className="options">
											<i className={`fal fa-arrows-h ${this.state.expanded ? '' : 'selected'}`} onClick={() => { 
	
										 		this.setState({
											 		expanded: false
										 		});
										 		
											}}></i>
											<i className={`fal fa-th ${this.state.expanded ? 'selected' : ''}`} onClick={() => { 
				
										 		this.setState({
											 		expanded: true
										 		});
										 		
											}}></i>
										</div>
									}
								</h4>
								<div className={`row u-pt-small u-pb-zero quizzes ${this.state.expanded ? 'expanded' : ''}`}>
															
									{_.map(data.completed, (item, key) => {	
				
										return (
											<QuizGridItem 
												key={key}
												quiz={item}
												icon={'fa-plus'}
												size="small"
											/>						
										);
									})}
								</div>
							</div>
						</div>
					}
				</div>
			</div>
		);		
	}
	
	onChangeType(type){
		this.setState({
			type: type,
		});
	}
	
	render() {
		
		let { quizzes } = this.props;
		
		if(!quizzes.quizzes){
			return (
				<Loading />
			);
		}
		
		if(checkPermission(this.props.account.permissions, 'QUIZZES_ADD') || checkPermission(this.props.account.permissions, 'QUIZZES_EDIT')){
		
			let counter = this.filterData('counter');
			let tabs = [];
			
			if(checkPermission(this.props.account.permissions, 'QUIZZES', 'approved')){
				tabs.push({
					label: i18n.t('quizzes:toolbar_approved'),
					counter: String(counter.approved),
					onClick: () => { this.onChangeType('approved') },
					selected: this.state.type === 'approved' ? true : false
				});
			}
			
			if(checkPermission(this.props.account.permissions, 'QUIZZES', 'upcoming')){
				tabs.push({
					label: i18n.t('quizzes:toolbar_upcoming'),
					counter: String(counter.upcoming),
					onClick: () => { this.onChangeType('upcoming') },
					selected: this.state.type === 'upcoming' ? true : false
				});
			}
			
			if(checkPermission(this.props.account.permissions, 'QUIZZES', 'draft')){
				tabs.push({
					label: i18n.t('quizzes:toolbar_draft'),
					counter: String(counter.draft),
					onClick: () => { this.onChangeType('draft') },
					selected: this.state.type === 'draft' ? true : false
				});
			}
			
			if(checkPermission(this.props.account.permissions, 'QUIZZES', 'disabled')){
				tabs.push({
					label: i18n.t('quizzes:toolbar_disabled'),
					counter: String(counter.disabled),
					onClick: () => { this.onChangeType('disabled') },
					selected: this.state.type === 'disabled' ? true : false
				});
			}
			
			return (
	
				<React.Fragment>
					<Toolbar
						tabs={tabs}
						buttons={[{
							label: i18n.t('quizzes:action_add'),
							url: '/quizzes/add'
						}]}
						filter={{
							toggleFunc: (status) => {
								this.setState({
									search: status
								});
							},
							onChangeFunc: (event, type) => { 
								
								let filters = this.state.filters;
								
								switch(type){
									
									case 'keyword':
										filters.keyword = event.target.value;
										break;
										
									case 'type':
									case 'status':
										filters[type] = event.value;
										break;	
									
									default:
										return;
								}
						
								this.setState({
									filters: filters
								})
							},
							fields: [
								/*{
									placeholder: i18n.t('quizzes:filter_type'),
									name: "type",
									type: 'suggest',
									options: [
										{
											value: 1,
											label: 'Item 1'
										},
										{
											value: 2,
											label: 'Item 2'
										}
									]
								},
								{
									placeholder: i18n.t('quizzes:filter_status'),
									name: "status",
									type: 'suggest',
									options: [
										{
											value: 1,
											label: 'Item 1'
										},
										{
											value: 2,
											label: 'Item 2'
										}
									]
								},*/
								{
									placeholder: i18n.t('quizzes:filter_keyword'),
									name: "keyword",
									type: 'text'
								}
							],
							focus: 'keyword'
						}}
					/>
					
					{this.renderTable()}				
				</React.Fragment>
			);
		}else{
			
			return(
				<React.Fragment>
					{this.renderGrid()}				
				</React.Fragment>
			);	
		}
	}
}

function mapStateToProps({ account, quizzes }, ownProps){
	return {
		account,
		quizzes
	};
}

export default connect(mapStateToProps, { 
	setSectionOptions,
	fetchQuizzesList
})(Quizzes);