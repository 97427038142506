import React from 'react';
import Button from '../../components/chrome/button';
import _ from 'lodash';

const EmptySection = ({ title, icon, description, cta }) => {
		
	if(cta){
		
		if(!Array.isArray(cta)){
			cta = [cta];
		}
	}
		
	return (
		<div className="empty-section">
            <i className={` u-mb-small fal ${icon}`}></i>
			<h3 className="u-mb-xsmall">{title}</h3>
			<p>{description}</p>
			
			{cta && 
				
				<div className="buttons">
				
					{_.map(cta, (option, key) => {
				
						return (
							<React.Fragment key={key}>
								<Button
									{...option}
								/>
							</React.Fragment>
						);
					})}
				</div>
			}
		</div>
	);
}

export default EmptySection;