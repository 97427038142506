import React, { Component } from 'react';
import { Calendar, DateRangePicker, DateRange } from 'react-date-range';
import i18n from '../../helpers/i18n'; 
import moment from 'moment';
import 'moment/min/locales';
import * as rdrLocales from 'react-date-range/dist/locale';
import TimePicker from 'rc-time-picker';
import MaskedInput from 'react-input-mask';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

export default class DatePicker extends Component {
	
	constructor(props){
		super(props);
		moment.locale(i18n.language);
		
		this.state = {
			selected: this.props.selected
		}
		
		this.handleClick = this.handleClick.bind(this);
	}
	
	componentWillMount(){
		document.addEventListener('mousedown', this.handleClick, false);
	}
	
	componentWillUnmount(){
		document.removeEventListener('mousedown', this.handleClick, false);
	}
	
	componentDidUpdate(prevProps, prevState){

		if(this.props.selected !== prevProps.selected){
			this.setState({
				selected: this.props.selected
			});
		}
	}
	
	handleClick = (e) => {		
		
		if(this.node && this.node.contains(e.target)){
			
			if(this.props.timepicker){
				
				if(e.target.classList.contains('rc-time-picker-input')){
					return;
				}
			}
			
			this.setState({ timeOpen: false });
			return;
		}else if(this.props.timepicker){

			// TIMEPICKER IS ADDED AT ROOT!
			if(e.target.classList.contains('rc-time-picker-input') ||e.target.classList.contains('rc-time-picker-panel') || e.target.classList.contains('rc-time-picker-panel-select')){
				return;
			}else if(e.target.offsetParent && e.target.offsetParent.classList.contains('rc-time-picker-panel-select')){
				return;
			}
		}
		
		this.setState({ timeOpen: false });
		this.props.close();
	}
	
	handleChange(type, payload) {
				
		payload = moment(payload);
		let selected = moment(this.state.selected);
		
		if(type === 'time'){
			selected.set({hour: payload.format('HH'), minute: payload.format('mm'), second: 0});
		}else{
			selected.set({year: payload.format('YYYY'), month: payload.format('MM')-1, date: payload.format('DD')});
		}
						
		this.setState({
			selected: selected
		});
				
		if(this.props.onChange){
			this.props.onChange(moment(selected).toDate());
		}
	}
	
	handleRangeChange(payload) {
				
		this.setState({
			selected: payload.range1
		});
		
		if(this.props.onChange){
			this.props.onChange(payload.range1);
		}
	}

	renderOptions(){
		
		let options = {};
		
		if(this.props.minDate){
			options.minDate = this.props.minDate;
		}
		
		if(this.props.maxDate){
			options.maxDate = this.props.maxDate;
		}
				
		return options
	}
	
	renderSinglePicker(){
				
		// FORCES ALL BUTTONS IN POPOP TO NOT SUBMIT!
		document.querySelectorAll("button.rdrDay").forEach(el => {
	        el.type = 'button';
	    });
	    
	    let popup = '';
		
		if(this.props.popup){
			popup = `popup ${this.props.popup}`;
		}
						
		return (
			<div className={`wrapper ${popup}`}>
				
				<Calendar
					date={this.state.selected}				
					color="#768093"
					onChange={this.handleChange.bind(this, 'date')}
					locale={rdrLocales[moment.locale()]}
					showMonthAndYearPickers={false}
					{...this.renderOptions()}
				/>
				
				{this.props.timepicker && 
							
					<div className="timepicker">
						<label>
							{i18n.t('field_time_label')}:
						</label>
						
						<div className="times">							
							<MaskedInput
								type="text"
								mask="12:34"
								formatChars={{
								    '1': '[0-2]',
								    '2': '[0-9]',
								    '3': '[0-5]',
								    '4': '[0-9]',
								    '5': '[0-3]' // 2nd H for 24 Hour input
								}}
								placeholder="HH:MM"
								className="rc-time-picker-input"
								onChange={(event) => {
									
									let value = event.target.value;
									let mask = "12:34";
									
									if(value.startsWith('2')){
										mask = "15:34";
									}
									
									this.setState({ 
										mask: mask,
										value: value
									});
																		
									if(!value.includes('_') && value.length == 5){
										this.handleChange('time', `${moment(this.state.selected).format('YYYY-MM-DD')} ${value}`);								
									}
								}}
								onFocus={() => {
									this.setState({ 
										value: moment(this.state.selected).format('HH:mm'),
										timeOpen: true 
									});
								}}
								onBlur={() => {
									this.setState({ 
										value: false
									});
								}}
								value={this.state.value ? this.state.value : moment(this.state.selected).format('HH:mm')}
								mask={this.state.mask}
							/>
							
							<TimePicker 
								allowEmpty={false}
								value={moment(this.state.selected)}
								showSecond={false}
								onChange={(event) => {								
									this.handleChange('time', event);
								}}
								open={this.state.timeOpen}
								inputReadOnly={true}
							/>
						</div>	
					</div>
				}
				
			</div>
		);
	}
	
	
	renderRangePicker(){
		
		// FORCES ALL BUTTONS IN POPOP TO NOT SUBMIT!
		document.querySelectorAll("button.rdrDay").forEach(el => {
	        el.type = 'button';
	    });
	    
		let popup = '';
		let calendar = '';
				
		if(this.props.range.preset){
			calendar = (
				<DateRangePicker
					onChange={this.handleRangeChange.bind(this)}
					showSelectionPreview={true}
					moveRangeOnFirstSelection={false}
					className={'PreviewArea'}
					color="#768093"
					rangeColors={["#768093"]}
					months={this.props.range.months ? this.props.range.months : 2}
					ranges={this.props.selected}
					direction="horizontal"
					locale={rdrLocales[moment.locale()]}
					showMonthAndYearPickers={false}
					showDateDisplay={false}
					inputRanges={[]}
					{...this.renderOptions()}
				/>
			);
		}else{
						
			calendar = (
				<DateRange
					onChange={this.handleRangeChange.bind(this)}
					showSelectionPreview={true}
					moveRangeOnFirstSelection={false}
					className={'PreviewArea'}
					color="#768093"
					rangeColors={["#768093"]}
					months={this.props.range.months ? this.props.range.months : 2}
					ranges={[this.props.selected]}
					direction="horizontal"
					locale={rdrLocales[moment.locale()]}
					showMonthAndYearPickers={false}
					showDateDisplay={false}
					{...this.renderOptions()}
				/>
			);
		}
		
		if(this.props.popup){
			popup = `popup ${this.props.popup}`;
		}
		
		return (
			<div className={`wrapper ${popup}`}>
				{calendar}
			</div>
		);
	}
	
	render(){
				
		let type = 'single';

		if(this.props.range){
			type = 'range';
		}
				
		return (
			<div className={`datepicker ${this.props.alignRight ? 'right' : ''}`} ref={node => this.node = node }>
				{type === 'single' &&
					this.renderSinglePicker()
            	}
            	
            	{type === 'range' &&
					this.renderRangePicker()
            	}
			</div>
		)
	}
}