import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import OverlaySettings from '../../components/chrome/overlay_settings';
import i18n from '../../helpers/i18n'; 
import InputField from '../../components/forms/field.js';
import { startSubmission, stopSubmission } from '../../actions/forms';
import _ from 'lodash';
import { tagsArray } from '../../helpers/tags';
import Loading from '../../components/chrome/loading';
import AssetToolbar from './toolbar';

import { fetchAssetsList, fetchCollectionsList, saveAsset, fetchRemoteVideoDetails } from '../../actions/assets';
import { fetchTags } from '../../actions/options';

import { makeSelection } from '../../actions/library';
import { checkPermission } from '../../helpers/permissions';
import Swal from 'sweetalert2';
import ReactTooltip from 'react-tooltip';
import { imageUrl } from '../../helpers/s3';

import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFilePoster from 'filepond-plugin-file-poster';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageEdit from 'filepond-plugin-image-edit';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';

import * as Doka from '../../packages/react-doka/lib/doka.esm.min';
import '../../packages/react-doka/lib/doka.min.css';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css';

registerPlugin(FilePondPluginFileEncode, FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFilePoster, FilePondPluginFileValidateType, FilePondPluginImageCrop, FilePondPluginImageResize, FilePondPluginImageTransform, FilePondPluginImageEdit, FilePondPluginMediaPreview);

var formName = 'formAssetEdit';

class AssetsEdit extends Component {

	constructor(props){
		super(props);
		this.props.stopSubmission(formName);	
		
		const { id } = this.props.match.params;
		let source = 'local';
		
		if(id){
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'ASSETS_EDIT')){
				this.props.history.push('/');
			}
			
			this.state = {
	            id: id,
	            files: [],
	            files_changed: false,
	            thumbs_changed: false,
	            maxFiles: 1,
	            tags: [],
	            selected: [],
	            error: {
		            main: false,
		            videos: []
		        },
	            headline: false,
	            image: false,
	            source: source,
	            remote: [{
		            type: 'youtube',
		            id: '',
		            name: ''
	            }],
	            videos: [],
	            uploading: false
	        };
	       				       	
			
			
		}else{
			
			// MUST HAVE CORRECT PERMISSIONS!
			if(!checkPermission(this.props.account.permissions, 'ASSETS_ADD')){
				this.props.history.push('/');
			}
			
			const query = require('query-string');
			const queryString = query.parse(this.props.location.search);
			let collection = false;
			
			if(!queryString.collection){
				//this.props.history.push(this.props.parentPath);
			}else{
				collection = queryString.collection;
				this.props.fetchCollectionsList(collection)	
			}
					
			this.state = {
	            collection: collection,
	            files: [],
	            files_changed: false,
	            thumbs_changed: false,
	            maxFiles: 20,
	            tags: [],
	            selected: [],
	            error: {
		            main: false,
		            videos: []
		        },
	            source: source,
	            remote: [{
		            type: 'youtube',
		            id: '',
		            name: ''
	            }],
	            videos: [],
	            uploading: false
	        };
		}
				
		this.getRemoteVideoBouncer = _.debounce((key) => {
			this.getRemoteVideo(key);
		}, 250);
		
		this.props.initialize({
			source: source
		});	
		
		this.pondVideo = [];	
	}
	
	componentDidMount(){
		
		if(this.state.id){
			
			this.props.fetchAssetsList(this.state.id, () => {
		       	
		       	if(this.props.assets.assets && !this.props.assets.assets[this.state.id]){
					this.props.history.push(this.props.parentPath);
				}else{
					this.updateDomElements();
				}
	       	});
			
			this.updateDomElements();
			
		}
		
		this.props.fetchTags(() => {
			this.updateDomElements();
		});
	}
	
	updateDomElements(){
		
		let source = 'local';
		
		if(this.props.assets.assets && this.props.options.tags && this.state.id){
		
			let asset = this.props.assets.assets[this.state.id];	
			let selected = [];
			
			if(asset.readonly === 1){
				this.props.history.push(this.props.parentPath);	
			}
			
			if(asset.tags){
																
				_.forEach(asset.tags, (tag, key) => {	
										
					if(this.props.options.tags.asset && this.props.options.tags.asset[key]){
						
						tag = this.props.options.tags.asset[key];
						
						selected.push({
							label: i18n.t(`brand_${this.props.brand.details.directory}:tag_${tag.tag}`),
							value: tag.id,
							fixed: true
						});
					
					}else{
						selected.push({
							label: tag,
							value: key
						});
					}
				});
			}
						
			let files = [];
			let remote = false;
			let videos = [];
			
			if(asset.type === 'youtube' || asset.type === 'vimeo'){
				
				this.props.initialize({
					remote_id_0: asset.filename,
					remote_name_0: asset.name
				});
				
				remote = [{
					type: asset.type, 
					id: asset.filename,
					name: asset.name,
					thumbnail: [
						{
							source: imageUrl(asset.thumbnail),
							options: {
				              	type: 'local'
				            }
						}
					]
				}];
				
				source = 'remote';
				
			}else{
				files = [
					{
						source: imageUrl(asset.filename),
						options: {
			              	type: 'local'
			            },
			            custom_name: asset.name
					}
				];
							
				if(asset.type === 'video' && asset.thumbnail){
					videos.push({
						seconds: 0,
						thumbnail: [
							{
								source: imageUrl(asset.thumbnail),
								options: {
					              	type: 'local'
					            }
							}
						]
					});
				}
			}
		
			this.setState({
				selected: selected,
				files: files,
				files_changed: false,
				thumbs_changed: false,
				headline: asset.headline === 1 ? true : false,
				image: asset.type === 'image' ? true : false,
				source: source,
				remote: remote,
				videos: videos
			});				
		}
		
		this.props.change('source', source);
	}

	onSubmit(values) {
	  	
	  	let proceed = false;
	  	let files = [];
	  	let thumbs = [];
	  	let errors = {
		  	main: false,
		  	videos: []
		};
	  	
	  	switch(this.state.source){
		  	
		  	case 'local':
				files = _.map(this.pond.getFiles(), (file, key) => {
				  				  	
				  	return {
					  	filename: file.filename,
					  	encoded: file.getFileEncodeBase64String(),
					  	type: file.fileType
					}
				});
				
				if(files.length > 0){
					proceed = true;
				}else{
					errors.main = true;
				}
				
				_.forEach(files, (file, key) => {	
					
					if(file.type.substr(0, 5) === 'video'){
					  	
					  	let thumb = this.pondVideo[key].getFiles();
					  	
					  	if(thumb[0]){
					  		thumbs[key] = thumb[0].getFileEncodeBase64String();
					  	}else{
						  	proceed = false;
						  	errors.videos.push(key);
					  	}
				  	}
				});									
		  		break;
		  	
		  	case 'remote':
		  		proceed = true;
		  		
		  		_.forEach(this.state.remote, (file, key) => {	
					
				  	let thumb = this.pondVideo[key].getFiles();
				  	
				  	if(thumb[0]){
				  		thumbs[key] = thumb[0].getFileEncodeBase64String();
				  	}else{
					  	proceed = false;
					  	errors.videos.push(key);
				  	}
				});
		  		break;
		  		
		  	default:
		  	
		  		break;
		}

	  	if(!proceed){
		  	
		  	this.setState({
			  	error: errors
			});
		  	
		}else{
		  	
		  	this.props.startSubmission(formName);
		  	let posting = _.clone(values);
		  	
		  	posting.source = this.state.source;
		  	
		  	if(this.state.source === 'remote'){
			  	
				if(this.state.id){
			  		posting.remote = this.state.remote[0];
			  	}else{
				  	posting.remote = this.state.remote;
			  	}			  	
			  	
			}else{
							  				  	
			  	if(this.state.files_changed){
					
					if(this.state.id){
				  		posting.file = files[0];
				  	}else{
					  	posting.files = files;
				  	}
				}
				
				if(this.state.id){
			  		posting.name = this.state.files[0].custom_name;
			  	}else{
				  	posting.name = _.map(this.pond.getFiles(), (file, key) => {
					  	return this.state.files[key].custom_name;
					});
			  	}
			}
			
			if(this.state.thumbs_changed){
					
				if(this.state.id){
			  		posting.thumbnail = thumbs[0];
			  	}else{
				  	posting.thumbnails = thumbs;
			  	}
			}
						
			if(!this.state.id){
		  		posting.collection_id = this.state.collection;
		  	}
		  	
		  	// TAGS
		  	if(this.state.selected.length > 0){
			  	posting.tags = tagsArray(this.state.selected);
		  	}
		  	
		  	if(this.state.headline){
			  	posting.headline = 1;
		  	}else{
			  	posting.headline = 0;
		  	}
		  		
		  	this.props.saveAsset(formName, this.state.id, posting, (percent) => {
				
				if(this.state.files_changed){
					
					this.setState({
						uploading: percent
					});
				}
				
			}, (assets) => {
			  	
			  	if(this.props.overlay.back){
				  	
				  	switch(this.props.overlay.back.action){
					  	
				  		case 'library':				  			
				  			this.props.makeSelection('asset', assets)
				  			this.props.history.push(this.props.overlay.back.url);
				  			break;
				  			
				  		default:
				  			this.props.history.push(this.props.overlay.back.url);	
				  			break;
			  		}
				  	
			  	}else{
				  	this.props.history.push(this.props.parentPath);	
			  	}
			});
	  	}
	}
	
	getRemoteVideo(key, type){
		
		let remote = this.state.remote;
		
		this.props.fetchRemoteVideoDetails(remote[key].type, remote[key].id, (data) => {
			
			if(!type || type === 'name'){
				remote[key].name = data.title;
			}
			
			if(!type || type === 'thumbnail'){
				remote[key].thumbnail = [
					{
						source: data.thumbnail,
						options: {
			              	type: 'local'
			            },
			            custom_name: remote[key].name
					}
				];
				
				this.setState({
					thumbs_changed: true
				});
			}
			
			this.props.change(`remote_name_${key}`, data.title);
			
			this.setState({
				remote: remote
			});
			
		});
	}
	
	onInputChangeTag(selected) {
	    this.setState({ selected: selected });
	}
	
	onInputChangeSource(selected) {
	    this.setState({ source: selected.value });
	}
	
	onInputChangeRemote(type, key, value){

		let remote = this.state.remote;
		
		if(value.target){
			value = value.target.value;
		}else{
			value = value.value;
		}
		
		if(!remote[key]){
			remote[key] = {
				type: 'youtube',
				id: '',
				name: '',
				thumbnail: ''
			};
		}
		
		remote[key][type] = value;
				
		if(type !== 'name' && remote[key].id !== '' && remote[key].name === ''){
			this.getRemoteVideoBouncer(key);
		}
		
		this.setState({
			remote: remote
		});
	}
	
	remoteAddRemove(type, key){
		
		let remote = this.state.remote;
						
		if(type === 'add'){
			remote.push({
				type: 'youtube',
				id: '',
				name: ''
			});
		}else{
			remote.splice(key, 1);
			
			if(remote.length === 0){
				remote.push({
					type: 'youtube',
					id: '',
					name: ''
				});
			}
		}
						
		this.setState({
			remote: remote
		});
		
		_.forEach(remote, (item, key) => {					
			this.props.change(`remote_id_${key}`, item.id);
		});		
	}
	
	renderHeadline(){
		
		if(this.state.image){
			return(
				<Field
					name="headline"
					type="switch"
					labelSwitch={i18n.t('assets:label_headline')} 
					selected={this.state.headline}
					onChangeFunc={(event) => {
						this.setState({
							headline: event.target.checked
						});							
					}}
					component={InputField}
				/>
			)
		}
	}
	
	renderRemote(){
			
		let rows = this.state.remote;
		let i = 0;
		
		if(rows.length === 0){
			rows.push('');
		}
		
		return _.map(this.state.remote, (item, key) => {
			
			if(typeof item !== 'undefined'){
				
				let labelIconId = '';
				let labelIconName = '';
            
				if(item.id !== ''){
					
					let source = i18n.t('assets:label_remote_type_youtube');
					
					switch(item.type){
									
						case 'youtube':
							source = i18n.t('assets:label_remote_type_youtube');
							break;
							
						case 'vimeo':
							source = i18n.t('assets:label_remote_type_vimeo');
							break;
							
						default: 
							
							break;
					}
					
					
					labelIconId = (
						<React.Fragment>				
							<i className="fal fa-eye" data-tip={i18n.t('assets:label_remote_id_preview')}  onClick={() => { 
								
								switch(item.type){
									
									case 'youtube':
										window.open(`https://www.youtube.com/watch?v=${item.id}`, '_blank');
										break;
										
									case 'vimeo':
										window.open(`https://vimeo.com/${item.id}`, '_blank');
										break;
							
									default: 
										
										break;
								}
							}}></i>
						</React.Fragment>
					);
					
					labelIconName = (
						<React.Fragment>
							<i className="fal fa-redo"  data-tip={i18n.t('assets:label_remote_name_regenerate', { source: source})} onClick={() => { 
								this.getRemoteVideo(key, 'name');
							}}></i>
						</React.Fragment>
					);
				}
								
				return (
					<div className="row" key={key}>
						<div className={`col-xl-3`}>
					
							<Field
								label={i18n.t('assets:label_remote_type')} 
								name={`remote_type_${key}`}
								type="suggest"
								options={[
									{
										value: 'youtube',
										label: i18n.t('assets:label_remote_type_youtube')
									},
									{
										value: 'vimeo',
										label: i18n.t('assets:label_remote_type_vimeo')
									}
								]}
								component={InputField}
								selected={this.state.remote[key].type}
								onChangeFunc={this.onInputChangeRemote.bind(this, 'type', key)}
							/>	
						</div>
						<div className={`col-xl-3`}>
							<Field
								label={i18n.t('assets:label_remote_id')} 
								labelIcon={labelIconId}
								name={`remote_id_${key}`}
								value={this.state.remote[key].id}
								type="text"
								onChangeFunc={this.onInputChangeRemote.bind(this, 'id', key)}
								component={InputField}
							/>	
						</div>
						<div className={`col-xl-${this.state.id ? 6 : 4}`}>
							<Field
								label={i18n.t('assets:label_remote_name')} 
								labelIcon={labelIconName}
								name={`remote_name_${key}`}
								value={this.state.remote[key].name}
								type="text"
								component={InputField}
								onChangeFunc={this.onInputChangeRemote.bind(this, 'name', key)}
							/>	
						</div>
						
						{!this.state.id && 
							<div className="col-xl-2">
								<button
									className="c-btn c-btn--danger c-btn--outline c--btn-fullwidth c--btn--push-label" 
									type="button"
									onClick={() => { this.remoteAddRemove('remove', key); }}
								>
									<i className="fal fa-trash"></i> 
								</button>
							</div>
						}
					</div>		
				)
			}
		});
	}
	
	renderVideoThumbs(){
		
		let videos = [];
		
		if(this.state.source === 'remote'){
			videos = _.filter(this.state.remote, function(o) { 
				
				if(o.id){
					return true;
				}else{
					return false;
				} 
			});
		}else{
			videos = this.state.videos;
		}
				
		if(videos.length > 0){
			
			return (
				<div className="c-card">								
					<h4 className="u-mb-medium">{i18n.t('assets:title_thumbnails')}</h4>
					
					{ _.map(videos, (video, key) => {
					
						let title = 'Loading';
						
						if(this.state.source === 'remote'){
							title = video.name;
						}else{
							title = this.state.files[key].custom_name;
						}
						
						return (
							
							<div key={key}>
								
								<h6 className="u-mb-small">
									{title}	
									
									<div className="options">
										
										<i className="fal fa-camera" data-tip={this.state.source === 'remote' ? i18n.t('assets:label_thumbnail_remote_snapshot') : i18n.t('assets:label_thumbnail_local_snapshot')}  onClick={() => { 
								
											if(this.state.source === 'remote'){
												this.getRemoteVideo(key, 'thumbnail');
											}else{
												Swal.fire({
													title: i18n.t('assets:thumbnail_dialog_title'),
													text: i18n.t('assets:thumbnail_dialog_description'),
													input: 'number',
													inputValue: video.seconds,
													showCancelButton: true,
													confirmButtonText: i18n.t('dialog_confirm'),
													cancelButtonText: i18n.t('dialog_cancel'),
													focusConfirm: false
												}).then((result) => {
										
													if(result.value){
														
														let existing = this.state.videos;
													
														existing[key].seconds = result.value;
														
														this.setState({
															videos: existing
														});
														
														this.getVideoThumbnail(key, result.value)
													}
												});
											}
											
										}}></i>
									</div>									
								</h6>
								
								
								<div className={`filepond-container noname ${this.state.error.videos.includes(key) ? 'error' : ''}`}>
									<FilePond 
										maxFiles="1"
										ref={ref => this.pondVideo[key] = ref}
										allowFileEncode={true}
										acceptedFileTypes="image/*"
										itemInsertLocation="after"
				                      	labelIdle={i18n.t('filepond_idle', { count: this.state.maxFiles })}
				                      	labelFileLoading={i18n.t('filepond_fileLoading')}
				                      	labelTapToCancel={i18n.t('filepond_tapToCancel')}
				                      	labelFileWaitingForSize={i18n.t('filepond_waitingForSize')}
				                      	files={video.thumbnail}
				                      	server={{
											process: null,
											load: (source, load, error, progress, abort, headers) => {
												
												fetch(source)
													.then((res) => {
											            return res.blob();
											        }).then((res) => {
												      	load(res);
											        });
											}
										}}
										onupdatefiles={(files) => {
			
											let errors = this.state.error;
											//let thumbs_changed = false;
											
											if(files.length > 0){												
												errors.videos = _.filter(errors.videos, function(o) {
												    return o !== key;
												});
																																								
												if(!files[0].origin || files[0].origin !== 3){ // NOT LOADED ON INIT
													//thumbs_changed = true;
													return;
												}
			
											}else{
												
												let existing = [];
												
												if(this.state.source === 'remote'){
													
													existing = this.state.remote;
												
													existing[key].thumbnail = [];
													
													this.setState({
														remote: existing
													});
													
												}else{
													
													existing = this.state.videos;
												
													existing[key].thumbnail = [];
													
													this.setState({
														videos: existing
													});
												}
											}	
											
											this.setState({
												error: errors,
												//thumbs_changed: thumbs_changed
											});									
										}}
										
										imageEditEditor={Doka.create({
											onconfirm: (output) => {
												this.setState({
													thumbs_changed: true
												});
											}
										})}
									/>
								</div>
							</div>
							
							
							
							
						);
					})}
				</div>
			)
		}
	}
	
	getVideoThumbnail(file_key, seconds){
		
		let files = this.pond.getFiles();
		let file = files[file_key];
		
		try {
		  	let video = document.createElement('video');				
			video.src = 'data:' + file.fileType + ';base64,' + file.getFileEncodeBase64String();
			video.currentTime = seconds;
			video.muted = true;
	
		 	video.oncanplay = () => {
			 	
			 	let ratio = video.videoWidth/video.videoHeight;
			    let w = video.videoWidth;
			    let h = parseInt(w/ratio,10);
			    
			 	
			 	let canvas = document.createElement('canvas');
			 	canvas.width = w;
			    canvas.height = h;
			    
				let ctx = canvas.getContext('2d');
		
				ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
				let img_data = canvas.toDataURL();
				
				video.remove();
				
				let videos = this.state.videos;
				
				let arr = img_data.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]), 
				n = bstr.length, 
				u8arr = new Uint8Array(n);
		            
		        while(n--){
		            u8arr[n] = bstr.charCodeAt(n);
		        }
		        		        
		        videos[file_key] = {
			        seconds: seconds,
			        thumbnail: [
						{
							source: new File([u8arr], this.state.files[file_key].custom_name, {type:mime}),
							options: {
				              	type: 'local'
				            }
						}
					]
				};
				
				this.setState({
					videos: videos,
					thumbs_changed: true
				});
		 	}
		}
		catch(err) {
		  	console.log(err)
		}		
	}
	
	renderPage(){
		
		if(this.state.collection && (!this.props.assets.collections || !this.props.assets.collections[this.state.collection])){
			return (
				<Loading />
			);
		}else if(this.state.id && (!this.props.assets.assets || !this.props.assets.assets[this.state.id])){
			return (
				<Loading />
			);
		}else if(!this.props.options.tags){
			return (
				<Loading />
			);
		}
		
		let tags = [];
				
		if(this.props.options.tags.asset){
			
			tags = _.map(this.props.options.tags.asset, (tag, key) => {
		  	
			  	return {
					label: i18n.t(`brand_${this.props.brand.details.directory}:tag_${tag.tag}`),
					value: tag.id,
					fixed: true
				}
			});			
		}
				
		if(this.state.id){
						
			let asset = this.props.assets.assets[this.state.id];
			
			if(asset.tags){
								
				_.forEach(asset.tags, (tag, key) => {					
			
					if(!_.find(tags, { id: key })) {
						
						tags.push({
							label: tag,
							value: key
						});
					}
				});
			}
		}
				
		return (					
			<div className="row">
				<div className="col-xl-4 col-md-12">
					<div className="c-card">
						<h4 className="u-mb-medium">{i18n.t('assets:title_general')}</h4>
					
						<Field
							label={i18n.t('assets:label_source')} 
							name="source"
							type="suggest"
							options={[
								{
									value: 'local',
									label: i18n.t('assets:label_source_local')
								},
								{
									value: 'remote',
									label: i18n.t('assets:label_source_remote')
								}
							]}
							component={InputField}
							selected={this.state.source}
							onChangeFunc={this.onInputChangeSource.bind(this)}
						/>
						
						<Field
							label={i18n.t('assets:label_tags')} 
							name="tags"
							type="suggest"
							options={tags}
							component={InputField}
							multiple={true}
							custom={true}
							selected={this.state.selected}
							onChangeFunc={this.onInputChangeTag.bind(this)}
						/>
		
						{this.renderHeadline()}
					</div>
				</div>
				<div className="col-xl-8 col-md-12">
					
					{this.state.source === 'local' &&
						<div className="c-card">								
							<h4 className="u-mb-medium">{i18n.t('assets:title_local')}</h4>
							
							<div className={`filepond-container rename ${this.state.error.main ? 'error' : ''}`}>
								<FilePond 
									allowMultiple={true} 
									maxFiles={this.state.maxFiles}
									ref={ref => this.pond = ref}
									allowFileEncode={true}
									/*acceptedFileTypes="image/* video/*"*/
									itemInsertLocation="after"
			                      	labelIdle={i18n.t('filepond_idle', { count: this.state.maxFiles })}
			                      	labelFileLoading={i18n.t('filepond_fileLoading')}
			                      	labelTapToCancel={i18n.t('filepond_tapToCancel')}
			                      	labelFileWaitingForSize={i18n.t('filepond_waitingForSize')}
			                      	files={this.state.files}
			                      	server={{
										process: null,
										load: (source, load, error, progress, abort, headers) => {
											
											fetch(source)
												.then((res) => {
										            return res.blob();
										        }).then((res) => {
											      	load(res);
										        });
										}
									}}
									onupdatefiles={(files) => {
		
										let videos = this.state.videos;
										let init = true;
										
										if(files.length > 0){
											
											let fileItems = files.map(fileItem => fileItem.file);
											let files_changed = false;
											let image = false;
																								
											_.forEach(files, (file, key) => {					
		
												if(file.fileType.substr(0, 5) === 'image'){
													image = true;
												}else if(file.fileType.substr(0, 5) === 'video'){
													
													if(!videos[key]){
														videos[key] = [];
													}															
												}
												
												let stateFile = this.state.files[key];
												let fileName = document.getElementsByClassName('filepond--item')[key].getElementsByClassName('filepond--file-info-main')[0];
												let name = file.filenameWithoutExtension;
												
												if(stateFile && stateFile.custom_name){
													name = stateFile.custom_name;
												}
												
												fileItems[key].custom_name = name;
												fileName.innerHTML = name;															
																										
												fileName.onclick = () => {															
													Swal.fire({
														title: i18n.t('assets:rename_dialog_title'),
														input: 'text',
														inputValue: this.state.files[key].custom_name,
														showCancelButton: true,
														confirmButtonText: i18n.t('dialog_confirm'),
														cancelButtonText: i18n.t('dialog_cancel'),
														focusConfirm: false
													}).then((result) => {
											
														if(result.value){
															
															files = this.state.files;
															files[key].custom_name = result.value;
															fileName.innerHTML = result.value;	
												
															this.setState({
																files: files
															});
														}
													});													
												}
																										
												if(!file.origin || file.origin !== 3){ // NOT LOADED ON INIT
													files_changed = true;
													init = false;
													return;
												}																							
											});
											
											let errors = this.state.error;
											errors.main = false;
											
											let videos_cleaned = [];
											
											_.forEach(this.state.videos, (video, video_key) => {	
												
												if(files[video_key]){
													videos_cleaned.push(video);
												}
											});
																																						
											this.setState({
												files: fileItems,
												files_changed: files_changed,
												error: errors,
												image: image,
												videos: videos_cleaned
											});
											
											
											
											
											
											if(!init){
																								
												setTimeout(() => {
													
													let existing = this.state.videos;
																												
													_.forEach(videos, (file, key) => {	
														
														if(!existing[key] || typeof existing[key].thumbnail === 'undefined'){
															this.getVideoThumbnail(key, 1)
														}
													});
													
												}, 5000);
											}
											
										}else{
											this.setState({
												files: [],
												files_changed: true,
												error: {
													main: false,
													videos: []
												},
												image: false,
												videos: []
											});
										}													
									}}
									imageEditEditor={Doka.create({
										onconfirm: (output) => {
											this.setState({
												files_changed: true
											});
										}
									})}
								/>
							</div>
						</div>
					}
					
					{this.state.source === 'remote' &&
						<div className="c-card">								
							<h4 className="u-mb-medium">{i18n.t('assets:title_remote')}</h4>
							{this.renderRemote()}	
							
							{!this.state.id && 
								<button
									className="c-btn c-btn--outline" 
									type="button"
									onClick={() => { this.remoteAddRemove('add'); }}
								>
									{i18n.t('assets:action_add_remote_video')}
								</button>
							}							
						</div>
					}
					
					{this.renderVideoThumbs()}
				</div>
			</div>
		);
	}
	
	render() {
		
		let title = i18n.t('assets:title_add');
		let tags = [];
		let readonly = false;
				
		if(this.state.id && this.props.assets.assets && this.props.assets.assets[this.state.id]){
						
			let asset = this.props.assets.assets[this.state.id];

			title = asset.name;
			
			if(asset.tags){
								
				_.forEach(asset.tags, (tag, key) => {					
			
					if(!_.find(tags, { id: key })) {
						
						tags.push({
							label: tag,
							value: key
						});
					}
				});
			}
			
			if(asset.readonly === 1){
				readonly = true;
			}
			
		}else if(this.state.collection && this.props.assets.collections && this.props.assets.collections[this.state.collection]){
			title = i18n.t('assets:title_add_to_collection', { name: this.props.assets.collections[this.state.collection].name });
		}
		
		const { handleSubmit } = this.props;
		
		let cta = i18n.t('assets:action_add_submit', { count: this.state.source === 'local' ? this.state.files.length : this.state.remote.length });
		
		if(this.state.uploading){
			
			if(this.state.uploading < 100){
				cta = i18n.t('assets:action_status_uploading', { percent: this.state.uploading });
			}else{
				cta = i18n.t('assets:action_status_saving');
			}
			
		}else if(this.state.id){
			cta = i18n.t('assets:action_edit_submit');
		}
		
		return (
			<form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
				<OverlaySettings closeURL={this.props.parentPath} />
				
				<header className="c-navbar u-mb-medium">
					<h2 className="c-navbar__title">
						{title}
					</h2>
				</header>
				
				<AssetToolbar
					id={this.state.id}
					section="edit"
					formName={formName}
					readonly={readonly}
					cta={cta}
					{...this.props}
				/>
				
				{this.renderPage()}
				
				<ReactTooltip 
					effect="solid"
				/>			
			</form>
		);
	}
}

const validate = (values, prop) => {	
	
	const errors = {};
	
	if (!values.source) {
		errors.source = 'Please select a source!';
	}
	
	let i = 0;
	
	while (i < 20) {
		if (!values[`remote_id_${i}`]) {
			errors[`remote_id_${i}`] = 'Please enter a URL!';
		}
		i++;
	}
		
	return errors;
}

function mapStateToProps({ assets, overlay, account, options, brand }, ownProps){
	return {
		assets,
		overlay,
		account,
		options,
		brand
	};
}

export default reduxForm({
	validate,
	form: formName,
	onSubmitFail: (errors, dispatch, submitError) => { dispatch({ type: 'ERRORS_ERROR', payload: { status: 'failed_validation_local', errors: errors } })}
})(
	connect(mapStateToProps, { 
		startSubmission, 
		stopSubmission,
		saveAsset,
		fetchAssetsList,
		fetchCollectionsList,
		makeSelection,
		fetchTags,
		fetchRemoteVideoDetails
	} )(AssetsEdit)
);