import { accountConstants } from '../constants/account';
import { axiosCreate } from '../helpers/axios';
import { errorsConstants } from '../constants/errors';

export function userAccount(callback) {

    return async (dispatch) => {
		try {
			const request = await axiosCreate().get('/user/account')

			dispatch({
				type: accountConstants.ACCOUNT,
				payload: request
			});
			
			if(callback)
				callback(request.data);

		} catch(error) {
			console.log(error)
			dispatch({
				type: errorsConstants.ERRORS_ERROR,
				payload: error.response.data
			});
		}
	};
}