import React, { Component } from 'react';
import { connect } from 'react-redux';
import Loading from './loading';
import { brandLookup } from '../../actions/brand';
import MetaTags from 'react-meta-tags';
import Favicon from 'react-favicon';
import i18n from '../../helpers/i18n'; 
import { imageResize } from '../../helpers/s3';
import AppendHead from 'react-append-head';
import { dev_domain } from '../../helpers/axios';

class Brand extends Component {

	constructor(props) {
		
		super(props);

		this.state = {
			display: false
		}
		
		this.props.brandLookup(dev_domain, () => {
			
			// SET COLORS
			document.documentElement.style.setProperty('--main-color', this.props.brand.details.color_main);
			document.documentElement.style.setProperty('--accent-color', this.props.brand.details.color_accent);
			
			// LOAD IN BRAND LANGUAGE FILES IF NEEDED!
			i18n.loadNamespaces([`brand_${this.props.brand.details.directory}`,'dashboard'], (err, t)=>{
				
				i18n.changeLanguage(i18n.language, () => {
					//this.forceUpdate();
			    });
			});
						
			let _react = this;
						
			// SMALL DELAY FOR CSS TO UPDATED IN DOM		
			setTimeout(function(){
								
				_react.setState({
					display: true
				});
			}, 250)	
			
		});
	}
	
	/*componentWillReceiveProps(nextProps) {
				
		// CHECK IF WE HAVE BRAND DETAILS	
		if(nextProps.brand.details !== false){
			
			//document.body.classList.add('background-color');
			
			// LOAD IN BRAND LANGUAGE FILES IF NEEDED!
			i18n.loadNamespaces([`brand_${nextProps.brand.details.directory}`,'dashboard'], (err, t)=>{
				
				i18n.changeLanguage(i18n.language, () => {
					this.forceUpdate();
			    });
			});
						
			this.setState({
				display: true
			});
		}	
	}*/

	render() {

		if(!this.state.display){
			
			return (
				<Loading 
					className="full brand"
				/>
			);
		}else{
			
			let title = this.props.brand.details.name;
			
			if(this.props.brand.details.title){
				title = this.props.brand.details.title;
			}
					
			return (
				<React.Fragment>
					<MetaTags>
						<title>{title}</title>
					</MetaTags>
					<AppendHead>
			          	<link rel="stylesheet" type="text/css" href={`/css/brands/${this.props.brand.details.directory}/custom.css`} />
			        </AppendHead>
					<Favicon url={imageResize(`brands/${this.props.brand.details.directory}/general/icon.png`, 500)} />
				</React.Fragment>
			);
		}
	}
}

function mapStateToProps({ brand }, ownProps){
	return {
		brand
	};
}

export default connect(mapStateToProps, { 
	brandLookup
})(Brand);
