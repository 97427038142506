import React, { Component } from 'react';
import { withRouter } from "react-router";
import i18n from '../../helpers/i18n'; 
import Table from '../../components/chrome/table';
import _ from 'lodash';
import { generateBack } from '../../helpers/back';
import { imageResize } from '../../helpers/s3';
import { checkPermission } from '../../helpers/permissions';

var countries = require("i18n-iso-countries");
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));

class RetailersList extends Component {
	
	render() {
		
		let append = '';
		
		if(this.props.parentPath){
			append = `?b=${generateBack(window.location.pathname)}`;
		}
				
		return (

			<Table 
				columns={[
					{
						key: "image",
						label: "",
						format: 'image_circle',
						className: 'tight'
					},
					{
						key: "name",
						label: "Name",
						sortable: true
					},
					{
						key: "location",
						label: "Location",
						sortable: true,
						format: "image_pre"
					},
					{
						key: "users",
						label: "Users",
						sortable: true
					},
					{
						key: "actions",
						label: "Actions",
						className: "tight right"
					}
				]}
				data={_.map(this.props.data, (retailer, key) => {
	
					let location = [];
					let country = countries.getName(retailer.details.country, "en");
					let actions = [];
					
					if(country){
						country = country.replace(/\s+/g, '-').toLowerCase();
					}
										
					if(retailer.details){
						if(retailer.details.city){
							location.push(retailer.details.city);
						}
						
						if(retailer.details.postcode){
							location.push(retailer.details.postcode);
						}
					}
					
					if(checkPermission(this.props.permissions, 'RETAILERS_EDIT')){
						actions = ['edit'];
					}else{
						actions = ['analytics'];
					}
																												
					return ({
						id: retailer.id,
						image: {
							src: retailer.image ? imageResize(retailer.image, 100) : false,
							icon: 'fal fa-store-alt',
							alt: retailer.name
						},
						name: retailer.name,
						users: retailer.users ? retailer.users.length : 0,
						location: {
							value: location.length > 0 ? location.join(', ') : '',
							image: country ? `/images/flags/countries/${country}.svg` : false,
							className: 'flag'
						},
						actions: actions								
					});
				})}
				actions={{
					edit: {
						tooltip: i18n.t('retailers:action_edit'),
						icon: 'fal fa-edit',
						url: `${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/retailer/[ID]${append}`
					},
					analytics: {
						tooltip: i18n.t('retailers:action_analytics'),
						icon: 'fal fa-analytics',
						url: `${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/retailer/[ID]/analytics${append}`
					}
				}}
				sort={{
					column: 'name',
					order: 'asc'
				}}
				rowClick={(row) => {
					
					if(checkPermission(this.props.permissions, 'RETAILERS_EDIT')){
						this.props.history.push(`${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/retailer/${row.id}${append}`);
					}else{
						this.props.history.push(`${this.props.parentPath ? this.props.parentPath : this.props.location.pathname}/retailer/${row.id}/analytics${append}`);
					}
				}}
			/>
		);
	}
}

export default withRouter(RetailersList);